import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  getAllAppointmentStatus,
  getAllAppointmentType,
  getAppointmentDetails,
  postAppointmentDetails,
} from "../../../../services/appointmentService/appointmentService";

function useAddAppointment({ userTrxnId, handleOpen, handleClose }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [editableMode, setEditableMode] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [appointmentType, setAppointmentType] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);

  const [appointmentDetails, setAppointmentDetails] = useState({
    amount: "",
    appointmentType: null,
    dateTime: false,
    hcp: "",
    remarks: "",
    status: "",
    typeId: 0,
  });

  const clearDetails = () => {
    setAppointmentDetails({
      amount: "",
      appointmentId: null,
      dateTime: "",
      hcp: "",
      remarks: "",
      statusId: null,
      typeId: null,
    });
  };

  const loadAllAppointmentType = () => {
    getAllAppointmentType().then((data) => {
      setAppointmentType(data?.appointmentStatuses);
    });
  };

  const capitalize = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const filterList = (list) =>
    list.filter((value) => {
      const name = value?.name?.toLowerCase() || "";
      const valueText = value?.value?.toLowerCase() || "";
      const term = searchTerm.toLowerCase();
      return name.includes(term) || valueText.includes(term);
    });

  const formattedDate = moment(appointmentDetails?.dateTime)
    // .set({ hour: 16, minute: 21, second: 54, millisecond: 590 })
    .toISOString();

  const saveCabDetails = async (appointmentId) => {
    const sendData = {
      amount: parseInt(appointmentDetails?.amount),
      appointmentId: appointmentId || null,
      dateTime: formattedDate,
      hcp: appointmentDetails?.hcp,
      remarks: appointmentDetails?.remarks,
      statusId: appointmentDetails?.status?.id || appointmentDetails?.appointmentStatusResponse?.id,
      typeId:
        appointmentDetails?.appointmentType?.id ||
        appointmentDetails?.appointmentTypeResponse?.id,
      userTxnId: userTrxnId,
    };

    await postAppointmentDetails(sendData).then((data) => {
      if (data.status === 200) {
        alert("successfully saved");

        clearDetails();
        setIsAdd(false);
        getAppointmentDetails(userTrxnId).then((res) => {
          setData(res?.appointmentDetailResponses);
        });
      } else {
        alert("Error Fetching Data...");
      }
    });
  };

  const loadAppointmentDetails = async (userTrxnId) => {
    await getAppointmentDetails(userTrxnId).then((res) => {
      setData(res?.appointmentDetailResponses);
      handleClose();
    });
  };

  const loadAllAppointmentStatus = () => {
    const anyMatchingValue =
      appointmentDetails?.appointmentType?.id ||
      appointmentDetails?.appointmentTypeResponse?.id;
    if (anyMatchingValue) {
      getAllAppointmentStatus(anyMatchingValue).then((data) => {
        setAppointmentStatus(data?.appointmentStatusResponses);
      });
    }
  };

  useEffect(() => {
    loadAppointmentDetails(userTrxnId);
    loadAllAppointmentType();
    if (
      appointmentDetails?.appointmentType?.id ||
      appointmentDetails?.appointmentTypeResponse?.id
    ) {
      loadAllAppointmentStatus();
    }
  }, [
    appointmentDetails?.appointmentType?.id,
    appointmentDetails?.appointmentTypeResponse?.id,
  ]);

  return {
    errorMsg,
    saveCabDetails,
    editableMode,
    setEditableMode,
    appointmentDetails,
    setAppointmentDetails,
    isAdd,
    setIsAdd,
    selectedIndex,
    setSelectedIndex,
    data,
    clearDetails,
    capitalize,
    handleSearch,
    filterList,
    appointmentType,
    appointmentStatus,
  };
}

export default useAddAppointment;
