import packageJson from "../../package.json";

export const cacheControling = () => {
  let version = localStorage.getItem("version"); //getting build version set in local storage
  if (version != packageJson.version) {
    // compares version from local storage with build version

    emptyCache();

    localStorage.clear();
    localStorage.setItem("version", packageJson.version);
  }
};

export function emptyCache() {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    window.location.reload(true);
  }
}
