import React from "react";
import useAddAppointment from "./useAddAppointment.logic";
import { Grid } from "@material-ui/core";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";

function TextField(props) {
  return (
    <Grid item xs={2}>
      {props.isEditable ? (
        <Grid container direction="column">
          <label>{props.label}</label>
          <input
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            style={{
              width: 200,
              height: 40,
              borderRadius: 0,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={(e) => props.onChange(e)}
          />
        </Grid>
      ) : (
        <Grid container direction="column">
          <b>{props.label}</b>
          <span>{props.value}</span>
        </Grid>
      )}
    </Grid>
  );
}

function DropdownSearch(props) {
  return (
    <Grid item xs={2}>
      {props?.isEditable ? (
        <Grid container direction="column">
          <label>{props.label}</label>
          <Dropdown style={{ marginTop: "-5px" }}>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 40,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
              }}
            >
              {props?.value || "Add..."}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 210,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
                padding: "5px",
                borderRadius: "0px",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                onChange={props?.appointment?.handleSearch}
                style={{
                  width: "100%",
                  padding: "5px",
                  boxSizing: "border-box",
                  borderRadius: "0px",
                  border: "1px solid gray",
                }}
              />
              {props?.appointment
                ?.filterList(props?.filterData)
                .map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200, maxHeight: 200 }}
                      onClick={() => props?.onClick(value)}
                    >
                      {props?.appointment?.capitalize(value.name)}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </Grid>
      ) : (
        <Grid container direction="column">
          <b>{props.label}</b>
          <span>{props.value}</span>
        </Grid>
      )}
    </Grid>
  );
}

function DateTimeField(props) {
  let formattedDateTime = moment(props?.dateTime).format("YYYY-MM-DD HH:mm:ss");

  return (
    <Grid item xs={2}>
      {props.isEditable ? (
        <Grid container direction="column">
          <label>Date Time</label>
          <input
            type="datetime-local"
            value={formattedDateTime}
            onChange={(e) =>
              props.setAppointmentDetails((s) => ({
                ...s,
                dateTime: e.target.value,
              }))
            }
            // onChange={(e) => console.log(e.target.value, "datetime")}
            // style={{
            //   width: "200px",
            //   height: 40,
            //   paddingLeft: "6px",
            //   marginTop: "2px",
            // }}
          />
        </Grid>
      ) : (
        <Grid container direction="column">
          <b>Date Time</b>
          <span>{moment(props.dateTime).format("DD-MM-YYYY hh:mm a")}</span>
        </Grid>
      )}
    </Grid>
  );
}

function CabTiles({
  amount,
  appointmentType,
  dateTime,
  hcp,
  status,
  comments,
  onUpdateClick,
  isEditable,
  setAppointmentDetails,
  state,
  onSave,
  onCancel,
}) {
  const handleOnClick = (value) => {
    setAppointmentDetails((s) => ({
      ...s,
      appointmentType: value,
    }));
  };

  const handleStatus = (value) => {
    setAppointmentDetails((s) => ({
      ...s,
      status: value,
    }));
  };
  return (
    <Grid
      container
      sx={{ marginBottom: 20, backgroundColor: "red" }}
      xs={12}
      spacing={2}
    >
      <DropdownSearch
        appointment={state}
        isEditable={isEditable}
        onClick={handleOnClick}
        value={appointmentType}
        placeholder="Add..."
        label="AppointmentType"
        filterData={state?.appointmentType}
      />
      <TextField
        type="number"
        isEditable={isEditable}
        onChange={(e) =>
          setAppointmentDetails((s) => ({ ...s, amount: e.target.value }))
        }
        value={amount}
        placeholder="Add..."
        label="Amount"
      />
      <TextField
        type="text"
        isEditable={isEditable}
        onChange={(e) =>
          setAppointmentDetails((s) => ({ ...s, hcp: e.target.value }))
        }
        value={hcp}
        placeholder="Add..."
        label="HCP"
      />

      <DateTimeField
        isEditable={isEditable}
        dateTime={dateTime}
        setAppointmentDetails={setAppointmentDetails}
      />

      <DropdownSearch
        appointment={state}
        isEditable={isEditable}
        onClick={handleStatus}
        value={status}
        placeholder="Add..."
        label="Status"
        filterData={state?.appointmentStatus}
      />
      {isEditable ? (
        <div style={{ width: "250px" }}>
          <p style={{ textAlign: "left", paddingTop: "6px" }}>Comments</p>
          <textarea
            placeholder="Add..."
            value={comments}
            type="text"
            onChange={(e) =>
              setAppointmentDetails((s) => ({ ...s, remarks: e.target.value }))
            }
            style={{ width: 250, marginTop: "-12px", paddingLeft: "5px" }}
          />
        </div>
      ) : (
        <div style={{ width: "250px" }}>
          <b>Comments</b>
          <p style={{ textAlign: "left" }}>{comments}</p>
        </div>
      )}
      <Grid item xs={1.5}>
        {isEditable && (
          <>
            <button
              style={{
                width: 100,
                height: 40,
                marginRight: 10,
                backgroundColor: "#1892ff",
                border: "2px solid #0a67b2",
              }}
              onClick={() => {
                onSave();
                state?.data?.length === 0 && state?.clearDetails();
              }}
            >
              Save
            </button>
            <button
              style={{
                width: 100,
                height: 40,
                border: "2px solid #0a67b2",
                backgroundColor: "red",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
          </>
        )}
        {!isEditable && (
          <button
            style={{
              width: 90,
              height: 40,
              marginRight: 10,
              backgroundColor: "#1892ff",
              border: "2px solid #0a67b2",
            }}
            onClick={onUpdateClick}
          >
            Update
          </button>
        )}
      </Grid>
    </Grid>
  );
}

function AddAppointment({ userTrxnId, handleOpen, handleClose }) {
  const state = useAddAppointment({ userTrxnId, handleOpen, handleClose });
  console.log(state, "state here");
  return (
    <div>
      <Grid>
        <button
          style={{
            width: 180,
            height: 40,
            backgroundColor: "#1892ff",
            border: "2px solid #0a67b2",
          }}
          onClick={() => {
            state.setIsAdd(true);
            state?.clearDetails();
          }}
        >
          Add Appointments
        </button>
        <hr />
        {state.isAdd && (
          <CabTiles
            amount={state?.appointmentDetails?.amount}
            appointmentType={state?.appointmentDetails?.appointmentType?.name}
            dateTime={state?.appointmentDetails?.dateTime}
            hcp={state?.appointmentDetails?.hcp}
            status={state?.appointmentDetails?.status?.name}
            typeId={state?.appointmentDetails?.typeId}
            comments={state?.appointmentDetails?.remarks}
            setAppointmentDetails={state.setAppointmentDetails}
            onSave={() => {
              state.saveCabDetails();
            }}
            onCancel={() => {
              state.clearDetails();
              state.setIsAdd(true);
              if (state?.data) {
                state.setIsAdd(false);
              }
            }}
            isEditable={state.isAdd}
            state={state}
          ></CabTiles>
        )}
        {state?.data?.length > 0 && (
          <div
            style={{
              backgroundColor: "#F5F7F8",
              border: "2px solid #c0c2c4",
              padding: "10px",
            }}
          >
            {state?.data?.length !== 0 &&
              state?.data?.map((val, i) => {
                let currentlist = i == state.selectedIndex;
                return (
                  <>
                    {state?.data.length >= 1 && (
                      <hr style={{ marginTop: "-11px" }} />
                    )}
                    <CabTiles
                      isEditable={currentlist}
                      onUpdateClick={() => {
                        state.setSelectedIndex(i);
                        state.setAppointmentDetails(val);
                      }}
                      amount={
                        currentlist
                          ? state.appointmentDetails?.amount
                          : val?.amount
                      }
                      appointmentType={
                        currentlist
                          ? state?.appointmentDetails?.appointmentType?.name ||
                            val?.appointmentTypeResponse?.name
                          : val?.appointmentTypeResponse?.name
                      }
                      hcp={currentlist ? state.appointmentDetails.hcp : val.hcp}
                      dateTime={
                        currentlist
                          ? state.appointmentDetails.dateTime
                          : val?.dateTime
                      }
                      status={
                        currentlist
                          ? state?.appointmentDetails?.status?.name ||
                            val?.appointmentStatusResponse?.name
                          : val?.appointmentStatusResponse?.name
                      }
                      comments={
                        currentlist
                          ? state?.appointmentDetails?.remarks
                          : val?.remarks
                      }
                      setAppointmentDetails={state.setAppointmentDetails}
                      onSave={() => {
                        state.saveCabDetails(val?.id);
                        state.setSelectedIndex(null);
                      }}
                      onCancel={() => {
                        state.clearDetails();
                        state.setSelectedIndex(null);
                      }}
                      state={state}
                    ></CabTiles>
                  </>
                );
              })}
          </div>
        )}
      </Grid>
      <p style={{ color: "green", textAlign: "center" }}>{state.errorMsg}</p>
    </div>
  );
}

export default AddAppointment;
