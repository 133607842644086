import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader } from "../../store/DataStore";
import { getUserCode, getUserRole } from "../../store/DataStore";

export const getAgentData = async (isActive) => {
  let userRole = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  if (userRole !== "SALES_LEAD") {
    userRole = "SALES_AGENT";
  }
  const userCodeParam = userRole === "KINDER_SALES_AGENT" ? `&userCode=${code}` : ``;
  const url = `${APP_URL}/user/all`;
  try {
    const data = await axios.get(url, {
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserByDepartment = async (departmentId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const data = await axios.get(`${APP_URL}/settings/team/${departmentId}`, {
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllDepartments = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const data = await axios.get(`${APP_URL}/settings/department/all`, {
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllStages = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const data = await axios.post(`${APP_URL}/data/status/stage/all`,{},{
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTaskAgentData = async (isActive) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const url = `${APP_URL}/enquiry/task/user/all`;
  try {
    const data = await axios.get(url, {
      headers,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
