import "./Settings.css";
import Form from "./UserRole";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import useSettings from "./useSettings.logic";
import Roles from "./RolesAndPermission/Roles";
import Dropdown from "react-bootstrap/Dropdown";
import { Autocomplete, MenuItem } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Header/Header";
import CallCampaign from "./CallCampaign/CallCampaign";
import SideMenu from "../../components/SideMenu/SideMenu";
import CreateCampaign from "./CreateCampign/CreateCampaign";
import { CustomMenu } from "../../components/AddLead/AddLead";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";
import { checkPermission } from "../../util/permissionUtils";
import {
  Box,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Button as MuiButton,
} from "@material-ui/core";
import { useStyles } from "../../components/multi-select";
import { Button } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import SelectAllTransferList from "../../components/TransferList";
import PackagesComponent from "../Packages/PackagesComponennt";

function PreLeadAssignComponent(props) {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div className="col- m-0">
          <b>Assigned To</b>
          <br />

          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 150,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 30,
              }}
            >
              {props.state.preAssignedUser.selected
                ? props.state.preAssignedUser.selected
                : "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 250,
              }}
            >
              {props?.state.preAssignedUser.list.map(
                ({ name, code }, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{
                        width: 180,
                        maxHeight: 200,
                      }}
                      onClick={() => {
                        props.state.handlePreAssignedToChange(code, name);
                      }}
                    >
                      {name}
                    </Dropdown.Item>
                  );
                }
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="col-1  m-0 ">
            <b>Location</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.locationtData}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedLocations}
                onChange={(value, event) =>
                  props.state.handleSelectLocations(value, event)
                }
                setState={props.state.setSelectedLocations}
              />
            </div>
          </div>
          <div className="col-1  m-0 ">
            <b>Circle</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.circles}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedCircles}
                onChange={(value, event) =>
                  props.state.handleSelectCircles(value, event)
                }
                setState={props.state.setSelectedCircles}
              />
            </div>
          </div>
          <div className="col-1 m-0">
            <b>Treatment</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.treatmentData}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedTreatments}
                onChange={(value, event) =>
                  props.state.handleSelectTreatments(value, event)
                }
                setState={props.state.setSelectedTreatments}
              />
            </div>
          </div>
          <div className="col-1 m-0">
            <b>Source</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.sources}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedSource}
                onChange={(value, event) =>
                  props.state.handleSelectSources(value, event)
                }
                setState={props.state.setSelectedSource}
              />
            </div>
          </div>
          {sheduledDaysConponent(props.state, props.setColors)}
        </div>

        {props.state.isSave && (
          <div className="col-2 mt-4" style={{ marginLeft: "15%" }}>
            <button
              style={{
                height: 35,
                width: 70,
                backgroundColor: "#0a67b2",
              }}
              onClick={() => props.state.savePreAgentsDetails()}
            >
              Save
            </button>
          </div>
        )}
      </div>

      <div className="row" style={{ width: "100%" }}>
        <hr style={{ marginTop: 20 }} />

        <div className="row" style={{ width: "100%", padding: 30 }}>
          <table class="table table-bordered">
            <thead>
              <tr style={{ width: "100%" }}>
                <th style={{ width: "230px" }} scope="col">
                  Name
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Location
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Circle
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Treatment
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Source
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Days and Time
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p style={{ textAlign: "left", fontSize: 14 }}>
                    {props.state.preSalesAgentName}
                  </p>
                </td>

                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.preAgentData.locations &&
                      props.state.preAgentData.locations.map((value, key) => {
                        return (
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.preAgentData.telecomCircles &&
                      props.state.preAgentData.telecomCircles.map(
                        (value, key) => {
                          return (
                            <p
                              style={{
                                textAlign: "left",
                                fontSize: 14,
                              }}
                            >
                              {value.name}
                            </p>
                          );
                        }
                      )}
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.preAgentData.treatments &&
                      props.state.preAgentData.treatments.map((value, key) => {
                        return (
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>

                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.preAgentData.sources &&
                      props.state.preAgentData.sources.map((value, key) => {
                        return (
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>

                <td>
                  {" "}
                  {props.state.preAgentData.scheduleDays &&
                    props.state.preAgentData.scheduleDays.map((value, key) => {
                      return (
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {value.day}({value.startTime} to {value.endTime})
                        </p>
                      );
                    })}
                </td>

                <td>
                  {" "}
                  {props.state.preAgentData.scheduleDays &&
                    props.state.preAgentData.scheduleDays.map((value, key) => {
                      return (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: 14,
                            color: props.setColors(value.status),
                            fontWeight: 700,
                          }}
                        >
                          {value.status ? "ON" : "OFF"}
                        </p>
                      );
                    })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
      </div>
    </>
  );
}

function LeadAssignComponent(props) {
  return (
    <>
      <div
        style={{
          width: "90%",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        <div className="col-2 m-0">
          <b>Department</b>
          <br />

          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 150,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 30,
              }}
            >
              {props?.state?.department?.selected
                ? props?.state?.department?.selected
                : "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {props.state.department.list.map(({ name, id }, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 200,
                      maxHeight: 200,
                    }}
                    onClick={() => {
                      props.state.handleDepartmentChange(id, name);
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-2 m-0">
          <b>Assigned To</b>
          <br />

          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 150,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 30,
              }}
            >
              {props?.state?.assignedUser?.selected
                ? props?.state?.assignedUser?.selected
                : "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {props.state.assignedUser.list.map(({ name, code }, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 200,
                      maxHeight: 200,
                    }}
                    onClick={() => {
                      props.state.handleAssignedToChange(code, name);
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="col-2  m-0 ">
            <b>Stages</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.stages}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedStages}
                onChange={(value, event) =>
                  props.state.handleSelectStages(value, event)
                }
                setState={props.state.setSelectedStages}
              />
            </div>
          </div>

          <div className="col-2  m-0 ">
            <b>Location</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.locationtData}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedLocations}
                onChange={(value, event) =>
                  props.state.handleSelectLocations(value, event)
                }
                setState={props.state.setSelectedLocations}
              />
            </div>
          </div>
          {/* <div className="col-1  m-0 ">
            <b>Circle</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.circles}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedCircles}
                onChange={(value, event) =>
                  props.state.handleSelectCircles(value, event)
                }
                setState={props.state.setSelectedCircles}
              />
            </div>
          </div> */}
          <div className="col-2 m-0">
            <b>Package</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.treatmentData}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedTreatments}
                onChange={(value, event) =>
                  props.state.handleSelectTreatments(value, event)
                }
                setState={props.state.setSelectedTreatments}
              />
            </div>
          </div>
          <div className="col-2 m-0">
            <b>Source</b>
            <div
              style={{
                width: 140,
                height: 35,
                backgroundColor: "white",
                border: "1px solid  #00997c",
                marginTop: 8,
              }}
            >
              <ReactMultiselectCheckboxes
                options={props.state.sources}
                getDropdownButtonLabel={props.state.getDropdownButtonLabel}
                value={props.state.selectedSource}
                onChange={(value, event) =>
                  props.state.handleSelectSources(value, event)
                }
                setState={props.state.setSelectedSource}
              />
            </div>
          </div>
          {sheduledDaysConponent(props.state, props.setColors)}
        </div>

        {props.state.isSave && (
          <div className="col-2 mt-4" style={{ marginRight: 0 }}>
            <button
              style={{
                height: 35,
                width: 70,
                backgroundColor: "#0a67b2",
              }}
              onClick={() => props.state.saveAgentsDetails()}
            >
              Save
            </button>
          </div>
        )}
      </div>

      <div className="row" style={{ width: "100%" }}>
        <hr style={{ marginTop: 20 }} />

        <div className="row" style={{ width: "100%", padding: 30 }}>
          <table class="table table-bordered">
            <thead>
              <tr style={{ width: "100%" }}>
                <th style={{ width: "230px" }} scope="col">
                  Name
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Location
                </th>
                {/* <th style={{ width: "230px" }} scope="col">
                  Circle
                </th> */}
                <th style={{ width: "230px" }} scope="col">
                  Package
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Source
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Days and Time
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Status
                </th>
                <th style={{ width: "230px" }} scope="col">
                  Stages
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p style={{ textAlign: "left", fontSize: 14 }}>
                    {props.state.salesAgentName}
                  </p>
                </td>

                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.agentData.locations &&
                      props.state.agentData.locations.map((value, key) => {
                        return (
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>
                {/* <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.agentData.telecomCircles &&
                      props.state.agentData.telecomCircles.map((value, key) => {
                        return (
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: 14,
                            }}
                          >
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td> */}
                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.agentData.treatments &&
                      props.state.agentData.treatments.map((value, key) => {
                        return (
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>

                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.agentData.sources &&
                      props.state.agentData.sources.map((value, key) => {
                        return (
                          <p style={{ textAlign: "left", fontSize: 14 }}>
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>

                <td>
                  {" "}
                  {props.state.agentData.scheduleDays &&
                    props.state.agentData.scheduleDays.map((value, key) => {
                      return (
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {value.day}({value.startTime} to {value.endTime})
                        </p>
                      );
                    })}
                </td>

                <td>
                  {" "}
                  {props.state.agentData.scheduleDays &&
                    props.state.agentData.scheduleDays.map((value, key) => {
                      return (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: 14,
                            color: props.setColors(value.status),
                            fontWeight: 700,
                          }}
                        >
                          {value.status ? "ON" : "OFF"}
                        </p>
                      );
                    })}
                </td>
                <td>
                  {" "}
                  {props.state.agentData.statusStages &&
                    props.state.agentData.statusStages.map((value, key) => {
                      return (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: 14,
                            color: "#000",
                          }}
                        >
                          {value.name}
                        </p>
                      );
                    })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
      </div>
    </>
  );
}

function BulkMessage(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
      }}
    >
      <div className="col-2">
        <b>Status</b>
        <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {props.state.status ? props.state.status : "Status"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
              }}
            >
              {props.state.statusList
                ? props.state.statusList.map((data, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{
                          width: 200,
                        }}
                        onClick={() =>
                          props.state.handleStatus(data.name, data.id)
                        }
                      >
                        {data.name}
                      </Dropdown.Item>
                    );
                  })
                : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="col-2">
        <b>Mode</b>
        <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {props.state.mode ? props.state.mode : "Mode"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
              }}
            >
              {props.state.messageMode
                ? props.state.messageMode.map((data, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() => props.state.handleMode(data)}
                      >
                        {data}
                      </Dropdown.Item>
                    );
                  })
                : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="col-2 m-0">
        <b>From</b>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #00997c",
            width: 160,
            height: 40,
            borderRadius: 5,
            marginBottom: 7,
          }}
        >
          <DatePicker
            className="mySearchEnquiryUpDatePicker followUpdate"
            selected={
              props.state.searchEnquiryFromDate
                ? props.state.searchEnquiryFromDate
                : new Date()
            }
            onChange={(date) => {
              props.state.setSearchEnquiryFromDate(date);
            }}
            maxDate={new Date()}
          />
        </div>
      </div>

      <div className="col-2 m-0">
        <b>To</b>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #00997c",
            width: 160,
            height: 40,
            borderRadius: 5,
          }}
        >
          <DatePicker
            className="mySearchEnquiryUpDatePicker followUpdate"
            selected={
              props.state.searchEnquiryToDate
                ? props.state.searchEnquiryToDate
                : new Date()
            }
            onChange={(date) => {
              props.state.setSearchEnquiryToDate(date);
            }}
            maxDate={new Date()}
          />
        </div>
      </div>
      {props.state.activeTab.isBulkMessageActive && (
        <div className="col-1 mt-0">
          <button
            style={{
              width: "100%",
              height: 50,
              marginLeft: 15,
            }}
            onClick={() => props.state.sendBulkMessage()}
          >
            SendMessage
          </button>
        </div>
      )}
    </div>
  );
}

function Tab(props) {
  return (
    <button
      style={{
        width: "100%",
        height: 50,
        marginLeft: 15,
        cursor: "pointer",
        backgroundColor: props.isActive ? "#06c6a2" : "lightgray",
      }}
      onClick={props.onClick}
    >
      {props.name}
    </button>
  );
}

function BulkMessageComponent(props) {
  const [isBulkMessageActive, setIsBulkMessageActive] = useState(true);
  const [isCreateCampaign, setIsCreateCampaign] = useState(false);
  const [isCallCampaign, setIsCallCampaign] = useState(false);
  return (
    <div>
      <div className="col-3 bulk-tab">
        {/* hided bulk messages tab */}
        <Tab
          name="Bulk Message"
          onClick={() => {
            setIsBulkMessageActive(true);
            setIsCreateCampaign(false);
            setIsCallCampaign(false);
          }}
          isActive={isBulkMessageActive}
        />
        <Tab
          name="Message Campaign"
          onClick={() => {
            setIsBulkMessageActive(false);
            setIsCallCampaign(false);
            setIsCreateCampaign(true);
          }}
          isActive={isCreateCampaign}
        />
        <Tab
          name="Call Campaign"
          onClick={() => {
            setIsBulkMessageActive(false);
            setIsCreateCampaign(false);
            setIsCallCampaign(true);
          }}
          isActive={isCallCampaign}
        />
      </div>
      {isBulkMessageActive && <BulkMessage state={props.state} />}
      {isCreateCampaign && <CreateCampaign />}
      {isCallCampaign && <CallCampaign />}
    </div>
  );
}

function BlackListComponent(props) {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div className="col-md-2">
          <b>Number </b>
          <div
            style={{
              height: 40,
              borderRadius: 5,
              border: `1px solid black`,
              display: "flex",
              width: 220,
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                className="drop-down"
                variant="none"
                style={{
                  height: 25,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                {props.state.countryCode?.name
                  ? props.state.countryCode.name
                  : "+91"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: 40,
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {props.state.countryCodes.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{
                        maxHeight: 60,
                      }}
                      onClick={() => props.state.setCountryCode(value)}
                    >
                      {value.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <input
              onChange={(e) => props.state.setBlackNumber(e.target.value)}
              placeholder="Phone Number"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div>

        {props.state.isSave && (
          <div className="col-2 mt-3">
            <button
              style={{
                height: 40,
                width: 70,
              }}
              onClick={() => props.state.saveBlackDetails()}
            >
              Blacklist
            </button>
          </div>
        )}
      </div>

      <div className="row" style={{ width: "100%" }}>
        <hr style={{ marginTop: 20 }} />

        <div
          style={{
            width: "100%",
            height: "55vh",
            overflow: "auto",
            padding: 30,
          }}
        >
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Number</th>
              </tr>
            </thead>
            <tbody>
              {props.state.blackListData &&
                props.state.blackListData.map((val) => {
                  return (
                    <tr>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val.name}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val.phoneNumber}
                        </p>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <hr />
      </div>
    </>
  );
}

function AssignBuddyComponent(props) {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div className="col- m-0">
          <b>Assigned To</b>
          <br />

          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 150,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 30,
              }}
            >
              {props.state.assignedBuddy.selected
                ? props.state.assignedBuddy.selected
                : "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {props.state.assignedBuddy.list.map(({ name, code }, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 160,
                    }}
                    onClick={() => {
                      props.state.handleAssignedBuddyToChange(code, name);
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-1  m-0 ">
          <b>Location</b>
          <div
            style={{
              width: 140,
              height: 35,
              backgroundColor: "white",
              border: "1px solid  #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <ReactMultiselectCheckboxes
              options={props.state.locationtData}
              getDropdownButtonLabel={props.state.getDropdownButtonLabel}
              value={props.state.selectedLocations}
              onChange={(value, event) =>
                props.state.handleSelectLocations(value, event)
              }
              setState={props.state.setSelectedLocations}
            />
          </div>
        </div>
        {/* <div className="col-1  m-0 ">
     <b>Center</b>
     <div
       style={{
         width: 140,
         height: 35,
         backgroundColor: "white",
         border: "1px solid  #00997c",
         borderRadius: 5,
         marginTop: 8,
       }}
     >
       <ReactMultiselectCheckboxes
         options={state.centerData}
         getDropdownButtonLabel={state.getDropdownButtonLabel}
         value={state.selectedCenters}
         onChange={(value, event) =>
           state.handleSelectCenters(value, event)
         }
         setState={state.setSelectedCenters}
       />
     </div>
    </div> */}
        {sheduledDaysConponent(props.state, props.setColors)}
        {props.state.isSave && (
          <div className="col-2 mt-3">
            <button
              style={{
                height: 40,
                width: 70,
              }}
              onClick={() => props.state.saveKareBuddy()}
            >
              Save
            </button>
          </div>
        )}
      </div>

      <div className="row" style={{ width: "100%" }}>
        <hr style={{ marginTop: 20 }} />

        <div className="row" style={{ width: "100%", padding: 30 }}>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Location</th>
                {/* <th scope="col">Centers</th> */}
                <th scope="col">Days and Time</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p style={{ textAlign: "left", fontSize: 14 }}>
                    {props.state.kareBuddyName}
                  </p>
                </td>

                <td>
                  <div
                    style={{
                      maxHeight: 275,
                      width: "100%",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {props.state.buddyDetails?.locations &&
                      props.state.buddyDetails?.locations.map((value, key) => {
                        return (
                          <p
                            style={{
                              textAlign: "left",
                              fontSize: 14,
                            }}
                          >
                            {value.name}
                          </p>
                        );
                      })}
                  </div>
                </td>
                {/* <td>
                          <div
                            style={{
                              maxHeight: 275,
                              width: "100%",
                              overflowY: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {state.buddyDetails?.centers &&
                              state.buddyDetails?.centers.map((value, key) => {
                                return (
                                  <p
                                    style={{ textAlign: "left", fontSize: 14 }}
                                  >
                                    {value.name}
                                  </p>
                                );
                              })}
                          </div>
                        </td> */}
                <td>
                  {" "}
                  {props.state.buddyDetails?.scheduleDays &&
                    props.state.buddyDetails?.scheduleDays.map((value, key) => {
                      return (
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {value.day}({value.startTime} to {value.endTime})
                        </p>
                      );
                    })}
                </td>

                <td>
                  {" "}
                  {props.state.buddyDetails?.scheduleDays &&
                    props.state.buddyDetails?.scheduleDays.map((value, key) => {
                      return (
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: 14,
                            color: props.setColors(value.status),
                            fontWeight: 700,
                          }}
                        >
                          {value.status ? "ON" : "OFF"}
                        </p>
                      );
                    })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
      </div>
    </>
  );
}

function LeadTransferComponent(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <div className="col-1 m-0">
        <b>Transfer From</b>
        <br />
        <Dropdown>
          <Dropdown.Toggle
            variant="none"
            style={{
              width: 140,
              height: 36,
              border: `1px solid #00997c`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.state.assignedFrom.selected
              ? props.state.assignedFrom.selected
              : "Select"}
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              maxHeight: 200,
              overflowY: "scroll",
              overflowX: "hidden",
              width: 150,
            }}
          >
            {props.state.assignedActiveUser.list.map(
              ({ name, code }, index) => {
                return (
                  <>
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{
                        width: 160,
                      }}
                      onClick={() => {
                        props.state.handleAssignedLeadFromChange(code, name);
                      }}
                    >
                      {name}
                    </Dropdown.Item>
                  </>
                );
              }
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="col-1 m-0">
        <b>Transfer To</b>
        <br />
        <Dropdown>
          <Dropdown.Toggle
            variant="none"
            style={{
              width: 140,
              height: 36,
              border: `1px solid #00997c`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.state.assignedLeadTo.selected
              ? props.state.assignedLeadTo.selected
              : "Select"}
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              maxHeight: 200,
              overflowY: "scroll",
              overflowX: "hidden",
              width: 150,
            }}
          >
            {props.state.assignedUser.list.map(({ name, code }, index) => {
              return (
                <>
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 160,
                    }}
                    onClick={() => {
                      props.state.handleAssignedLeadToChange(code, name);
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="col-1 m-0">
        <b>Status</b>
        <div
          style={{
            width: 140,
            height: 35,
            backgroundColor: "white",
            border: "1px solid  #00997c",
            marginTop: 8,
          }}
        >
          <ReactMultiselectCheckboxes
            options={props.state.statusData}
            placeholderButtonLabel={"Choose"}
            getDropdownButtonLabel={props.state.getDropdownButtonLabel}
            value={props.state.selectedStatuses}
            onChange={(value, event) =>
              props.state.handleSelectStatuses(value, event)
            }
            setState={props.state.setSelectedStatuses}
          />
        </div>
      </div>
      <div className="col-1 m-0">
        <b>Source</b>
        <div
          style={{
            width: 140,
            height: 35,
            backgroundColor: "white",
            border: "1px solid  #00997c",
            marginTop: 8,
          }}
        >
          <ReactMultiselectCheckboxes
            options={props.state.sources}
            getDropdownButtonLabel={props.state.getDropdownButtonLabel}
            value={props.state.selectedSource}
            onChange={(value, event) =>
              props.state.handleSelectSources(value, event)
            }
            setState={props.state.setSelectedSource}
          />
        </div>
      </div>
      <div className="col-1 m-0">
        <b>Location</b>
        <div
          style={{
            width: 140,
            height: 35,
            backgroundColor: "white",
            border: "1px solid  #00997c",
            marginTop: 8,
          }}
        >
          <ReactMultiselectCheckboxes
            options={props.state.locationtData}
            placeholderButtonLabel={"Choose"}
            getDropdownButtonLabel={props.state.getDropdownButtonLabel}
            value={props.state.selectedLocations}
            onChange={(value, event) =>
              props.state.handleSelectLocations(value, event)
            }
            setState={props.state.setSelectedLocations}
          />
        </div>
      </div>
      <div className="col-1 m-0">
        <b>Treatment</b>
        <div
          style={{
            width: 140,
            height: 35,
            backgroundColor: "white",
            border: "1px solid  #00997c",
            marginTop: 8,
            paddingLeft: 20,
          }}
        >
          <ReactMultiselectCheckboxes
            options={props.state.treatmentData}
            placeholderButtonLabel={"Choose"}
            getDropdownButtonLabel={props.state.getDropdownButtonLabel}
            value={props.state.selectedTreatments}
            onChange={(value, event) =>
              props.state.handleSelectTreatments(value, event)
            }
            setState={props.state.setSelectedTreatments}
          />
        </div>
      </div>
      <div className="col-1 m-0">
        <b>Choose Date</b>
        <Dropdown>
          <Dropdown.Toggle
            variant="none"
            style={{
              width: 140,
              height: 36,
              border: `1px solid #00997c`,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.state.assignedData.chooseDate
              ? props.state.assignedData.chooseDate
              : "All"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {props.state.enquiryDateFilter.map((value, index) => {
              return (
                <>
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 160,
                    }}
                    onClick={() => {
                      props.state.setAssignedData((s) => ({
                        ...s,
                        chooseDate: value,
                      }));
                    }}
                  >
                    {value}
                  </Dropdown.Item>
                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>

        {props.state.assignedData.chooseDate === "Choose Date" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #00997c",
              width: 160,
              height: 40,
              borderRadius: 5,
              marginBottom: 7,
            }}
          >
            <label
              style={{
                marginLeft: 10,
              }}
            >
              From
            </label>
            <DatePicker
              className="mySearchEnquiryUpDatePicker followUpdate"
              selected={
                props.state.assignedData.fromDate
                  ? props.state.assignedData.fromDate
                  : new Date()
              }
              onChange={(date) => {
                props.state.setAssignedData((s) => ({ ...s, fromDate: date }));
              }}
              maxDate={new Date()}
            />
          </div>
        ) : null}
        {props.state.assignedData.chooseDate === "Choose Date" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #00997c",
              width: 160,
              height: 40,
              borderRadius: 5,
            }}
          >
            <label
              style={{
                marginLeft: 10,
              }}
            >
              To
            </label>
            <DatePicker
              className="mySearchEnquiryUpDatePicker followUpdate"
              selected={
                props.state.assignedData.toDate
                  ? props.state.assignedData.toDate
                  : new Date()
              }
              onChange={(date) => {
                props.state.setAssignedData((s) => ({ ...s, toDate: date }));
              }}
              maxDate={new Date()}
            />
          </div>
        ) : null}
      </div>
      <div
        style={{
          width: "15%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingTop: "15px",
        }}
        className="col-1 m-0"
      >
        <b>Change to New Lead </b>
        <input
          style={{
            width: 20,
            height: 20,
            marginLeft: "5%",
          }}
          type="checkbox"
          value={props.state.isNewLead}
          onChange={(value) => {
            props.state.setIsNewLead(value.target.checked);
          }}
        />
      </div>
      {props.state.activeTab.isLeadAssignActive && (
        <div className="col-2 mt-3">
          <button
            style={{
              height: 40,
              width: 70,
            }}
            onClick={() => props.state.saveAgentsDetails()}
          >
            Save
          </button>
        </div>
      )}

      {props.state.activeTab.isBlackListActive && (
        <div className="col-2 mt-3">
          <button
            style={{
              height: 40,
              width: 70,
            }}
            onClick={() => props.state.saveAgentsDetails()}
          >
            Save
          </button>
        </div>
      )}
      {props.state.activeTab.isBulkAssignActive && (
        <div className="col-2 mt-3">
          <button
            style={{
              height: 40,
              width: 70,
              backgroundColor: "#0a67b2",
            }}
            onClick={() => props.state.bulkAssign()}
          >
            Transfer
          </button>
        </div>
      )}
    </div>
  );
}

function TargetManagementComponent(props) {
  return (
    <>
      <h5>Configure Target</h5>
      <hr style={{ marginTop: 20 }} />
      <div
        className="row"
        // style={{
        //   width: "100%",
        //   display: "flex",
        // }}
      >
        <div className="col-1 m-0">
          <b>Department</b>
          <br />

          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 150,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 30,
              }}
            >
              {props?.state?.department?.selected
                ? props?.state?.department?.selected
                : "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {props.state.department.list
                .filter(({ name }) => name == "SALES")
                .map(({ name, id }, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{
                        width: 200,
                        maxHeight: 200,
                      }}
                      onClick={() => {
                        props.state.handleDepartmentChange(id, name);
                      }}
                    >
                      {name}
                    </Dropdown.Item>
                  );
                })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-1 m-0">
          <b>Assigned To</b>
          <br />

          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 150,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: 30,
              }}
            >
              {props.state.assignedTargetUser.selected
                ? props.state.assignedTargetUser.selected
                : "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              as={CustomMenu}
              style={{
                maxHeight: 200,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 260,
              }}
            >
              {props.state.assignedUser.list.map(({ name, code }, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 200,
                      maxHeight: 200,
                    }}
                    // onClick={() => {
                    //   props.state.handleAssignedToChange(code, name);
                    // }}
                    onClick={() => {
                      props.state.handleTargetAssignedUser(code, name);
                    }}
                  >
                    {name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-1 m-0">
          <b>Year</b>
          <div>
            <input
              value={props.state.selectedYear}
              placeholder="Month"
              style={{
                height: 35,
                width: "140px",
                borderRadius: 5,
                border: `1px solid #00997c`,
                outline: "none",
                marginTop: 8,
              }}
            />
          </div>
        </div>
        <div className="col-1 m-0">
          <b>Month</b>

          <div
            style={{
              marginTop: 8,
              border: `1px solid white`,
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.state.selectedMonth || ""}
              onChange={(e) => props.state.setSelectedMonth(e.target.value)}
              sx={{
                width: 140,
                maxHeight: 35,
                backgroundColor: "white",
                border: "1px solid #00997c",
                "& .MuiInputBase-root": {
                  marginTop: "15px !important",
                },
              }}
            >
              {props?.state?.monthList?.map((list, index) => {
                return (
                  <MenuItem value={list} key={index}>
                    {list}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="col-1 m-0">
          <b>Ip Target</b>
          <div
            style={{
              width: 200,
              height: 35,
              backgroundColor: "white",
              border: "1px solid  #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <input
              onChange={(e) => props.state.setIpTarget(e.target.value)}
              placeholder="Numebr of Ip Target"
              style={{
                // height: "100%",
                // width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div>
        <div className="col-1 optarget ">
          <b>Op target</b>
          <div
            style={{
              width: 200,
              height: 35,
              backgroundColor: "white",
              border: "1px solid #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <input
              onChange={(e) => props.state.setOpTarget(e.target.value)}
              placeholder="no of output target"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div>
        {/* <div className="col-1 m-0 opTarget">
          <b>Op Target</b>
          <div
            style={{
              width: 200,
              height: 35,
              backgroundColor: "white",
              border: "1px solid  #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <input
              onChange={(e) => props.state.setOpTarget(e.target.value)}
              placeholder="Numebr of Op Target"
              style={{
                // height: "100%",
                // width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div> */}
        <div className="col-2  revenue">
          <b>Revenue</b>
          <div
            style={{
              width: 200,
              height: 35,
              backgroundColor: "white",
              border: "1px solid  #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <input
              onChange={(e) => props.state.setRevenue(e.target.value)}
              placeholder="Amount of Revenue"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div>
        {/*<-- Budgeted spent field   --> */}
        {/* <div className="col-2 m-0 Budget">
          <b>Budget</b>
          <div
            style={{
              width: 200,
              height: 35,
              backgroundColor: "white",
              border: "1px solid  #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <input
              onChange={(e) => props.state.setBudgetedSpent(e.target.value)}
              placeholder="Budgeted spent"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div> */}
        {/*<-- Budgeted Leads field   --> */}
        <div className="col-1 m-0 Budget">
          <b>Budget Leads</b>
          <div
            style={{
              width: 200,
              height: 35,
              backgroundColor: "white",
              border: "1px solid  #00997c",
              borderRadius: 5,
              marginTop: 8,
            }}
          >
            <input
              onChange={(e) => props.state.setNumberOfLeads(e.target.value)}
              placeholder="Budgeted Leads"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
            />
          </div>
        </div>
        {/*<-- Budget Leads field   --> */}
        <div className="col-2 mt-3 targetSave">
          <button
            style={{
              height: "40px",
              width: 70,
              marginTop: 8,
            }}
            onClick={() => props.state.saveTargetDetails()}
          >
            Save
          </button>
        </div>
      </div>
      <hr style={{ marginTop: 20 }} />
      <h5>Search Target</h5>
      <hr style={{ marginTop: 20 }} />
      <div
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <div className="col-1 m-0">
          <b>Month</b>

          <div
            style={{
              marginTop: 8,
              border: `1px solid white`,
            }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.state.searchMonth || ""}
              onChange={(e) => props.state.setSearchMonth(e.target.value)}
              sx={{
                width: 140,
                maxHeight: 35,
                backgroundColor: "white",
                border: "1px solid #00997c",
                "& .MuiInputBase-root": {
                  marginTop: "15px !important",
                },
              }}
            >
              {props?.state?.monthList?.map((list, index) => {
                return (
                  <MenuItem value={list} key={index}>
                    {list}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="col-2 mt-3">
          <button
            style={{
              height: "40px",
              width: 70,
              marginTop: 8,
            }}
            onClick={() => props.state.searchTargetDetails()}
          >
            Search
          </button>
        </div>
      </div>

      <hr style={{ marginTop: 20 }} />

      <div className="row" style={{ padding: 30 }}>
        <table class="table ">
          <thead
            style={{
              backgroundColor: "#06c6a2",
              color: "white",
              width: "100%",
            }}
          >
            <tr>
              <th scope="col">Assigned To</th>
              <th scope="col">Year</th>
              <th scope="col">Month</th>
              <th scope="col">Ip Target</th>
              <th scope="col">Ip Scheduled</th>
              <th scope="col">Op Target</th>
              <th scope="col">Op Scheduled</th>
              <th scope="col">Revenue</th>
              <th scope="col">Budgeted Leads</th>
              {/* <th scope="col">Surgery Suggested</th> */}
            </tr>
          </thead>
          <tbody>
            <>
              {props.state.targetDetail?.length ? (
                props.state.targetDetail.map((val, index) => {
                  console.log("val", val);
                  return (
                    <tr>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.teamName}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.yearValue}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.monthValue}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.ipTarget}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.ipScheduledTarget}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.opTarget}
                        </p>
                      </td>{" "}
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.opScheduledTarget}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.revenue}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.budgetedLeads}
                        </p>
                      </td>
                      {/* <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.surgerySuggestedTarget}
                        </p>
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <div>NO Data Found...!</div>
              )}
            </>
          </tbody>
        </table>
      </div>
      <hr />
    </>
  );
}

function DigitalLeadComponent(props) {
  const digitalmonth = [
    { id: "1", label: "January" },
    { id: "2", label: "February" },
    { id: "3", label: "March" },
    { id: "4", label: "April" },
    { id: "5", label: "May" },
    { id: "6", label: "June" },
    { id: "7", label: "July" },
    { id: "8", label: "August" },
    { id: "9", label: "September" },
    { id: "10", label: "October" },
    { id: "11", label: "November" },
    { id: "12", label: "December" },
  ];

  const getMonthName = (num) => {
    const month = digitalmonth[num - 1]?.label;
    return month;
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div className="col-1 ">
          <b>Location</b>
          <div
            style={{
              width: 140,
              height: 35,
              backgroundColor: "white",
              // border: "1px solid  #00997c",
              marginTop: 8,
            }}
          >
            <select
              id="mySelect"
              style={{
                width: 140,
                height: 35,
                paddingLeft: "10px",
              }}
              value={props?.state?.leadDigitalManagememt.locationId}
              onChange={(e) =>
                props?.state?.setLeadDigitalManagement({
                  ...props.state.leadDigitalManagememt,
                  locationId: e.target.value,
                })
              }
            >
              {props?.state?.locationtData?.map((data) => (
                <option value={data.value}>{data.label}</option>
              ))}
            </select>

            {/* <ReactMultiselectCheckboxes
            options={props.state.locationtData}
            placeholderButtonLabel={"Choose"}
            getDropdownButtonLabel={props.state.getDropdownButtonLabel}
            value={props.state.selectedLocations}
            onChange={(value, event) =>
              props.state.handleSelectLocations(value, event)
            }
            setState={props.state.setSelectedLocations}
          /> */}
          </div>
        </div>
        <div className="col-1 ">
          <b>Treatment</b>
          <div
            style={{
              width: 140,
              height: 35,
              backgroundColor: "white",
              marginTop: 8,
            }}
          >
            <select
              id="mySelect"
              style={{
                width: 140,
                height: 35,
                paddingLeft: "10px",
              }}
              value={props?.state?.leadDigitalManagememt.treatmentId}
              onChange={(e) =>
                props?.state?.setLeadDigitalManagement({
                  ...props.state.leadDigitalManagememt,
                  treatmentId: e.target.value,
                })
              }
            >
              {props.state.treatmentData?.map((data) => (
                <option value={data.value}>{data.label}</option>
              ))}
            </select>

            {/* <ReactMultiselectCheckboxes
            options={props.state.treatmentData}
            placeholderButtonLabel={"Choose"}
            getDropdownButtonLabel={props.state.getDropdownButtonLabel}
            value={props.state.selectedTreatments}
            onChange={(value, event) =>
              props.state.handleSelectTreatments(value, event)
            }
            setState={props.state.setSelectedTreatments}
          /> */}
          </div>
        </div>

        <div className="col-1 ">
          <b>Month</b>
          <div
            style={{
              width: 140,
              height: 35,
              backgroundColor: "white",
              marginTop: 8,
            }}
          >
            <select
              id="mySelect"
              style={{
                width: 140,
                height: 35,
                paddingLeft: "10px",
              }}
              value={props?.state?.leadDigitalManagememt.month}
              onChange={(e) =>
                props?.state?.setLeadDigitalManagement({
                  ...props.state.leadDigitalManagememt,
                  month: e.target.value,
                })
              }
            >
              {digitalmonth.map((data) => (
                <option value={data.id}>{data.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-1 ">
          <b>Source</b>
          <div
            style={{
              width: 140,
              height: 35,
              backgroundColor: "white",
              marginTop: 8,
            }}
          >
            <select
              id="mySelect"
              style={{
                width: 140,
                height: 35,
                paddingLeft: "10px",
              }}
              value={props?.state?.leadDigitalManagememt.sourceType}
              onChange={(e) =>
                props?.state?.setLeadDigitalManagement({
                  ...props.state.leadDigitalManagememt,
                  sourceType: e.target.value,
                })
              }
            >
              <option value="FACEBOOK">Facebook</option>
              <option value="GOOGLE">Google</option>
            </select>
          </div>
        </div>

        <div className="col-1 ">
          <b>Leads Need</b>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              marginTop: 5,
            }}
          >
            <input
              type="text"
              style={{
                width: "100%",
                height: 35,
                borderRadius: "0px",
              }}
              value={props?.state?.leadDigitalManagememt.leadsNeed}
              onChange={(e) =>
                props?.state?.setLeadDigitalManagement({
                  ...props.state.leadDigitalManagememt,
                  leadsNeed: e.target.value,
                })
              }
            />
          </div>
        </div>
        {/* <div className="col-1 ml-2">
        <b>Expected CPL</b>
        <div
          style={{
            width: "100%",
            height: 35,
            backgroundColor: "white",
            borderRadius: 5,
            marginTop: 8,
            paddingLeft: 20,
          }}
        >
          <input
            type="text"
            style={{
              width: "100%",
              height: 35,
            }}
          />
        </div>
      </div> */}
        <div className="col-1 ">
          <b>Spent</b>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              marginTop: 5,
            }}
          >
            <input
              type="text"
              style={{
                width: "100%",
                height: 35,
                borderRadius: "0px",
              }}
              value={props?.state?.leadDigitalManagememt.spent}
              onChange={(e) =>
                props?.state?.setLeadDigitalManagement({
                  ...props.state.leadDigitalManagememt,
                  spent: e.target.value,
                })
              }
            />
          </div>
        </div>

        {props.state.activeTab.isDigitalLeadActive && (
          <div className="col-2 mt-3">
            <button
              style={{
                height: 40,
                width: 70,
                marginTop: "14px",
                backgroundColor: "#0a67b2",
              }}
              // onClick={() => props.state.saveAgentsDetails()}
              onClick={() => props.state.saveLeadDigitalManagements()}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          paddingLeft: "40px",
          marginTop: "3rem",
        }}
      >
        <div className="col-1 m-0">
          <b>Month</b>

          <div
            style={{
              marginTop: 5,
              border: `1px solid white`,
            }}
          >
            <select
              // labelId="demo-simple-select-label"
              id="mySelect"
              style={{
                width: 140,
                height: 35,
                paddingLeft: "10px",
              }}
              value={props.state.searchDigitalMonth || ""}
              onChange={(e) =>
                props.state.setSearchDigitalMonth(e.target.value)
              }
              sx={{
                width: 140,
                maxHeight: 35,
                backgroundColor: "white",
                border: "1px solid #00997c",
                "& .MuiInputBase-root": {
                  marginTop: "15px !important",
                },
              }}
            >
              {/* {props?.state?.monthList?.map((list, index) => {
                return (
                  <MenuItem value={list} key={index}>
                    {list}
                  </MenuItem>
                );
              })} */}
              <option></option>
              {digitalmonth.map((data) => (
                <option value={data.id}>{data.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-2 mt-3">
          <button
            style={{
              height: "35px",
              width: 70,
              marginTop: 14,
              marginLeft: "90px",
              backgroundColor: "#0a67b2",
            }}
            onClick={() => props.state.searchDigitalLead()}
          >
            Search
          </button>
        </div>
      </div>

      <div className="row" style={{ padding: 15, height: 130 }}>
        <table class="table ">
          <thead
            style={{
              backgroundColor: "#06c6a2",
              color: "white",
              width: "100%",
            }}
          >
            <tr>
              <th scope="col">Source Type</th>
              <th scope="col">Location</th>
              <th scope="col">Treatment</th>
              <th scope="col">ExpCpl</th>
              <th scope="col">Month</th>
              <th scope="col">LeadsNeed</th>
              <th scope="col">Spent</th>
            </tr>
          </thead>
          <tbody>
            <>
              {props?.state?.searchDigitalLeadManagement?.length > 0 ? (
                props?.state?.searchDigitalLeadManagement?.map((val, index) => {
                  console.log("val", val);
                  return (
                    <tr>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.sourceType}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.location}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.treatment}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.expCpl}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {getMonthName(val?.month)}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.leadsNeed}
                        </p>
                      </td>
                      <td>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {val?.spent}
                        </p>
                      </td>{" "}
                    </tr>
                  );
                })
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No Data Found...!
                </div>
              )}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
}

const customList = (title, items) => (
  <Card>
    <CardHeader
      className={classes.cardHeader}
      avatar={
        <Checkbox
          color="primary"
          onClick={handleToggleAll(items)}
          checked={
            numberOfChecked(items) === items.length && items.length !== 0
          }
          indeterminate={
            numberOfChecked(items) !== items.length &&
            numberOfChecked(items) !== 0
          }
          disabled={items.length === 0}
          inputProps={{ "aria-label": "all items selected" }}
        />
      }
      title={title}
      subheader={`${numberOfChecked(items)}/${items.length} selected`}
    />
    <Divider />
    <List className={classes.list} dense component="div" role="list">
      {items.map((value) => {
        const labelId = `transfer-list-all-item-${value}-label`;
        return (
          <ListItem
            key={value}
            role="listitem"
            button
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${value.name}`} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  </Card>
);

function Settings() {
  const state = useSettings();

  const setColors = (value) => {
    let bgColor = "white";
    if (value === true) {
      bgColor = "#54B435";
    } else if (value === false) {
      bgColor = "#FF8787";
    }
    return bgColor;
  };

  const tock = localStorage?.getItem("token");

  console.log("=============tock=", tock);

  return (
    <div>
      <div className="report-containers">
        <div
          style={{
            width: "155px",
            height: "100%",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SideMenu />
        </div>
        <div
          style={{
            width: "100%",

            // backgroundColor:"red",
            overflow: "hidden",
            boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
            // paddingRight:"30px",
            // border: "1px solid gray",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "10vh",
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Header state={state} />
          </div>
          <hr />
          <div
            style={{
              width: "100%",
              height: "8vh",
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            {checkPermission("LEAD_MANGEMENT") && (
              <button
                style={{
                  width: "auto",
                  padding: `10px 10px`,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isLeadAssignActive
                    ? "#0a67b2"
                    : "lightgray",
                  marginRight: "5px",
                }}
                onClick={() => state.handleTab(0)}
              >
                Lead Management
              </button>
            )}

            {checkPermission("USER_ROLE_TAB") && (
              <button
                style={{
                  width: 150,
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isUserRole
                    ? "#0a67b2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(5)}
              >
                User Role
              </button>
            )}

            {checkPermission("ROLES_AND_PERMISSION_TAB") && (
              <button
                style={{
                  width: 250,
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isPermission
                    ? "#06c6a2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(2)}
              >
                Roles & Permissions
              </button>
            )}

            {checkPermission("BLALCK_LIST_TAB") && (
              <button
                style={{
                  width: 150,
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isBlackListActive
                    ? "#06c6a2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(3)}
              >
                Blacklist
              </button>
            )}

            {/* {checkPermission("BUDDY_MANAGEMENT_TAB") && (
              <button
                style={{
                  width: "auto",
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isAssignBuddyActive
                    ? "#06c6a2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(4)}
              >
                Buddy Management
              </button>
            )} */}

            {checkPermission("TARGET_MANAGEMENT_TAB") && (
              <button
                style={{
                  width: "auto",
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isTargetActive
                    ? "#06c6a2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(6)}
              >
                Target Management
              </button>
            )}
            {checkPermission("SERVICE_TAB") && (
              <button
                style={{
                  width: "auto",
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isPackages
                    ? "#06c6a2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(7)}
              >
                Packages
              </button>
            )}

            {/* <button
                style={{
                  width: "auto",
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isPreLeadActive
                    ? "#0a67b2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(7)}
              >
                Pre Lead Management
              </button> */}

            {/* <button
                style={{
                  width: "auto",
                  padding: `10px 10px`,
                  marginLeft: 10,
                  cursor: "pointer",
                  backgroundColor: state.activeTab.isDigitalLeadActive
                    ? "#0a67b2"
                    : "lightgray",
                }}
                onClick={() => state.handleTab(8)}
              >
                Digital Lead Management
              </button> */}
          </div>
          <hr />
          <div
            style={{
              paddingLeft: 10,
            }}
          >
            {state.activeTab.isPreLeadActive && (
              <PreLeadAssignComponent state={state} setColors={setColors} />
            )}

            {state.activeTab.isLeadAssignActive && (
              <LeadAssignComponent state={state} setColors={setColors} />
            )}
            {/* {state.activeTab.isPermission && (
              <BulkMessageComponent state={state} />
            )} */}

            {state.activeTab.isBlackListActive && (
              <BlackListComponent state={state} />
            )}

            {state.activeTab.isAssignBuddyActive && (
              <AssignBuddyComponent state={state} setColors={setColors} />
            )}

            {state.activeTab.isBulkAssignActive && (
              <LeadTransferComponent state={state} />
            )}
            {state.activeTab.isTargetActive && (
              <TargetManagementComponent state={state} setColors={setColors} />
            )}

            {state.activeTab.isPackages && <PackagesComponent />}

            {state.activeTab.isUserRole && <Form />}

            {state.activeTab.isPermission && <Roles state={state} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
function sheduledDaysConponent(state, setColors) {
  return (
    <div className="col-4 m-0" style={{ paddingLeft: 20 }}>
      <b>Days And Time</b>
      <div
        style={{
          width: 500,
          height: 36,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: `1px solid #00997c`,
          padding: 5,
          marginTop: 7,
        }}
        onClick={() => state.setIsTable(!state.isTable)}
      >
        <div
          style={{
            width: 110,
            fontSize: 14,
            height: 25,
            border: `1px solid #00997c`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Days <span style={{ paddingLeft: 50 }}> ▼</span>
        </div>
        <div
          style={{
            width: 110,
            fontSize: 14,
            height: 25,
            border: `1px solid #00997c`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          StartTime <span style={{ paddingLeft: 20 }}> ▼</span>
        </div>
        <div
          style={{
            width: 110,
            fontSize: 14,
            height: 25,
            border: `1px solid #00997c`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          EndTime <span style={{ paddingLeft: 20 }}> ▼</span>
        </div>
        <div
          style={{
            width: 110,
            fontSize: 14,
            height: 25,
            border: `1px solid #00997c`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Status <span style={{ paddingLeft: 20 }}> ▼</span>
        </div>
      </div>
      {state.isTable ? (
        <div
          style={{
            width: 500,
            height: 365,
            marginTop: 7,
            borderRadius: 5,
            border: `1px solid #00997c`,
            position: "absolute",
            zIndex: 999,
            backgroundColor: "#EFF5F5",
          }}
        >
          <div
            style={{
              width: 500,
              height: 365,
              display: "flex",
              justifyContent: "center",
              padding: 5,
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 120,
                height: 350,
                border: `1px solid #00997c`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {state.selectDays.map((values) => {
                return (
                  <div
                    style={{
                      width: 90,
                      height: 25,
                      border: `1px solid #00997c`,
                      marginBottom: 0,
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "start",
                      backgroundColor: setColors(values.status),
                    }}
                    id="hover"
                  >
                    <p
                      style={{
                        fontSize: 12,
                        paddingLeft: 3,
                        paddingTop: 3,
                      }}
                      onClick={() => state.setGetDay(values.day)}
                    >
                      {values.day}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                width: 120,
                height: 350,
                border: `1px solid #00997c`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {state.selectDays.map(({ key, startTime }) => {
                return (
                  <div
                    style={{
                      width: 70,
                      height: 25,
                      marginBottom: 0,
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                    id="hover"
                  >
                    <select
                      value={startTime}
                      onChange={(e) => state.handleDayTime(key, "startTime", e)}
                    >
                      {state.time.map((item, i) => (
                        <option key={i} value={item} style={{ fontSize: 12 }}>
                          {item.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                width: 120,
                height: 350,
                border: `1px solid #00997c`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {state.selectDays.map(({ key, endTime }) => {
                return (
                  <div
                    style={{
                      width: 70,
                      height: 25,
                      marginBottom: 0,
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                    id="hover"
                  >
                    <select
                      value={endTime}
                      onChange={(e) => state.handleDayTime(key, "endTime", e)}
                    >
                      {state.time.map((item, i) => (
                        <option key={i} value={item} style={{ fontSize: 12 }}>
                          {item.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                width: 120,
                height: 350,
                border: `1px solid #00997c`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {state.selectDays.map(({ key, status }) => {
                return (
                  <div
                    style={{
                      width: 70,
                      height: 25,
                      border: `1px solid #00997c`,
                      marginBottom: 0,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                    id="hover"
                  >
                    <div
                      style={{
                        width: 40,
                        height: 24,
                        backgroundColor: status ? "green" : "white",
                        border: `1px solid #00997c`,
                      }}
                      onClick={() => state.handleDayTime(key, "status", true)}
                    >
                      <p style={{ fontSize: 12, paddingTop: 3 }}>{"ON"}</p>
                    </div>
                    <div
                      style={{
                        width: 40,
                        height: 24,
                        backgroundColor: status ? "white" : "red",
                        border: `1px solid #00997c`,
                      }}
                      onClick={() => state.handleDayTime(key, "status", false)}
                    >
                      <p style={{ fontSize: 12, paddingTop: 3 }}>{"OFF"}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
