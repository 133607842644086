import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { checkPermission } from "../../util/permissionUtils";
import {
  getAllCentersList,
  postActiveCenter,
} from "../../services/mykare/centerService";

function Header({ state }) {
  const [time, setTime] = useState(moment());
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [centerName, setCenterName] = useState("");
  const [logoIcon, setLogoIcon] = useState("");

  const [centerList, setCenterList] = useState([]);

  const sendActiveCenter = async (value) => {
    const response = await postActiveCenter(value?.code);
    window?.location?.reload();
  };

  const loadCentersList = () => {
    getAllCentersList().then((data) => {
      setCenterList(data?.centers);
    });
  };

  const activeCenter = centerList.find((center) => center?.isActive === true);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const capitalize = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

  const handleSearchList = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const filterList = (list) =>
    list.filter((value) => {
      const name = value?.name?.toLowerCase() || "";
      const valueText = value?.value?.toLowerCase() || "";
      const term = searchTerm.toLowerCase();

      return name.includes(term) || valueText.includes(term);
    });

  useEffect(() => {
    getLogo();
    loadCentersList();
    const intervalId = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getLogo = () => {
    const logo = localStorage.getItem("logo");
    setLogoIcon(logo);
  };

  const getCurrentTime = () => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 12) {
      return "Good Morning";
    } else if (hours >= 12 && hours < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#074173",
      }}
    >
      <div
        style={{
          width: "30%",
          height: "80px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "#074173",
        }}
      >
        <h4
          style={{
            color: "white",
            fontWeight: 600,
            margin: 0,
            padding: 0,
            marginLeft: "10px",
            paddingTop: "10px",
          }}
        >
          {getCurrentTime()},
          {/* <span style={{ color: "#07ffd0" }}>Test Admin</span> */}
        </h4>
        <p
          style={{
            textAlign: "left",
            marginLeft: "10px",
            color: "#07ffd0",
            paddingTop: "7px",
          }}
        >
          Manage your sales leads effectively
        </p>
      </div>
      {checkPermission("CENTER_VIEW") && (
        <div
          style={{
            width: "54%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `2px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#074173",
                marginTop: "10px",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontWeight: 600,
                  paddingTop: "14px",
                }}
              >
                {activeCenter?.name || "Select Center"}
              </p>
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 210,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
                padding: "5px",
                borderRadius: "0px",
                backgroundColor: "#074173",
                color: "black",
                border: "2px solid #808080",
              }}
            >
              <style>
                {`
                         input::placeholder {
                         color: black;
                         font-weight:600;}

                         input {
                      caret-color: white;
                     background-color: gray,
                      }
                         
                         `}
              </style>
              <input
                type="text"
                placeholder="Search"
                onChange={handleSearchList}
                style={{
                  width: "100%",
                  padding: "5px",
                  boxSizing: "border-box",
                  marginBottom: "5px",
                  borderRadius: "0px",
                  border: "1px solid gray",

                  color: "black",
                }}
              />
              {filterList(centerList).map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{
                      width: 200,
                      maxHeight: 210,
                      borderRadius: "0px",
                      color: "white",
                      padding: "10px 5px",
                      backgroundColor:
                        hoveredIndex === index ? "#0056b3" : "#074173",
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => {
                      sendActiveCenter(value);
                    }}
                  >
                    {capitalize(value.name)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <div
        style={{
          width: "27%",
          height: "70px",
          backgroundColor: "#074173",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: "200px",
            height: "70px",

            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Typography style={{ color: "white", fontSize: "30px" }}>
            {time?.format("LTS")}
          </Typography>
        </div>

        <img
          src={logoIcon}
          style={{ width: 140, height: 50, marginLeft: "20px" }}
        />
      </div>
    </div>
  );
}

export default Header;
