import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";

import EnquiryDetails from "./Pages/enquiryDetails/EnquiryDetails.js";
import Enquiry from "./Pages/Enquiry/Enquiry";
import MykareEnquiryDetail from "./Pages/myKareEnquiryDetail/MykareEnquiryDetail";

import Settings from "./Pages/Settings/Settings";
import PartnerSettings from "./Pages/Partner/Settings/Settings";
import SidebarSettings from "./Pages/Settings/SidebarSettings";
import LotieAnimation from "./Pages/LotieAnimation/LotieAnimation";
import { BASE_PATH } from "./util/AppConstants";
import SessionExpired from "./Pages/Session/SessionExpired.js";
import ErrorPage from "./Pages/ErrorPage/ErrorPage.js";
import PrivateRoute from "./PrivateRoute.js";
import Tasks from "./Pages/Tasks/Tasks.js";
import MykareTaskDetail from "./Pages/Tasks/TaskDetails/MykareTaskDetail.js";

function AppNavigator() {
  return (
    <Router basename={BASE_PATH}>
      <div>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <PrivateRoute path="/home">
            <Home />
          </PrivateRoute>
          <Route path="/sessionexpired">
            <SessionExpired />
          </Route>

          <PrivateRoute path="/partner-settings">
            <PartnerSettings />
          </PrivateRoute>
          <PrivateRoute path="/enquiryDetails">
            <EnquiryDetails />
          </PrivateRoute>
          <PrivateRoute path="/enquiry">
            <Enquiry />
          </PrivateRoute>
          
          <PrivateRoute path="/task">
            <Tasks />
          </PrivateRoute>

          <PrivateRoute path="/lotieAnimation">
            <LotieAnimation />
          </PrivateRoute>

          <PrivateRoute path="/mykareEnquiryDetail">
            <MykareEnquiryDetail />
          </PrivateRoute>

          <PrivateRoute path="/mykareTaskDetail">
            <MykareTaskDetail />
          </PrivateRoute>

          <PrivateRoute path="/settings">
            <Settings />
          </PrivateRoute>
          <PrivateRoute path="/sidebarsettings">
            <SidebarSettings />
          </PrivateRoute>

          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default AppNavigator;
