import api from "../../axios-config";

export const getAllCountries = async () => {
  var headers = new Headers();
  headers.append("X-CSCAPI-KEY", "API_KEY");

  var requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => console.log("error", error));
};
export const getStatesByCountry = async (countryCode) => {
  var headers = new Headers();
  headers.append("X-CSCAPI-KEY", "API_KEY");

  var requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  fetch(
    `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => console.log("error", error));
};

export const getAllManagers = async () => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(`/associate/agent/manager/all`, {
    headers,
  });

  return response;
};
export const getAllAgents = async () => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(`/associate/agent/all`, {
    headers,
  });

  return response;
};
export const saveAgent = async (postData) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.post(
    `/associate/agent/save`,
    postData,
    {
      headers,
    }
  );

  return response;
};
