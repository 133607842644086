import React, { useState, useEffect } from "react";
import moment from "moment";
import { getCabDetailApi } from "../../../services/mykare/cabDetailService";
import {
  getAllAppointmentStatus,
  getAllAppointmentType,
  getAppointmentDetails,
  postAppointmentDetails,
} from "./../../../services/appointmentService/appointmentService";

function useCabDetail({ userTrxnId, cabDetailsId, callBackFn }) {
  const [errorMsg, setErrorMsg] = useState("");
  const [editableMode, setEditableMode] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [appointmentType, setAppointmentType] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);

  const [appointmentDetails, setAppointmentDetails] = useState({
    amount: "",
    appointmentType: null,
    dateTime: false,
    hcp: "",
    remarks: "",
    status: "",
    typeId: 0,
  });

  const clearDetails = () => {
    setAppointmentDetails({
      amount: null,
      appointmentId: null,
      dateTime: "",
      hcp: "",
      remarks: "",
      statusId: null,
      typeId: null,
    });
  };

  const loadAllAppointmentType = () => {
    getAllAppointmentType().then((data) => {
      setAppointmentType(data?.appointmentStatuses);
    });
  };

  const capitalize = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const filterList = (list) =>
    list.filter((value) => {
      const name = value?.name?.toLowerCase() || "";
      const valueText = value?.value?.toLowerCase() || "";
      const term = searchTerm.toLowerCase();
      return name.includes(term) || valueText.includes(term);
    });
  const formattedDate = moment(appointmentDetails?.dateTime);
  // .set({ hour: 16, minute: 21, second: 54, millisecond: 590 })
  // .toISOString();

  const saveCabDetails = async (appointmentId) => {
    const sendData = {
      amount: parseInt(appointmentDetails?.amount),
      appointmentId: appointmentId || null,
      dateTime: formattedDate,
      hcp: appointmentDetails?.hcp,
      remarks: appointmentDetails?.remarks,
      statusId: appointmentDetails?.status?.id,
      typeId: appointmentDetails?.appointmentType?.id,
      userTxnId: userTrxnId,
    };

    await postAppointmentDetails(sendData).then((data) => {
      console.log(data);
      if (data.status === 200) {
        getAppointmentDetails(24).then((res) => {
          setData(res?.appointmentDetailResponses);
        });
      }
    });
  };

  const loadAppointmentDetails = async (userTrxnId) => {
    await getAppointmentDetails(userTrxnId).then((res) => {
      setData(res?.appointmentDetailResponses);
    });
  };

  const loadAllAppointmentStatus = () => {
    getAllAppointmentStatus(appointmentDetails?.appointmentType?.id).then(
      (data) => {
        setAppointmentStatus(data?.appointmentStatusResponses);
      }
    );
  };

  useEffect(() => {
    loadAppointmentDetails(userTrxnId);
    loadAllAppointmentType();
    if (appointmentDetails?.appointmentType) {
      loadAllAppointmentStatus();
    }
  }, [appointmentDetails?.appointmentType?.id]);

  return {
    errorMsg,
    saveCabDetails,
    editableMode,
    setEditableMode,
    appointmentDetails,
    setAppointmentDetails,
    dataLoaded,
    isAdd,
    setIsAdd,
    selectedIndex,
    setSelectedIndex,
    data,
    clearDetails,
    capitalize,
    handleSearch,
    filterList,
    appointmentType,
    appointmentStatus,
  };
}

export default useCabDetail;
