import "./Home.css";
import React from "react";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";

import "../../Styles/Pagination.css";
import useHome from "./useHome.logic";
import List from "@material-ui/core/List";
import ReactPaginate from "react-paginate";

import "react-pro-sidebar/dist/css/styles.css";
import { makeStyles } from "@material-ui/core/styles";

import CustomLoader from "../../components/CustomLoader";
import PartnerTopBar from "../../components/Partner_TopBar";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkPermission } from "./../../util/permissionUtils";

const Home = () => {
  const state = useHome();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 20,
      marginTop: 10,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));
  const classes = useStyles();
  if (!(state.statsLoaded && state.dataLoaded)) {
    return <CustomLoader />;
  }

  return (
    <>
      <div style={{ display: "flex",height:"100vh " }}>
        {/* <Sidebar /> */}
        <div className={classes.root}>
          <div className="sticky">
            <div className="main-subContainer">
              {/* <PartnerTopBar /> */}
              <div className="row border1">
                <div className="col-3 cor1">
                  <b>
                    <p className="size1">{state.totalCount}</p>
                  </b>
                  <b>
                    <p className="colour">Total Query</p>
                  </b>
                </div>
                <div className="col-3 cor2">
                  <b>
                    <p className="size1">{state.pendingCount}</p>
                  </b>
                  <b>
                    <p className="colour">Pendings</p>
                  </b>
                </div>
                <div className="col-3 cor3">
                  <b>
                    <p className="size1">{state.arrivalCount}</p>
                  </b>
                  <b>
                    <p className="colour">Arrivals</p>
                  </b>
                </div>
                <div className="col-3 cor4">
                  <b>
                    <p className="size1">{state.appointmentCount}</p>
                  </b>
                  <b>
                    <p className="colour">Appointments</p>
                  </b>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />

            <div>
              {state.showReportUI ? (
                <div className="report-ui-container">
                  <div className="date-picker-container">
                    <p>From Date:</p>
                    <DatePicker
                      selected={state.fromDate}
                      onChange={(date) => state.setFromDate(date)}
                    />
                  </div>
                  <div className="date-picker-container">
                    <p>To Date:</p>
                    <DatePicker
                      selected={state.toDate}
                      onChange={(date) => state.setToDate(date)}
                    />
                  </div>
                  <button
                    className="download-button"
                    onClick={state.handleDownloadReport}
                  >
                    Download Report
                  </button>
                </div>
              ) : (
                <button
                  className="report-button"
                  onClick={() => state.setShowReportUI(true)}
                >
                  Report
                </button>
              )}
            </div>

            <div className="row scroll" style={{ minHeight: "3rem" }}>
              <div className="container col-2 m-0">
                <b>Enquiry Date</b>
              </div>
              <div className="container col-2 m-0">
                <b>Patient Details</b>
                <br />
                <input
                  name="search"
                  placeholder="Search"
                  value={state.searchItem}
                  onChange={state.handleSearchItemChange}
                />
              </div>
              {checkPermission("PARTNER_AGENT_VIEW") && (
                <div className="container col-2 m-0">
                  <b>Agent Details</b>
                </div>
              )}
              <div className="container col-2 m-0">
                <b>Type</b>
                <br />
                <select
                  value={state.selectType.selected}
                  onChange={state.handleSelectTypeChange}
                >
                  {state.selectType.list.map((item, i) => (
                    <option key={i} value={item}>
                      {item.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="container col-2 m-0">
                <b>Center Name</b>
              </div>
              <div className="container col-2 m-0">
                <b>Status</b>
                <br />
                <select
                  value={state.selectStatus.selected}
                  onChange={state.handleSelectStatusChange}
                >
                  {state.selectStatus.list.map((item, i) => (
                    <option key={i} value={item}>
                      {item.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <hr />

            <List component="nav" aria-label="mailbox folders">
              {state.homeData}
            </List>
            <ReactPaginate
              previousLabel={"PREV"}
              nextLabel={"NEXT"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              initialPage={state.offset}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={state.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
