import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader } from "../../store/DataStore";

export const getAllAppointmentType = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const { data } = await axios.get(`${APP_URL}/appointment/type/all`, {
      headers,
    });
    console.log("Appointment Types:", data);
    return data;
  } catch (error) {
    console.error("Error fetching appointment types", error);
    throw error;
  }
};

export const getAllAppointmentStatus = async (AppointmentTypeId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const { data } = await axios.get(
      `${APP_URL}/appointment/statusBy/${AppointmentTypeId}`,
      {
        headers,
      }
    );
    console.log("Appointment Types:", data);
    return data;
  } catch (error) {
    console.error("Error fetching appointment types", error);
    throw error;
  }
};

export const postAppointmentDetails = async (postData) => {
  if (!postData.typeId || !postData.dateTime || !postData.statusId) {
    alert("Please fill all details");
  } else {
    const headers = {
      Authorization: getAuthHeader(),
    };
    try {
      const response = await axios.post(
        `${APP_URL}/appointment/save`,
        postData,
        {
          headers,
        }
      );

      return response;
    } catch (error) {
      console.error("Error posting appointment data", error);
      throw error;
    }
  }
};

export const getAppointmentDetails = async (userTrxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const { data } = await axios.get(
      `${APP_URL}/appointment/details/${userTrxnId}`,
      {
        headers,
      }
    );
    console.log("Appointment Types:", data);
    return data;
  } catch (error) {
    console.error("Error posting appointment data", error);
    throw error;
  }
};
