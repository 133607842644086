import React from "react";

import "../../styles.css";
import useLogin from "./useLogin.logic";
import homeIcon from "../../assets/kareflow-home.svg";

const Login = () => {
  const state = useLogin();
  return (
    <div
      style={{
        background: "white",
        height: "100vh",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="padding" style={{ width: "400px", height: "790px" }}>
        <br />
        <br />

        <div
          className="imgcontainer"
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <img src={homeIcon} alt="Pic" style={{ width: 260 }} />

          {state.error ? (
            <div>
              <p className="col-red">Invalid Login </p>
            </div>
          ) : null}
        </div>

        <input
          style={{
            width: "100%",
            height: 50,
            marginBottom: "10px",
            borderRadius: "0px",
          }}
          type="text"
          value={state.username}
          onChange={(e) => {
            state.setUsername(e.target.value);
          }}
          id="username"
          placeholder="User Name"
        />

        <input
          style={{
            width: "100%",
            height: 50,
            marginBottom: "20px",
            borderRadius: "0px",
          }}
          type="password"
          value={state.password}
          onChange={(e) => {
            state.setPassword(e.target.value);
          }}
          placeholder="Password"
        />

        <button
          onClick={() => state.loginUser()}
          style={{
            width: "100%",
            backgroundColor: "#0a67b2",
            border: "2px solid #00215E",
            height: 50,
          }}
        >
          <h2>Login</h2>
        </button>
      </div>
    </div>
  );
};
export default Login;
