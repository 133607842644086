import React from "react";
import "./MykareEnquiry.css";
import { Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import useMykareEnquiry from "./useMykareEnquiry.logic";
import InsuranceDetail from "./InsuranceDetails/InsuranceDetail";
import AppointmentDetail from "./AppointmentDetail/AppointmentDetail";
import PatientsDoc from "./PatientDocs/PatientsDoc";
import CabDetail from "./CabDetail/CabDetail";
import PatientsDetail from "./PatientDetail/PatientsDetail";
import LoanDetails from "./EmiDetails/LoanDetails";
import { statusColorFinder } from "../../util/statusColorFinder";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { checkPermission } from "../../util/permissionUtils";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import OPiPpopup from "./Components/oPiPpopup";
import { DialogContent, Button } from "@mui/material";
import LotieAnimation from "../LotieAnimation/LotieAnimation";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import * as appConstants from "../../util/AppConstants";
import { getAuthHeader } from "../../store/DataStore.js";
import SideMenu from "../../components/SideMenu/SideMenu.js";
import Header from "../../components/Header/Header.js";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import AddAppointment from "./AppointmentDetail/AddAppointment/AddAppointment.jsx";
import MuiLoaderModal from "../../components/MuiLoader/MuiLoader.js";
import ChatPopUp from "./ChatPopUp/ChatPopUp.js";
import Drawer from "../../components/Drawer/Drawer.jsx";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const MykareEnquiryDetail = () => {
  const state = useMykareEnquiry();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginLeft: 0,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 1000px`,
      marginRight: "12px",
    },
  }));

  const classes = useStyles();
  const openInNewTab = (enquiryId, type) => {
    window.open(`/activity/${enquiryId}/${type}`, "_blank");
  };

  const alternateNum =
    state?.userResData?.alternateContact !== null
      ? state?.userResData?.alternateContact
      : "";

  const [openPdfPopup, setOpenPdfPopup] = useState(false);
  const [isGenLinkPopup, setNowGenLinkPopup] = useState(false);
  const [open, setOpen] = React.useState(false);

  const closePdfPopup = () => {
    setOpenPdfPopup(false);
  };
  const closeGenDialog = () => {
    setNowGenLinkPopup(false);
  };

  const setStarLead = () => {
    axios
      .put(
        appConstants.APP_URL + "/enquiry/star",
        {
          starLead: !state.data.starLead,
          userTxnId: state.data.enquiryId,
        },
        {
          headers,
        }
      )
      .then(({ data }) => {
        if (data.message === "Success") {
          state.getEnquiryDetails();
        }
      });
  };

  const capitalize = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const filterList = (list) =>
    list.filter((value) => {
      const name = value?.name?.toLowerCase() || "";
      const valueText = value?.value?.toLowerCase() || "";
      const term = searchTerm.toLowerCase();

      return name.includes(term) || valueText.includes(term);
    });

  return (
    <>
      {state.isMotivation ? (
        <LotieAnimation />
      ) : (
        <div style={{ display: "flex", width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "170px",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SideMenu />
          </div>
          <div
            style={{
              boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
              width: "100%",
            }}
          >
            <div
              style={{
                height: "97.5vh",
                padding: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div style={{ width: "100%", position: "sticky", height: 110 }}>
                <div
                  style={{
                    width: "100%",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <button
                      onClick={() => state.back()}
                      style={{
                        width: "80px",
                        height: 40,
                        backgroundColor: "#1892ff",
                        border: "2px solid #0a67b2",
                        padding: "0px 20px",
                      }}
                    >
                      BACK
                    </button>
                    {checkPermission("DELETE_ENQUIRY") && (
                      <button
                        onClick={() => state.deleteEnquiry()}
                        style={{
                          width: "auto",
                          height: 40,
                          backgroundColor: "red",
                          marginLeft: 10,
                          border: "2px solid #0a67b2",
                          padding: "0px 20px",
                        }}
                      >
                        Delete Enquiry
                      </button>
                    )}
                  </div>
                </div>
                <hr />
              </div>

              <div>
                <div className="row">
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      EnquiryId
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state.data.enquiryId}`}</div>
                    </p>
                  </div>

                  <div className="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Enquiry Date
                    </p>
                    <div className="ed-txt-1 text-start">
                      <div className="textColor">
                        {state.data?.enquiryDate?.toString().split("T")[0]}
                      </div>
                      <div className="textColor">
                        {moment(state?.data?.enquiryDate).format("LTS")}
                      </div>
                    </div>
                  </div>

                  {state.data.enquiryDate &&
                    checkPermission("LEADS_ENQUIRY_DATE_FIELD") && (
                      <div className="col-md-1">
                        <p className="text-start" style={{ fontWeight: "700" }}>
                          Enquiry Date
                        </p>
                        <div className="ed-txt-1 text-start">
                          <div className="textColor">
                            {checkPermission("TRANSFERRED_DATE_VIEW")
                              ? state?.data?.createdDate
                                  ?.toString()
                                  .split("T")[0]
                              : state.data?.enquiryDate
                                  ?.toString()
                                  .split("T")[0]}
                          </div>
                          <div className="textColor">
                            {checkPermission("TRANSFERRED_DATE_VIEW")
                              ? moment(state?.data?.createdDate).format("LTS")
                              : moment(state?.data?.enquiryDate).format("LTS")}
                          </div>
                        </div>
                      </div>
                    )}
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      User Name
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{state?.data?.userName}</div>
                    </p>
                  </div>
                  {state?.referralName && (
                    <div class="col-md-1">
                      <p className="text-start">ReferralName</p>
                      <p className="ed-txt-1 text-start">
                        <div className="textColor">{state.referralName}</div>
                      </p>
                    </div>
                  )}
                  <div class="col-md-1">
                    <p className="text-start " style={{ fontWeight: "700" }}>
                      Age
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">
                        {state.age ? state.age : "Null"}
                      </div>
                    </p>
                  </div>
                  <div class="col-md-1">
                    <p className="text-start " style={{ fontWeight: "700" }}>
                      Gender
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">
                        {state.gender ? state.gender : "Null"}
                      </div>
                    </p>
                  </div>

                  <div class="col-md-1">
                    <p className="text-start " style={{ fontWeight: "700" }}>
                      Phone Number
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">
                        <p>
                          {`${
                            state.data.phoneNumber
                          }${" "}/${" "}${alternateNum}`}
                        </p>
                      </div>
                    </p>
                  </div>

                  <div
                    class="col-md-1 "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontWeight: "800" }}>Mark as star lead</p>
                    <div onClick={setStarLead}>
                      {state.data.starLead ? (
                        <StarIcon
                          style={{
                            cursor: "pointer",
                            color: "#ffd250",
                            fontSize: "30px",
                          }}
                        />
                      ) : (
                        <StarBorderIcon
                          style={{
                            cursor: "pointer",
                            color: "#ffcda5",
                            fontSize: "30px",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {/* <div class="col-md-1">
                    <p className="text-start " style={{ fontWeight: "800" }}>
                      Type
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{` ${state.data.type}`}</div>
                    </p>
                  </div> */}

                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Source
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state.data.source}`}</div>
                    </p>
                  </div>

                  <div className="col-md-1 capitalize">
                    <p className="text-start " style={{ fontWeight: "700" }}>
                      Status
                    </p>
                    <p
                      className={statusColorFinder(state.status)}
                      style={{ textAlign: "left" }}
                    >
                      {state.status ? state.status : ""}
                    </p>
                  </div>
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Email
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state?.data?.email}`}</div>
                    </p>
                  </div>
                  <hr />
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Assigned To
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state.data.assignToName}`}</div>
                    </p>
                  </div>
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Location
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state.data.location}`}</div>
                    </p>
                  </div>
                  {state?.data?.subLocationName && (
                    <div class="col-md-1">
                      <p className="text-start" style={{ fontWeight: "700" }}>
                        Sub Location
                      </p>
                      <p className="ed-txt-1 text-start">
                        <div className="textColor">{`${state.data.subLocationName}`}</div>
                      </p>
                    </div>
                  )}
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Services
                    </p>
                    <p className="ed-txt-1 text-start">
                      <div className="textColor">{`${state.data.packages}`}</div>
                    </p>
                    {/* <OtpVerified
                      imageSrc={state?.data?.packages ? packages : null}
                      message="OTP Verified!"
                    /> */}
                  </div>
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Marital status
                    </p>
                    <p className="text-start ed-txt-1">
                      {state?.userResData?.maritalStatus || ""}
                    </p>
                  </div>
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Occupation
                    </p>
                    <p className="text-start ed-txt-1">
                      {state?.userResData?.occupation?.name || ""}
                    </p>
                  </div>
                  {/* <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "800" }}>
                      Location
                    </p>
                    <p className="text-start ed-txt-1">
                      {state?.userResData?.city || ""}
                    </p>
                  </div> */}
                  <div class="col-md-1">
                    <p className="text-start" style={{ fontWeight: "700" }}>
                      Languages
                    </p>
                    {state?.userResData?.languages?.length > 0 ? (
                      <div className="row flex">
                        {state?.userResData?.languages?.map((lag, index) => (
                          <p
                            key={index}
                            className="text-start ed-txt-1 col p-0 m-0"
                            style={{ paddingTop: "10px" }}
                          >
                            {lag?.name}
                          </p>
                        ))}
                      </div>
                    ) : (
                      <p className="text-start p-0 mt-2 ed-txt-1"></p>
                    )}
                  </div>

                  {state?.data?.relationName && (
                    <div class="col-md-1">
                      <p className="text-start" style={{ fontWeight: "700" }}>
                        Relationship
                      </p>
                      <p className="text-start ed-txt-1">
                        {state?.data?.relationName}
                      </p>
                    </div>
                  )}

                  {state?.data?.enquiryName && (
                    <div class="col-md-1">
                      <p className="text-start" style={{ fontWeight: "700" }}>
                        Patient Name
                      </p>
                      <p className="text-start ed-txt-1">
                        {state?.data?.enquiryName}
                      </p>
                    </div>
                  )}

                  {checkPermission("CALL_RECORDING_VIEW") && (
                    <div class="col-md-2">
                      <button
                        style={{
                          textDecoration: "none",
                          border: "2px solid #0a67b2",
                          width: "160px",
                          height: "40px",
                          cursor: "pointer",
                          backgroundColor: "#1892ff",
                          color: "white",
                          gap: "10px",
                        }}
                        onClick={() => setOpen(true)}
                      >
                        <RecordVoiceOverIcon sx={{ color: "red" }} />
                        <span> Call Recordings</span>
                      </button>
                    </div>
                  )}
                  <div class="col-md-1">
                    <Drawer
                      open={open}
                      setOpen={setOpen}
                      userTxnId={state?.data?.enquiryId}
                    />
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-1"></div>
                  <div class="col-md-1"></div>
                  {state?.url && (
                    <div class="col-md-1">
                      <p className="text-start" style={{ fontWeight: "700" }}>
                        Url
                      </p>
                      <p className="ed-txt-1 text-start">
                        <div className="textColor">{state.url}</div>
                      </p>
                    </div>
                  )}
                  {state?.ctaLabel && (
                    <div class="col-md-4">
                      <p className="text-start" style={{ fontWeight: "700" }}>
                        CTA Label
                      </p>
                      <p className="ed-txt-1 text-start">
                        <div className="textColor">{state.ctaLabel}</div>
                      </p>
                    </div>
                  )}

                  <>
                    {state?.data?.adId !== null && (
                      <div class="col-4">
                        <button
                          style={{ width: 140, padding: "5px" }}
                          onClick={state?.loadAdDetails}
                        >
                          Ad Details
                        </button>
                      </div>
                    )}
                  </>

                  <>
                    {state?.data?.patientCareType && (
                      <div class="col-md-2">
                        <button
                          style={{ width: 200, height: 60 }}
                          onClick={() =>
                            openInNewTab(
                              state.data.enquiryId,
                              state?.data?.patientCareType
                            )
                          }
                        >
                          View Karebuddy Activity
                        </button>
                      </div>
                    )}
                  </>
                </div>
                <hr />

                {/* {state.surgeryDetail && (
                  <div className="row">
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Treatment Name</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.handleTreatmentName(state.surgeryDetail)}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Category</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.surgeryDetail.category
                            ? state.surgeryDetail.category
                            : state.surgeryDetail.otherCategory}
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Location</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.surgeryDetail.location}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Sub Location</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.surgeryDetail.subLocation}
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* {state.enquiryDetail && (
                  <div className="row">
                   

                    {state.enquiryDetail.circle && (
                      <div className="col-3">
                        <p className="mb-0 mt-4 text-start">Circle</p>
                        <div className="ed-txt-2 mb-4 text-start">
                          <div className="textColor">
                            {state.enquiryDetail.circle}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-1">
                      <p
                        className="mb-0 mt-4 text-start"
                        style={{ fontWeight: "800" }}
                      >
                        Center
                      </p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.enquiryDetail.center
                            ? state.enquiryDetail.center
                            : "No Data"}
                        </div>
                      </div>
                    </div>
                    <AdDetails
                      isAdPopUp={state?.isAdPopUp}
                      setIsAdPopUp={state?.setIsAdPopUp}
                      adDetails={state?.adDetails}
                    />

                    {state.enquiryDetail.additionalInfo && (
                      <div className="col-3">
                        <p
                          className="mb-0 mt-4 text-start"
                          style={{ fontWeight: "800" }}
                        >
                          Additional Info
                        </p>
                        <div className="ed-txt-2 mb-4 text-start">
                          <div className="textColor">
                            {getAdditionalInfo(
                              state.enquiryDetail.additionalInfo
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )} */}
                {/* {state.smoDetails && (
                  <div>
                    <div className="row">
                      <div className="col-3">
                        <p className="mb-0 mt-4 text-start">Reason</p>
                        <div className="ed-txt-2 mb-4 text-start">
                          <div className="textColor">
                            {state.smoDetails.reason}
                          </div>
                        </div>
                      </div>

                      <div className="col-3">
                        <p className="mb-0 mt-4 text-start">Description</p>
                        <div className="ed-txt-2 mb-4 text-start">
                          <div className="textColor">
                            {state.smoDetails.description}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                )} */}
                {/* {state.preventiveCareDetail && (
                  <div className="row">
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Packages</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.preventiveCareDetail.packages}
                        </div>
                      </div>
                    </div>

                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Location</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.preventiveCareDetail.location}
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <p className="mb-0 mt-4 text-start">Sub Location</p>
                      <div className="ed-txt-2 mb-4 text-start">
                        <div className="textColor">
                          {state.preventiveCareDetail.subLocation}
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* <div style={{width:"200px",height:"100px",backgroundColor:"red"}}> */}

                {/* </div> */}

                <div className="row" style={{ gap: "50px" }}>
                  {/* {checkPermission("ENQUIRY_UPDATE_SUB_TAB") && ( */}
                  <div className="col-lg-1 col-1">
                    <button
                      className={
                        state.selectedTab.isOpen
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(1)}
                    >
                      Enquiry Update
                    </button>
                  </div>
                  {/* )} */}

                  {/* {checkPermission("PATIENT_DOCS_SUB_TAB") && ( */}
                  <div className="col-lg-1  col-1">
                    <button
                      className={
                        state.selectedTab.isPatientsDocs
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(6)}
                    >
                      Patient Docs
                    </button>
                  </div>
                  {/* )} */}
                  {/* {checkPermission("PATIENT_DETAIL_TAB") && ( */}
                  <div className="col-lg-1  col-1">
                    <button
                      className={
                        state.selectedTab.isPatientsDetail
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(7)}
                    >
                      Patient Detail
                    </button>
                  </div>
                  {/* )} */}

                  <div className="col-lg-1  col-1">
                    <button
                      className={
                        state.selectedTab.isAddAppointment
                          ? "active-class"
                          : "disabled-class"
                      }
                      style={{ padding: 10, width: 180 }}
                      onClick={() => state.handleHideFun(4)}
                    >
                      Appointments
                    </button>
                  </div>
                </div>

                <hr />
                {state.selectedTab.isOpen ? (
                  <div>
                    <div>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Col xl={2}>
                          <h5>Status</h5>
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                style={{
                                  width: 200,
                                  height: 36,
                                  border: `1px solid gray`,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {state.status ? state.status : "Status"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                style={{
                                  maxHeight: 210,
                                  overflowY: "scroll",
                                  overflowX: "hidden",
                                  width: 200,
                                  padding: "5px",
                                  borderRadius: "0px",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Search"
                                  onChange={handleSearch}
                                  style={{
                                    width: "100%",
                                    padding: "5px",
                                    boxSizing: "border-box",
                                    marginBottom: "5px",
                                    borderRadius: "0px",
                                    border: "1px solid gray",
                                  }}
                                />
                                {filterList(state.statusList).map(
                                  (value, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        href="#"
                                        style={{
                                          width: 200,
                                          maxHeight: 210,
                                          borderRadius: "0px",
                                        }}
                                        onClick={() => {
                                          state.handleStatus(
                                            value.name,
                                            value.id
                                          );
                                          state.getLostReason(value.id);
                                          state.loadPatientDetails();
                                          setSearchTerm("");
                                        }}
                                      >
                                        {capitalize(value.name)}
                                      </Dropdown.Item>
                                    );
                                  }
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>

                        {state.showEnquiryActionFields() && (
                          <Col xl={2}>
                            <h5
                              style={{
                                margin: "0px",
                                padding: "0px",
                                paddingBottom: "5px",
                              }}
                            >
                              Follow Up
                            </h5>
                            <DatePicker
                              className="myDatePicker "
                              selected={
                                state.followUpDate ? state.followUpDate : null
                              }
                              filterTime={state.filterPassedTime}
                              onChange={(date) => state.setFollowUpDate(date)}
                              showTimeSelect
                              dateFormat="MMMM d, yyyy h:mm aa"
                              // onChange={(date) => state.setFollowUpDate(date)}
                              minDate={new Date()}
                            />
                          </Col>
                        )}
                        {checkPermission("LEADS_ASSIGNED_FIELD") && (
                          <Col xl={2}>
                            <h5>Assign To</h5>
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 200,
                                    height: 36,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {state.assignedAgent || "Assign"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  // as={CustomMenu}
                                  style={{
                                    maxHeight: 210,
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: 200,
                                    padding: "5px",
                                    borderRadius: "0px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={handleSearch}
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      boxSizing: "border-box",
                                      marginBottom: "5px",
                                      borderRadius: "0px",
                                      border: "1px solid gray",
                                    }}
                                  />
                                  {filterList(state.userData).map(
                                    (value, id) => {
                                      return (
                                        <Dropdown.Item
                                          key={id}
                                          href="#"
                                          style={{
                                            width: 200,
                                            maxHeight: 200,
                                          }}
                                          onClick={() => {
                                            state.handleToAssigned(
                                              value?.name,
                                              value?.code
                                            );
                                            setSearchTerm("");
                                          }}
                                        >
                                          {capitalize(value.name)}
                                        </Dropdown.Item>
                                      );
                                    }
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col>
                        )}

                        {/* ) : null} */}
                        <>
                          {/* {(state?.data?.type == "Enquiry" ||
                            state?.data?.type == "Other Leads") &&
                            checkPermission("ENQUIRY_UPDATE_TREATMENT") && ( */}
                          <Col xl={2}>
                            <h5>Services</h5>
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 200,
                                    height: 36,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {!!state.selectedTreatment
                                    ? state.selectedTreatment.name
                                    : state.data.packages}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{
                                    maxHeight: 210,
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: 200,
                                    padding: "5px",
                                    borderRadius: "0px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={handleSearch}
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      boxSizing: "border-box",
                                      marginBottom: "5px",
                                      borderRadius: "0px",
                                      border: "1px solid gray",
                                    }}
                                  />
                                  {filterList(state.treatments).map(
                                    (value, index) => {
                                      return (
                                        <Dropdown.Item
                                          key={index}
                                          href="#"
                                          style={{
                                            width: 200,
                                            maxHeight: 200,
                                          }}
                                          onClick={() => {
                                            state.setselectedTreatment(value);
                                            setSearchTerm("");
                                          }}
                                        >
                                          {capitalize(value.name)}
                                        </Dropdown.Item>
                                      );
                                    }
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col>
                          {/* )} */}
                          {/* {(state?.data?.type == "Enquiry" ||
                            state?.data?.type == "Other Leads") &&
                            checkPermission("ENQUIRY_UPDATE_LOCATION") && ( */}
                          <Col xl={2}>
                            <h5>Location</h5>
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 200,
                                    height: 36,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {!!state.selectedLocation
                                    ? state.selectedLocation.name
                                    : state.data.location}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  style={{
                                    maxHeight: 210,
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: 200,
                                    padding: "5px",
                                    borderRadius: "0px",
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={handleSearch}
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      boxSizing: "border-box",
                                      marginBottom: "5px",
                                      borderRadius: "0px",
                                      border: "1px solid gray",
                                    }}
                                  />
                                  {filterList(state.locations).map(
                                    (value, index) => {
                                      return (
                                        <Dropdown.Item
                                          key={index}
                                          href="#"
                                          style={{
                                            width: 200,
                                            maxHeight: 200,
                                          }}
                                          onClick={() => {
                                            state.setSelectedLocation(value);
                                            state.setSelectedLocation(value);
                                            setSearchTerm("");
                                          }}
                                        >
                                          {capitalize(value.name)}
                                        </Dropdown.Item>
                                      );
                                    }
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col>
                          {/* )} */}
                          {/* <Col xl={2}>
                            <h5>Payment Type</h5>
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 200,
                                    height: 36,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  {!!state.selectedPaymentType
                                    ? state.selectedPaymentType
                                    : "Payment Type"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {state.ipPaymentModes.map((value, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        href="#"
                                        style={{ width: 200 }}
                                        onClick={() =>
                                          state.setSelectedPaymentType(value)
                                        }
                                      >
                                        {value}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col> */}

                          {state.showIntentFields() && (
                            <Col xl={2}>
                              <h5>Intent</h5>
                              <div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    style={{
                                      width: 200,
                                      height: 36,
                                      border: `1px solid gray`,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {!!state.selectedIntent
                                      ? state.selectedIntent
                                      : "Intent"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {state.intentModes.map((value, index) => {
                                      return (
                                        <Dropdown.Item
                                          key={index}
                                          href="#"
                                          style={{ width: 200 }}
                                          onClick={() =>
                                            state.setSelectedIntent(value)
                                          }
                                        >
                                          {value}
                                        </Dropdown.Item>
                                      );
                                    })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </Col>
                          )}
                        </>
                        <Col xl={2}>
                          {state.statusId === 21 ? (
                            <div style={{ marginTop: 10 }}>
                              <p
                                style={{
                                  textAlign: "left",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}
                              >
                                Sub Status
                              </p>

                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 270,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                  // disabled={
                                  //   checkPermission("OPD_DONE_EDIT")
                                  //     ? false
                                  //     : true
                                  // }
                                >
                                  {state.subStatusId
                                    ? state.subStatusId.name
                                    : "Select Reasons"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {state.subStatus.map((value, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        href="#"
                                        style={{ width: 270, maxHeight: 200 }}
                                        onClick={() =>
                                          state.handleSubStatus(value)
                                        }
                                      >
                                        {value.name}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          ) : null}
                        </Col>
                        {/* <Col xl={2}>
                          {state.statusId === 28 || state.statusId === 30 ? (
                            <div style={{ marginTop: 10 }}>
                              <p
                                style={{
                                  textAlign: "left",
                                  fontWeight: 600,
                                  marginBottom: 0,
                                }}
                              >
                                {state.status} Reason
                              </p>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="none"
                                  style={{
                                    width: 270,
                                    border: `1px solid gray`,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                  {state.selectedReasons
                                    ? state.selectedReasons.name
                                    : "Select Reasons"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {state.reasons.map((value, index) => {
                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        href="#"
                                        style={{ width: 270, maxHeight: 200 }}
                                        onClick={() =>
                                          state.setSelectedReasons(value)
                                        }
                                      >
                                        {value.name}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          ) : null}
                        </Col> */}
                      </Row>
                    </div>
                    <div className="marginleft ">
                      <div>
                        <div className="row border-top">
                          <p
                            style={{
                              textAlign: "left",
                              fontWeight: 600,
                              margin: 0,
                              marginTop: "30px",
                            }}
                          >
                            Add Your Comments
                          </p>

                          <div
                            className="col-lg-3 "
                            style={{ marginTop: "25px" }}
                          >
                            <div
                              style={{
                                height: "0px",

                                width: "100%",
                              }}
                            >
                              {state?.selectedComments.length > 0 && (
                                <div
                                  style={{
                                    height: "auto",
                                    display: "flex",
                                    width: "100%",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                    overflow: "scroll",
                                    marginTop: "20px",
                                  }}
                                >
                                  {state?.selectedComments.map((data, id) => {
                                    return (
                                      <div>
                                        <span
                                          style={{
                                            margin: "0px",
                                            padding: "7px 20px",
                                            backgroundColor: "#F5F7F8",
                                            borderRadius: "50px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {data}
                                          <span
                                            style={{
                                              width: 25,
                                              height: 25,
                                              cursor: "pointer",
                                              borderRadius: "50px",
                                              marginLeft: "10px",
                                              marginRight: "-10px",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                            onClick={() =>
                                              state?.handleDelete(id)
                                            }
                                          >
                                            <HighlightOffIcon />
                                          </span>
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}

                              <div
                                style={{
                                  width: "100%",
                                  height: "40px",
                                }}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="none"
                                    style={{
                                      width: "100%",
                                      height: "60px",
                                      border: `1px solid gray`,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    {"Add Comments"}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    style={{
                                      maxHeight: 210,
                                      overflowY: "scroll",
                                      overflowX: "hidden",
                                      width: 408,
                                      padding: "5px",
                                      borderRadius: "0px",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      onChange={handleSearch}
                                      style={{
                                        width: "100%",
                                        padding: "5px",
                                        boxSizing: "border-box",
                                        marginBottom: "5px",
                                        borderRadius: "0px",
                                        border: "1px solid gray",
                                      }}
                                    />
                                    {filterList(state.allComments).map(
                                      (value, id) => {
                                        return (
                                          <Dropdown.Item
                                            key={id}
                                            href="#"
                                            style={{
                                              width: 390,
                                              overflow: "hidden",
                                            }}
                                            onClick={() => {
                                              state.handleComments(
                                                value.value,
                                                value.id
                                              );
                                              setSearchTerm("");
                                            }}
                                          >
                                            {value.value}
                                          </Dropdown.Item>
                                        );
                                      }
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-lg-3"
                            style={{ marginTop: "-20px" }}
                          >
                            <p
                              style={{
                                textAlign: "left",
                                fontWeight: 600,
                                margin: 0,
                                marginBottom: "30px",
                              }}
                            >
                              Add Your Notes
                            </p>
                            <textarea
                              style={{
                                width: "100%",
                                height: 100,
                                borderRadius: 5,
                                paddingLeft: 10,
                                border: `1px solid gray`,
                              }}
                              type="text"
                              value={state?.selectedNotes}
                              placeholder="Notes..."
                              onChange={(e) =>
                                state?.setSelectedNotes(e?.target?.value)
                              }
                            />
                          </div>

                          {/* {checkPermission("ENQUIRY_UPDATE_BTN") && ( */}
                          <div class="col-1">
                            <div>
                              <button
                                style={state.buttonStyle}
                                onClick={() => {
                                  state.postStatus();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          {/* )} */}
                          <div className="col-lg-5 col-12">
                            {state.selectedTab.isOpen ? (
                              <>
                                {" "}
                                {state.status !== "New Lead" && (
                                  <div
                                    style={{
                                      width: "100%",
                                      height: 360,
                                      backgroundColor: "white",
                                      overflowX: "hidden",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {state.usersHistory
                                      ? state.usersHistory.map(
                                          (item, index) => {
                                            return (
                                              <div
                                                style={{
                                                  backgroundColor: "#EFEFEF",
                                                  padding: 30,
                                                  borderRadius: 10,
                                                  marginTop: 30,
                                                  marginRight: 10,
                                                }}
                                              >
                                                <div
                                                  style={{ display: "flex" }}
                                                >
                                                  <h6
                                                    style={{ color: "#242F9B" }}
                                                  >
                                                    {item.addedBy}
                                                  </h6>
                                                  <p
                                                    style={{
                                                      marginLeft: 10,
                                                      marginBottom: 6,
                                                    }}
                                                  >
                                                    Added a comment on
                                                  </p>
                                                  <p
                                                    style={{
                                                      marginLeft: 10,
                                                      marginBottom: 6,
                                                      color: "#242F9B",
                                                    }}
                                                  >
                                                    {
                                                      moment(item.addedDate)
                                                        .format(
                                                          "YYYY-MM-DD HH:mm:ss"
                                                        )
                                                        .split("+")[0]
                                                    }
                                                  </p>
                                                </div>
                                                <span>{item.comments} </span>
                                                <br></br>
                                                <br></br>
                                                {item?.oldStatus &&
                                                  item?.newStatus && (
                                                    <>
                                                      <span
                                                        style={{
                                                          marginLeft: 0,
                                                          marginBottom: 6,
                                                        }}
                                                      >
                                                        Status changed from
                                                      </span>
                                                      <span
                                                        style={{
                                                          color: "#242F9B",
                                                        }}
                                                      >
                                                        {item.oldStatus &&
                                                          item.newStatus &&
                                                          ` ${item.oldStatus} to ${item.newStatus} `}
                                                      </span>
                                                    </>
                                                  )}

                                                <br></br>
                                                <br></br>
                                                {item.followupDate && (
                                                  <>
                                                    <span
                                                      style={{
                                                        marginLeft: 0,
                                                        marginBottom: 6,
                                                      }}
                                                    >
                                                      Updated followup date to
                                                    </span>
                                                    <span
                                                      style={{
                                                        color: "#242F9B",
                                                      }}
                                                    >
                                                      {` ${item.followupDate}`}
                                                    </span>
                                                  </>
                                                )}
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                )}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        height: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ color: "green" }}>{state.message}</p>
                    </div>
                  </div>
                ) : null}

                <MuiLoaderModal
                  handleOpen={state?.handleOpen}
                  handleClose={state?.handleCloseModal}
                  open={state?.isShow}
                />

                {state.selectedTab.isAddAppointment ? (
                  <AddAppointment
                    userTrxnId={state.data.enquiryId}
                    handleOpen={state?.handleOpen}
                    handleClose={state?.handleCloseModal}
                  />
                ) : null}

                {state.selectedTab.isPatientsDocs ? (
                  <PatientsDoc userTrxnId={state.data.enquiryId} />
                ) : null}
                {state.selectedTab.isAppointment ? (
                  <AppointmentDetail
                    userTrxnId={state.data.enquiryId}
                    detailId={state.appointmentDetailId}
                    callBackFn={state.setAppointmentDetailId}
                    centerData={state.centerData}
                    locationData={state.locations}
                    doctorData={state.doctorData}
                    handleHide={state.handleHideFun}
                    patientId={state.data.patientId}
                    insuranceDetailId={state.insuranceId}
                    patientAadhar={state.aadhaarFiles}
                    patientPrescription={state.prescriptionFiles}
                    treatment={
                      state.enquiryDetail &&
                      state.handleTreatmentName(state.enquiryDetail)
                    }
                  />
                ) : null}
                {state.activeCheckBoxTwo ? (
                  <div>
                    <h1>tutyiyuiyu</h1>
                  </div>
                ) : null}
                {state.selectedTab.isInsurance ? (
                  <InsuranceDetail
                    userTrxnId={state.data.enquiryId}
                    insuranceDetailId={state.insuranceId}
                    callBackFn={state.setInsuranceId}
                    handleHide={state.handleHideFun}
                  />
                ) : null}
                {state.selectedTab.isEmi ? (
                  <LoanDetails
                    userTxnId={state.data.enquiryId}
                    loanDetailsId={state.loanDetailId}
                    callBackFn={state.setLoanDetailId}
                  />
                ) : null}
                {state.selectedTab.isPatientsDetail ? (
                  <PatientsDetail
                    patientId={state.data.userId}
                    refreshFn={state.refreshPersonalDetails}
                    handleHide={state.handleHideFun}
                    patientTxnId={state.data.enquiryId}
                    patientData={state}
                    newData={state?.isValid}
                  />
                ) : null}
              </div>
            </div>

            <div>
              <Dialog
                open={state?.open}
                onClose={state?.handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth={state.popUpType ? "xs" : "md"}
              >
                {state?.popUpType ? (
                  <>
                    <DialogTitle>
                      <b>Merge Enquiry Data</b>
                    </DialogTitle>
                    <DialogContent>
                      <input
                        style={{ height: "30px" }}
                        type="text"
                        placeholder="Enter original enquiry id"
                        onChange={(e) => {
                          state.setOriginalUserTxnId(e.target.value);
                        }}
                        value={state.originalUserTxnId}
                      ></input>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={state?.handleClose} variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          state.mergeLead();
                        }}
                        variant="contained"
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </>
                ) : (
                  !state.popUpType &&
                  state.open && (
                    <>
                      <DialogTitle>Confirm the date and time</DialogTitle>
                      <DialogContent>
                        {state.statusId == 19 || state.statusId == 18 ? (
                          <OPiPpopup
                            label={"Arrival Date Time"}
                            statusId={state.statusId}
                            date={
                              state.appointmentDetails?.ipDetails
                                ?.arrivalDateTime
                            }
                            data={state.appointmentDetails}
                            setFollowUpDate={state.setAppointmentDetails}
                          />
                        ) : (
                          <OPiPpopup
                            label={"Appointment Date Time"}
                            statusId={state.statusId}
                            data={state.appointmentDetails}
                            date={
                              state?.appointmentDetails?.opDetails?.dateTime
                            }
                            setFollowUpDate={state.setAppointmentDetails}
                          />
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={state?.handleClose} variant="outlined">
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            state.saveAppointmentDetails();
                          }}
                          variant="contained"
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </>
                  )
                )}
              </Dialog>
            </div>
          </div>

          <ChatPopUp />
        </div>
      )}
    </>
  );
};
export default MykareEnquiryDetail;

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Search..."
          onChange={(e) => setValue(e?.target?.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children?.toArray(children)?.filter(
            (child) =>
              !value || child?.props?.children?.toLowerCase()?.startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
