import React, { useState, useRef, useEffect } from "react";
import "./Roles.css";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getAllPermissions,
  getAllRole,
  getDisabed,
  getSpecificRole,
} from "../../../services/mykare/dataService";
import {
  postAddRole,
  postAddPermission,
  postEnabled,
} from "../../../services/mykare/dataService";
import useSettings from "../useSettings.logic";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Box from "@mui/material/Box";
import { CustomMenu } from "../../../components/AddLead/AddLead";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: "white",
    color: "#0a67b2",
  },
  list: {
    width: 300,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
    backgroundColor: "#0a67b2",
    color: "red",
  },
  saveButton: {
    backgroundColor: "#0a67b2",
    padding: "10px 45px",
    color: "white",
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function union(a, b) {
  return [...a, ...not(b, a)];
}

function SaveButton(props) {
  return (
    <button className="save_button" onClick={props.onClick}>
      Save
    </button>
  );
}

const Roles = ({ state }) => {
  const data = {
    disabled: [],
    enabled: [],
  };
  const [showModel, setShowModel] = useState(true);
  const [modelPermission, setModelPermission] = useState(false);
  const [iaAssosiate, setIsAssosiate] = useState(false);
  const [defaultPermissionData, setDefaultPermissionData] = useState({
    list: [{ id: 0, name: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [roleData, setRoleData] = useState({
    list: [{ id: 0, name: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [defaultrRoleData, setDefaultRoleData] = useState({
    list: [{ id: 0, name: "select" }],
    selected: null,
    selectedCode: null,
  });
  const [test, setTest] = useState([
    { id: 0, name: "select" },
    { id: 2, name: "select2" },
  ]);
  const [permissionData, setPermissionData] = useState([]);
  const [saveRoleData, setSaveRoleData] = useState({
    name: "",
    prefix: "",
  });
  const [roleIsSave, setRoleIsSave] = useState(true);
  const [disabledData, setDisabledData] = useState("");
  const [permissionName, setPermissionName] = useState("");
  const [permissionLabel, setPermissionLabel] = useState();
  const [permissionsearch, setPermissionSearch] = useState();
  const [roleId, setRoleId] = useState(null);

  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [filteredLeft, setFilteredLeft] = React.useState([]);

  const [right, setRight] = React.useState([]);
  const [rightSearch, setRightSearch] = React.useState("");
  const [leftSearch, setLeftSearch] = React.useState("");

  const [filteredRight, setFilteredRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleRolesToChange = async (assignedId, assignedName) => {
    setRoleData((s) => ({
      ...s,
      selected: assignedName,
      selectedCode: assignedId,
    }));
    setDisabledData(assignedName);
    setRoleId(assignedId);
    const findRole = await getSpecificRole(assignedId);
    setFilteredLeft(findRole?.data?.disabledPermissions);
    setFilteredRight(findRole?.data?.enabledPermissions);
    setLeft(findRole?.data?.disabledPermissions);
    setRight(findRole?.data?.enabledPermissions);
    //  setFilteredRight(findRole?.data?.enabledPermissions);
  };
  const loadAllRole = async () => {
    try {
      getAllRole().then((res) => {
        setRoleData((s) => ({ ...s, list: res.data }));
        setDefaultRoleData((s) => ({ ...s, list: res.data }));
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  const loadAllPermissions = async () => {
    getAllPermissions()
      .then((res) => {
        setPermissionData(res.data);
        setDefaultPermissionData(res.data);
      })
      .catch((e) => {
        console.log("errr====", e);
      });
  };

  const savePermission = async (permissionName, permissionLabel) => {
    if (permissionName && permissionLabel) {
      const postData = {
        name: permissionName,
        label: permissionLabel,
      };
      setRoleIsSave(false);
      try {
        const response = await postAddPermission(postData);
        if (response.status === 200) {
          setRoleIsSave(true);
          loadAllPermissions();
          alert("Successfully Saved");
          setPermissionLabel("");
          setPermissionName("");
        } else {
          setRoleIsSave(true);
          alert("Please fill all the Fields");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const saveRole = async (addRole) => {
    if (addRole) {
      const postData = {
        name: addRole.name,
        prefix: addRole.prefix,
      };
      setRoleIsSave(false);
      try {
        const response = await postAddRole(postData);
        if (response.status === 200) {
          setRoleIsSave(true);
          loadAllRole();
          alert("Successfully Saved");
          setSaveRoleData({
            name: "",
            prefix: "",
          });
        } else {
          setRoleIsSave(true);
          alert("Please fill all the Fields");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const saveEnabled = async () => {
    const ids = right.map((val) => val.id);
    const postData = {
      id: roleId,
      permissionIds: ids,
    };
    await postEnabled(postData).then((res) => {
      if (res.status == 200) {
        alert("Permissions Enabled sucessfully");
      }
    });
  };

  useEffect(() => {
    loadAllRole();
  }, []);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;
  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setFilteredRight(filteredRight.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setFilteredLeft(not(filteredLeft, leftChecked));
    setChecked(not(checked, leftChecked));
    setLeftSearch("");
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setFilteredLeft(filteredLeft.concat(rightChecked));
    setRight(not(right, rightChecked));
    setFilteredRight(not(filteredRight, rightChecked));
    setChecked(not(checked, rightChecked));
    setRightSearch("");
  };

  const filterLeft = (value) => {
    if (value === "" || value === null) {
      setFilteredLeft(left);
    } else {
      const filteredData = filteredLeft.filter((filteredValue) => {
        let valueLowercase = filteredValue.name?.toUpperCase();
        let searchTermLowercase = value?.toUpperCase();
        return valueLowercase.indexOf(searchTermLowercase) > -1;
      });
      if (filteredData.length !== 0) {
        setFilteredLeft(filteredData);
      } else {
        alert("Sorry no data found");
        setFilteredLeft(filteredLeft);
      }
    }
  };
  const filterRight = (value) => {
    if (value === "" || value === null) {
      setFilteredRight(right);
    } else {
      const filteredData = filteredRight.filter((filteredValue) => {
        let valueLowercase = filteredValue.name?.toUpperCase();
        let searchTermLowercase = value?.toUpperCase();
        return valueLowercase.indexOf(searchTermLowercase) > -1;
      });
      if (filteredData.length !== 0) {
        setFilteredRight(filteredData);
      } else {
        alert("Sorry no data found");
        setFilteredRight(filteredRight);
      }
    }
  };
  // const filterRight = (value) => {
  //   console.log("=======defaultRight======", defaultRight);
  //   if (value === "" || value === null) {
  //     setRight(defaultRight);
  //   } else {
  //     const filteredData = defaultRight.filter((filteredValue) => {
  //       let valueLowercase = filteredValue.name?.toUpperCase();
  //       let searchTermLowercase = value?.toUpperCase();
  //       return valueLowercase.indexOf(searchTermLowercase) > -1;
  //     });
  //     if (filteredData.length !== 0) {
  //       setRight(filteredData);
  //     } else {
  //       alert("Sorry no data found");
  //       setRight(defaultRight);
  //     }
  //   }
  // };
  const filterPermission = (value) => {
    setPermissionSearch(value);
    if (value === "" || value === null) {
      loadAllPermissions();
    } else {
      const filteredData = defaultPermissionData.filter((filteredValue) => {
        let valueLowercase = filteredValue.name?.toUpperCase();
        let searchTermLowercase = value?.toUpperCase();
        return valueLowercase.indexOf(searchTermLowercase) > -1;
      });
      if (filteredData.length !== 0) {
        setPermissionData(filteredData);
      } else {
        alert("Sorry no data found");
        loadAllPermissions();
      }
    }
  };

  const searchByValue = (value) => {
    if (value === "" || value === null) {
      loadAllRole();
    } else {
      const filteredData = defaultrRoleData.list.filter((filteredValue) => {
        let valueLowercase = filteredValue.name?.toUpperCase();
        let searchTermLowercase = value?.toUpperCase();
        return valueLowercase.indexOf(searchTermLowercase) > -1;
      });
      if (filteredData.length !== 0) {
        setRoleData((s) => ({ ...s, list: filteredData }));
      } else {
        alert("Sorry no data found");
        loadAllRole();
      }
    }
  };

  useEffect(() => {
    filterLeft(leftSearch);
    filterRight(rightSearch);
  }, [leftSearch, rightSearch]);

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            color="primary"
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  return (
    <div>
      <div className="role_container">
        <div className="btn_div">
          <button
            style={{ backgroundColor: showModel ? "#0a67b2" : "grey" }}
            className="button"
            onClick={() => {
              setShowModel(true);
              setIsAssosiate(false);
              setModelPermission(false);
            }}
          >
            Roles
          </button>
        </div>

        <div className="btn_div">
          <button
            style={{ backgroundColor: modelPermission ? "#0a67b2" : "grey" }}
            className="permission_button"
            onClick={() => {
              loadAllPermissions();
              setModelPermission(true);
              setIsAssosiate(false);
              setShowModel(false);
            }}
          >
            Permission
          </button>
        </div>
        <div className="btn_div">
          <button
            style={{
              backgroundColor: iaAssosiate ? "#0a67b2" : "grey",
              width: "100%",
            }}
            className="permission_button"
            onClick={() => {
              setModelPermission(false);
              setIsAssosiate(true);
              setShowModel(false);
            }}
          >
            Permission Assosiate
          </button>
        </div>
      </div>

      {showModel && (
        <div>
          <div className="model_content">
            <input
              className="role-input"
              value={saveRoleData.name}
              onChange={(e) =>
                setSaveRoleData((s) => ({ ...s, name: e.target.value }))
              }
              placeholder="Add Role"
            />
            <input
              className="role-input"
              value={saveRoleData.prefix}
              onChange={(e) =>
                setSaveRoleData((s) => ({ ...s, prefix: e.target.value }))
              }
              placeholder="Prefix"
            />
            {roleIsSave && (
              <SaveButton onClick={() => saveRole(saveRoleData)} />
            )}
          </div>
          <Grid container direction="column">
            <b>Search</b>
            <input
              className="role-input"
              onChange={(e) => searchByValue(e.target.value)}
              placeholder="Seach Roles"
            />
          </Grid>
          <div
            className="row"
            style={{
              width: "100%",
              padding: 30,
            }}
          >
            <table
              style={{
                width: "fit-content",
                height: "100%",
              }}
              class="table table-bordered"
            >
              <thead>
                <tr>
                  <th style={{ width: 70 }} scope="col">
                    ID
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Prefix</th>
                </tr>
              </thead>
              <tbody style={{ height: "60.5vh" }}>
                {roleData.list.length &&
                  roleData.list.map((val) => {
                    return (
                      <tr>
                        <td style={{ width: 70 }} scope="col">
                          {val.id}
                        </td>
                        <td scope="col">{val.name}</td>
                        <td scope="col">{val.prefix}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {modelPermission && (
        <div>
          <div className="model_content">
            <input
              className="role-input"
              value={permissionName}
              onChange={(e) => setPermissionName(e.target.value)}
              placeholder="Add Permission"
            />
            <input
              className="role-input"
              value={permissionLabel}
              onChange={(e) => setPermissionLabel(e.target.value)}
              placeholder="Add Label"
            />
            {roleIsSave && (
              <SaveButton
                onClick={() => savePermission(permissionName, permissionLabel)}
              />
            )}
          </div>
          <Grid container direction="column">
            <b>Search</b>
            <input
              className="role-input"
              onChange={(e) => filterPermission(e.target.value)}
              placeholder="Seach Permissions"
            />
          </Grid>
          <div
            className="row"
            style={{
              width: "100%",
              padding: 30,
            }}
          >
            <table>
              <thead>
                <tr>
                  <th style={{ width: 70 }}>ID</th>
                  <th>Name</th>
                  <th>Label</th>
                </tr>
              </thead>
              <tbody>
                {permissionData.map((item) => (
                  <tr style={{ height: 70 }} key={item.id}>
                    <td style={{ width: 70 }}>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.label}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {iaAssosiate && (
        <div
          style={{
            justifyContent: "center",
            marginTop: "30px",
            marginLeft: "30px",
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            className={classes.root}
          >
            <Grid
              style={{
                width: "370px",
                height: "300px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 300,
                    height: 60,
                    border: `1px solid #0a67b2`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {roleData.selected ? roleData.selected : "Select"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as={CustomMenu}
                  style={{
                    maxHeight: 225,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 300,
                    marginLeft: 0,
                    marginTop: "0px",
                    boxShadow:
                      " rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
                  }}
                >
                  {roleData?.list?.map((data, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 160, paddingTop: 13 }}
                        onClick={() => {
                          handleRolesToChange(data.id, data.name);
                        }}
                      >
                        {data.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </Grid>
            <Grid item>
              <input
                style={{ marginBottom: 10 }}
                className="role-input"
                value={leftSearch}
                onChange={(e) => setLeftSearch(e.target.value)}
                placeholder="Search Permission"
              />
              {customList("Disabled", filteredLeft)}
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                  style={{ color: "white", fontSize: 20 }}
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                  style={{ color: "white", fontSize: 20 }}
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <input
                style={{ marginBottom: 10 }}
                className="role-input"
                value={rightSearch}
                onChange={(e) => setRightSearch(e.target.value)}
                placeholder="Search Permission"
              />
              {customList("Enabled", filteredRight)}
            </Grid>
            <Grid
              style={{
                height: "300px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ "& button": { m: 1 } }}>
                <div>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.saveButton}
                    onClick={() => saveEnabled()}
                  >
                    Save
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Roles;
