import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader, getUserCode, getUserName } from "../../store/DataStore";

export const getCenters = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/mykare/centers`, {
    headers,
  });
  return response;
};

export const getPackages = async (centerCode) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/data/packages/all${
      centerCode ? `?centerCode=${centerCode}` : ""
    }`,
    {
      headers,
    }
  );
  return response;
};

export const getRelation = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/relation/all`, { headers });
  console.log("relation response", response);

  return response;
};

export const getPaymentMode = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/paymentType/all`, {
    headers,
  });

  return response;
};

export const getDoctors = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/mykare/doctors`, {
    headers,
  });
  return response;
};

export const getOrigins = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/mykare/origins`, {
    headers,
  });
  return response;
};

export const getInsuranceStatus = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/internal/mykare/insurance/status`,
    {
      headers,
    }
  );
  return response;
};

export const getAgentData = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const respone = await axios.get(`${APP_URL}/user/all`, {
    headers,
  });
  return respone;
};
export const saveSalesAgentDetail = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(
    `${APP_URL}/settings/lead/management/save`,
    postData,
    { headers }
  );
  return response;
};

export const savePreSalesAgentDetail = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(
    `${APP_URL}/settings/pre/lead/management/save`,
    postData,
    { headers }
  );
  return response;
};

export const saveTargetDetail = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(`${APP_URL}/team/target/save`, postData, {
    headers,
  });
  return response;
};

export const getTargetDetail = (month, year) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.get(`${APP_URL}/team/target/all?month=${month}`, {
    headers,
  });
  return response;
};

export const saveleaddigitalmanagement = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/settings/digital/budget/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getLeadDigitalManagement = async (month) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const respone = await axios.get(
    `${APP_URL}/settings/digital/budget?month=${month}`,
    {
      headers,
    }
  );

  return respone.data.digitalLeadResponseList;
};

export const saveKareBuddyDetail = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(
    `${APP_URL}/settings/karebuddy/management/save`,
    postData,
    { headers }
  );
  return response;
};
export const getBulkTransferCount = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(
    `${APP_URL}/mykare/enquiry/bulk/transfer/count`,
    postData,
    { headers }
  );
  return response;
};

export const sendCampaign = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const response = axios.post(
    `${APP_URL}/internal/mykare/message/campaign/schedule`,
    postData,
    { headers }
  );
  return response;
};

export const getCampaignTemplates = (treatmentId, locationId) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.get(
    `${APP_URL}/internal/mykare/message/campaign/template?locationId=${locationId}&treatmentId=${treatmentId}`,
    { headers }
  );
  return response;
};

export const getCampaignSchedules = () => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.get(
    `${APP_URL}/internal/mykare/message/campaign/schedule/all`,
    { headers }
  );
  return response;
};

export const sendBulkTransfer = (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(
    `${APP_URL}/mykare/enquiry/bulk/transfer`,
    postData,
    { headers }
  );
  return response;
};

export const getblacklist = () => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.get(`${APP_URL}/settings/blacklist/all`, { headers });
  return response;
};

export const blacklist = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(`${APP_URL}/settings/blacklist`, postData, {
    headers,
  });
  return response;
};

export const getSalesAgentDetail = async (agentCode) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const respone = await axios.get(
    `${APP_URL}/settings/lead/management?teamCode=${agentCode}`,
    {
      headers,
    }
  );
  return respone;
};

export const getPreSalesAgentDetail = async (agentCode) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const respone = await axios.get(
    `${APP_URL}/settings/pre/lead/management?teamCode=${agentCode}`,
    {
      headers,
    }
  );
  return respone;
};

export const getKareBuddyDetail = async (buddycode) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const respone = await axios.get(
    `${APP_URL}/settings/karebuddy/management?karebuddyCode=${buddycode}`,
    {
      headers,
    }
  );
  return respone;
};

export const getCallerDetails = async (userTxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/call/details?userTxnId=${userTxnId}`,
    { headers }
  );
  return response;
};

export const getAllExoPhoneNumbers = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/call/bridgePhone/all?type=EXOTEL`,
    { headers }
  );
  return response;
};

export const getCallRecordingLinks = async (callSid) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/call/recording?callSid=${callSid}`,
    { headers }
  );
  return response;
};


export const getCallRecordingList = async (txnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/call/details?userTxnId=${txnId}`,
    { headers }
  );
  return response;
};


export const getCircles = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/telecomCircle/all`, {
    headers,
  });
  return response;
};

export const postAddRole = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(`${APP_URL}/role/create`, postData, {
    headers,
  });
  return response;
};

export const postAddPermission = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(`${APP_URL}/permission/create`, postData, {
    headers,
  });
  return response;
};

export const getAllPermissions = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/permission/all`, {
    headers,
  });
  return response;
};

export const getAllRole = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/role/all`, {
    headers,
  });
  return response;
};

export const getSpecificRole = async (assignedId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/permission/${assignedId}`, {
    headers,
  });
  return response;
};

export const postEnabled = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.post(
    `${APP_URL}/role/rolePermission/associate`,
    postData,
    { headers }
  );
  return response;
};

export const softDelete = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  const response = axios.put(`${APP_URL}/enquiry/soft/delete`, postData, {
    headers,
  });
  return response;
};

export const searchComment = async (val) => {
  const headers = {
    Authorization: getAuthHeader(),
    Accept: "application/json",
  };
  const response = await axios.post(
    `${APP_URL}/internal/mykare/search/comment`,
    { value: val },
    {
      headers,
    }
  );
  return response;
};

export const sendCallCampaign = (postData) => {
  const headers = { Authorization: getAuthHeader() };
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const response = axios.post(
    `${APP_URL}/mykare/call/campaign/schedule`,
    postData,
    { headers }
  );
  return response;
};
