import { useState, useEffect } from "react";
import {
  getPatientsDetails,
  updatePatientsDetails,
} from "../../../services/mykare/PatientsService";
import {
  getAllRelation,
  getUserEducationList,
  getUserLanguageList,
  getUserLifestyleList,
  getUserLocationList,
  getUserMedicalHistoryList,
  getUserOccupationsList,
} from "../../../services/mykare/enquiryService";

function usePatientsDetail({
  patientId,
  refreshFn,
  handleHide,
  patientTxnId,
  patientData,
}) {
  const [age, setAge] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [selectedGender, setSelectedGender] = useState("MALE");

  const gender = ["MALE", "FEMALE", "OTHERS"];

  const meritalStatusList = [
    { id: 1, status: "MARRIED" },
    { id: 2, status: "UNMARRIED" },
    { id: 3, status: "WIDOWED" },
    { id: 4, status: "DIVORCED" },
    { id: 5, status: "SEPARATED" },
  ];

  const [lists, setLists] = useState({
    educationList: [],
    languageList: [],
    lifestyleList: [],
    medicalHistoryList: [],
    occupationList: [],
    locationList: [],
    relationList: [],
  });
  const [removeValue, setRemoveValue] = useState({
    habits: [],
    occupation: "",
    education: "",
    language: [],
    medicalHistory: [],
  });

  const [value, setValue] = useState({
    addedBy: "",
    addedByCode: "",
    alternateContact: null,
    age: null,
    city: null,
    educationId: null,
    email: null,
    gender: null,
    healthInsurance: false,
    languageIds: [],
    lifestyleIds: [],
    maritalStatus: null,
    medicalHistoryIds: [],
    occupationId: null,
    enquiryName: null,
    userId: 0,
    userTxnId: 0,
    uhId: 0,
    userName: null,
    relationName: null,
    relationId: null,
  });

  // console.log("[[[[[[[[[[[[[[[", value, patientData);

  const filterdLanguages =
    lists?.languageList &&
    lists?.languageList?.filter((language) =>
      [1, 6, 7, 9, 11, 19, 20].includes(language.id)
    );
  const isValid = !!(value?.userName && value?.gender);

  const loadPatientDetailList = async () => {
    getUserLanguageList().then((res) => {
      setLists((s) => ({ ...s, languageList: res.data }));
    });
    getUserEducationList().then((res) => {
      setLists((s) => ({ ...s, educationList: res.data }));
    });
    getUserOccupationsList().then((res) => {
      setLists((s) => ({ ...s, occupationList: res.data }));
    });
    getUserMedicalHistoryList().then((res) => {
      setLists((s) => ({ ...s, medicalHistoryList: res.data }));
    });
    getUserLifestyleList().then((res) => {
      setLists((s) => ({ ...s, lifestyleList: res.data }));
    });
    getAllRelation().then((res) => {
      setLists((s) => ({ ...s, relationList: res.data }));
    });
    // setLists({
    //   educationList: educaitonList?.data,
    //   languageList: languageList?.data,
    //   lifestyleList: lifestyleList?.data,
    //   medicalHistoryList: medicalHistoryList?.data,
    //   occupationList: occupationList?.data,
    //   locationList: locationList?.data,
    // });
    setValue({
      age: patientData?.data?.age || value?.age,
      alternateContact:
        patientData?.userResData?.alternateContact || value?.alternateContact,
      city: patientData?.userResData?.city || value?.city,
      educationId: value?.educationId,
      email: patientData?.data?.email || value?.email,
      gender: patientData?.gender || value?.gender,
      healthInsurance:
        patientData?.userResData?.healthInsurance ||
        value?.healthInsurance ||
        false,
      languageIds:
        (patientData?.userResData?.languages &&
          patientData?.userResData?.languages.map((item) => item?.id)) ||
        (value?.languageIds && value?.languageIds.map((item) => item.id)) ||
        [],
      lifestyleIds:
        (patientData?.userResData?.lifestyle &&
          patientData?.userResData?.lifestyle.map((item) => item?.id)) ||
        (value?.lifestyleIds && value?.lifestyleIds.map((item) => item.id)) ||
        [],
      maritalStatus:
        patientData?.userResData?.maritalStatus || value?.maritalStatus,

      medicalHistoryIds:
        (patientData?.userResData?.medicalHistories &&
          patientData?.userResData?.medicalHistories.map((item) => item?.id)) ||
        (value?.medicalHistoryIds &&
          value?.medicalHistoryIds.map((item) => item.id)) ||
        [],
      occupationId:
        patientData?.userResData?.occupation?.id || value?.occupationId,
      enquiryName: patientData?.data?.enquiryName || value?.enquiryName,
      userId: patientId,
      userTxnId: patientTxnId,
      uhId: patientData?.userResData?.uhId || value?.uhId,
      alternate: patientData?.userResData?.uhId || value?.alternateContact,
      userName: patientData?.data?.userName || value?.userName,
      relationName: patientData?.data?.relationName || value?.relationName,
    });
    setRemoveValue({
      occupation: patientData.userResData?.occupation?.name
        ? patientData.userResData?.occupation?.name
        : removeValue?.occupation,
      education: patientData?.userResData?.education?.name
        ? patientData?.userResData?.education?.name
        : removeValue?.education,
      language: patientData?.languages?.name || removeValue?.language,
    });
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (patientId || patientTxnId) {
      loadPatientDetails(patientId, patientTxnId);
    }
    loadPatientDetailList();
  }, []);

  const loadPatientDetails = (patientId, patientTxnId) => {
    getPatientsDetails(patientId, patientTxnId)
      .then((res) => {
        console.log("---------------------", res);
        setAge(res?.data?.age);
        setEmail(res?.data?.email);
        setName(res?.data?.patientName);
        setAlternateNumber(res?.data?.alternateContact);
        if (res?.data?.gender) {
          setSelectedGender(res?.data?.gender);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const editPatientDetail = async () => {
    if (isValid) {
      await updatePatientsDetails(value)
        .then((res) => {
          if (res?.status === 200) {
            alert("Successfully updated");
          }
        })
        .catch((error) => {
          alert("please Fill every Field");
          throw error;
        });
    } else {
      alert("Failed To Upload! Must be fill Name and Gender");
    }
    refreshFn(value);
  };
  const handleAlternateContactChange = (e) => {
    const newValue = e.target.value;
    setAlternateNumber(e.target.value);
    setInputValue(newValue);
    if (/^\d{10}$/.test(newValue)) {
      setErrorMessage("");
    } else {
      setErrorMessage("Please enter 10 digits");
    }
  };
  return {
    name,
    age,
    email,
    lists,
    value,
    gender,
    setAge,
    setName,
    setEmail,
    setValue,
    inputValue,
    errorMessage,
    successMessage,
    selectedGender,
    alternateNumber,
    handleInputChange,
    meritalStatusList,
    setSelectedGender,
    editPatientDetail,
    setRemoveValue,
    removeValue,
    filterdLanguages,
    handleAlternateContactChange,
  };
}

export default usePatientsDetail;
