import React from 'react'

function ErrorPage() {
  return (
    <div style={{width:"100%",height:"100vh",display:"flex", flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <h1 style={{fontSize:"200px"}}>404</h1>
        <p style={{fontSize:"50px"}}>Page not found</p>

    </div>
  )
}

export default ErrorPage