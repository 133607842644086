import React, { useEffect } from "react";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./SideMenu.css";
import {
  checkPermission,
  // checkPlatformAccess,
} from "../../util/permissionUtils";
import MessageIcon from "@mui/icons-material/Message";
import { getAuthHeader, getPlatforms } from "../../store/DataStore";
import PieChartIcon from "@mui/icons-material/PieChart";
import Badge from "@mui/material/Badge";
import axios from "axios";
import api from "../../axios-config";

function SideMenu() {
  // const [unreadCount, setUnreadCount] = useState(0);

  let history = useHistory();
  if (!localStorage.getItem("token")) {
    history.push("/");
  }

  function confirmViewUnreadMessages() {
    const userConfirmation = window.confirm(
      `You have unread messages. Do you want to view them now?`
    );

    if (userConfirmation) {
      // User clicked "OK"
      window.location.replace(process?.env.REACT_APP_CHAT_MODULE_URL);
    } else {
      // User clicked "Cancel"
      console.log("User chose not to view unread messages.");
    }
  }

  function Logout() {
    const confirmBox = window.confirm("Are you sure you want to logout?");
    if (confirmBox === true) {
      localStorage.removeItem("token");
      history.push({
        pathname: "/",
      });
    }
  }
  const getData = async () => {
    try {
      console.log("get data called");
      const headers = {
        Authorization: getAuthHeader(),
      };
      const code = localStorage.getItem("user-code");
      const role = localStorage.getItem("user-role");
      const centerCode = localStorage.getItem("centerCode");

      const response = await api.post(
        "/internal/chat/unread/count",
        {
          centerCode,
          code,
          role,
        },
        {
          headers,
        }
      );
      const prevCount = localStorage.getItem("unreadCount");
      if (prevCount != response?.data?.unreadCount) {
        localStorage.setItem("unreadCount", response?.data?.unreadCount);

        response?.data?.unreadCount != 0 && confirmViewUnreadMessages();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("inside UseEffect");
    getData();
  }, []);

  return (
    <div
      style={{
        width: "170px",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "120px",
          height: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
        }}
      >
        <div
          style={{
            width: "120px",
            height: "80px",
            backgroundColor: "#1892ff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            marginTop: "10px",
          }}
        >
          <h4 style={{ color: "white", fontWeight: 700 }}>CRM</h4>
        </div>
        <div
          style={{
            width: "120px",
            height: "500px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {checkPermission("FOCUS_TAB") && (
            <div
              className="menu-item hover-active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <a
                href={`${process.env.REACT_APP_REPORT_MODULE_URL}${
                  localStorage.getItem("user-role") === "SALES_AGENT"
                    ? `${process.env.REACT_APP_FOCUS_PATH}`
                    : `${process.env.REACT_APP_FOCUS_PATH}/admin`
                } `}
                className="menu-item"
                style={{
                  width: "120px",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textDecoration: "none",
                }}
              >
                <CenterFocusWeakIcon
                  fontSize="medium"
                  sx={{ color: "#2d4059" }}
                />
                <h6>Focus</h6>
              </a>
            </div>
          )}
          {checkPermission("LEADS_TAB") && (
            <NavLink
              to="/enquiry"
              className="menu-item"
              activeClassName="active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textDecoration: "none",
              }}
            >
              <LeaderboardIcon fontSize="medium" sx={{ color: "#2d4059" }} />
              <h6>Leads</h6>
            </NavLink>
          )}

          {checkPermission("TASK_TAB") && (
            <NavLink
              to="/task"
              className="menu-item"
              activeClassName="active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textDecoration: "none",
              }}
            >
              <AssignmentTurnedInIcon
                fontSize="medium"
                sx={{ color: "#2d4059" }}
              />
              <h6>Tasks</h6>
            </NavLink>
          )}

          {checkPermission("ANALYTICS_TAB") && (
            <a
              href={`${process.env.REACT_APP_ANALYTICS_MODULE_URL}`}
              className="menu-item"
              activeClassName="active"
              target="_blank"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textDecoration: "none",
              }}
            >
              <PieChartIcon fontSize="medium" sx={{ color: "#2d4059" }} />
              <h6>Analytics</h6>
            </a>
          )}

          {checkPermission("KARECHAT_TAB") && (
            <div
              className="menu-item hover-active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <a
                href={`${process.env.REACT_APP_CHAT_MODULE_URL}`}
                // href={`${process.env.REACT_APP_CHAT_MODULE_URL}
                // ${
                //   localStorage.getItem("user-role") === "SALES_AGENT"
                //     ? "karechat/homechat"
                //     : "karechat/homechat"
                // }
                //  `}
                className="menu-item"
                // target="_blank"
                style={{
                  width: "120px",
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textDecoration: "none",
                }}
              >
                {localStorage.getItem("unreadCount") != 0 ? (
                  <Badge
                    badgeContent={localStorage.getItem("unreadCount")}
                    color="primary"
                  >
                    <MessageIcon fontSize="medium" sx={{ color: "#2d4059" }} />
                  </Badge>
                ) : (
                  <MessageIcon fontSize="medium" sx={{ color: "#2d4059" }} />
                )}
                <h6>KareChat</h6>
              </a>
            </div>
          )}

          {/* <NavLink
              to={`${process.env.REACT_APP_CHAT_MODULE_URL}karechat/homechat`}
              className="menu-item"
              activeClassName="active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textDecoration: "none",
              }}
            >
              <SettingsIcon fontSize="medium" sx={{ color: "#2d4059" }} />
              <h6>kareChat</h6>
            </NavLink> */}

          {checkPermission("SETTINGS_TAB") && (
            <NavLink
              to="/settings"
              className="menu-item"
              activeClassName="active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textDecoration: "none",
              }}
            >
              <SettingsIcon fontSize="medium" sx={{ color: "#2d4059" }} />
              <h6>Settings</h6>
            </NavLink>
          )}
          {checkPermission("ADMIN_SETTINGS_TAB") && (
            <a
              href={`${process.env.REACT_APP_ADMIN_SETTINGS_URL}`}
              className="menu-item"
              activeClassName="active"
              style={{
                width: "120px",
                height: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textDecoration: "none",
              }}
            >
              <AdminPanelSettingsIcon
                fontSize="medium"
                sx={{ color: "#2d4059" }}
              />
              <h6>Admin Settings</h6>
            </a>
          )}

          <div
            className="menu-item hover-active"
            onClick={() => Logout()}
            style={{
              width: "120px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              cursor: "pointer",
              textDecoration: "none",
            }}
          >
            <LogoutIcon fontSize="medium" sx={{ color: "#2d4059" }} />
            <h6>Logout</h6>
          </div>
        </div>

        <div
          style={{
            width: "120px",
            height: "100px",
            padding: "5px",
          }}
        ></div>
      </div>
    </div>
  );
}

export default SideMenu;
