import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import {
  getUserRole,
  getUserCode,
  getAuthHeader,
  getUserName,
} from "../../store/DataStore";

export const updatePatientsDetails = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Accept: "application/json",
    Authorization: getAuthHeader(),
  };
  const response = await axios.put(`${APP_URL}/enquiry/update`, postData, {
    headers,
  });
  return response;
};

export const getPatientsDetails = async (userId, userTxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  try {
    if (userId && userTxnId) {
      const response = await axios.get(
        `${APP_URL}/user/getPersonalDetail?userId=${userId}&userTxnId=${userTxnId}`,
        {
          headers,
        }
      );
      return response;
    } else {
      throw new Error("Missing userId or userTxnId");
    }
  } catch (error) {
    console.error("Error in getPatientsDetails:", error);

    return { error: error.message };
  }
};

