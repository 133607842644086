import React from "react";
import "../Styles/sideBar.css";
import { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SidebarHeader,
} from "react-pro-sidebar";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import arrowIcon from "../assets/arrow-icon.svg";
import MakeCall from "../components/MakeCallComponent/MakeCall";
import { emptyCache } from "../util/cacheControling";

const Sidebar = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const item = localStorage.getItem("user-role");
  const userRol = item?.includes("_") ? item.replaceAll("_", " ") : item;
  const name = localStorage.getItem("user-name");
  const userName = name?.split("@")[0];

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  let history = useHistory();

  if (!localStorage.getItem("token")) {
    history.push("/");
  }
  function Logout() {
    const confirmBox = window.confirm("Are you sure you want to logout?");
    if (confirmBox === true) {
      localStorage.removeItem("token");
      history.push({
        pathname: "/",
      });
      emptyCache();
    }
  }

  const [open, setOpen] = React.useState(false);

  return (
    <ProSidebar collapsed={menuCollapse} className="border-gray">
      <SidebarHeader>
        <div className="logotext">
          <p className="admin-tag">
            {userName && !menuCollapse ? userName : ""}
          </p>
          <p className="admin-tag">{userRol && !menuCollapse ? userRol : ""}</p>
        </div>
        <div className="closemenu">
          {/* <div className="closemenu" onClick={menuIconClick}> */}
          {menuCollapse ? (
            <img
              src={arrowIcon}
              style={{
                width: "20px",
                height: "20px",

                marginBottom: "130px",
                marginTop: "695px",
                marginRight: "25px",
              }}
            />
          ) : (
            <img
              src={arrowIcon}
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "100%",
                marginBottom: "130px",
                marginTop: "2px",
                transform: "rotate(180deg)",
                marginTop: "695px",
                marginRight: "25px",
              }}
            />
          )}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square" style={{ marginTop: 10 }}>
          <MenuItem
            className="icon-cont"
            title="Logout"
            id="logout"
            onClick={() => Logout()}
            icon={<FiLogOut className="icon-blck" />}
          >
            Logout
          </MenuItem>
          <div class="mb-auto"></div>
          <MakeCall setOpen={setOpen} open={open} />
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};
export default Sidebar;
