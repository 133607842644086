import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../styles.css";
import axios from "axios";
import api from "../../axios-config";

const useLogin = () => {
  const history = useHistory();
  console.log(history?.location?.search?.replace("?redirect=", ""));

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState(false);
  const [userRole, setUserRole] = useState("");

  function loginUser() {
    api
      .post("/user/login", {
        username: username,
        password: password,
      })
      .then((resp) => {
        if (resp.status === 200) {
          localStorage.setItem("platforms", resp.data.platforms);

          localStorage.setItem("token", resp.data.token);
          localStorage.setItem("user-id", resp.data.id);
          localStorage.setItem("user-role", resp.data.role);
          localStorage.setItem("user-name", resp.data.name);
          localStorage.setItem("user-code", resp.data.code);
          localStorage.setItem("logo", resp.data.logo);
          localStorage.setItem(
            "user-permission",
            JSON.stringify(resp.data?.permissions)
          );
          if (history?.location?.search?.includes("redirect")) {
            console.log("entered if");
            window.location.replace(
              history?.location?.search?.replace("?redirect=", "")
            );
          }
          if (resp.data.kareflowUrl) {
            const url = resp.data.kareflowUrl.split("/");
            if (url.includes("kareflow-focus")) {
              // window.location.href =
              // process.env.REACT_APP_REPORT_MODULE_URL + resp.data.kareflowUrl;
              // history.push({ pathname: resp.data.kareflowUrl });/
              window.location.replace(resp.data.kareflowUrl);
            } else {
              // history.push({ pathname: resp.data.kareflowUrl });
              window.location.replace(resp.data.kareflowUrl);
            }
          } else {
            history.push({ pathname: "/enquiry" });
          }
        }
      })
      .catch((err) => {
        seterror(true);
        alert("You have entered an invalid username or password");
        console.log("error logging", err);
      });
  }
  return {
    loginUser,
    setUsername,
    seterror,
    setPassword,
    userRole,
  };
};
export default useLogin;
