
import fileDownload from "js-file-download";
import api from "../../axios-config";
import { getUserCode, getUserRole } from "../../store/DataStore";
import axios from "axios";
import { APP_URL } from "../../util/AppConstants";

export const getFilteredEnquiries = async (
  offset,
  perPage,
  enquiryType,
  search
) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const userRole = getUserRole();
  const userCode = getUserCode();
  var params = `pageNo=${offset}&pageSize=${perPage}&enquiryType=${enquiryType.toLowerCase()}&role=${userRole}&userCode=${userCode}`;
  if (search) {
    params += `&search=${search}`;
  }
  const response = await axios.get(
    `/associate/enquiry/filtered?${params}`,
    {
      headers,
    }
  );
  return response;
};

export const getEnquiryDetailsById = async (enquiryId,type) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `${APP_URL}/enquiry/getUserTrxnById?userTrxnId=${enquiryId}`,
    { headers }
  );

  return response;
};

export const getPartnerEnquiryReport = async (fromDate, toDate) => {

  const response = await axios.get(
    `/associate/enquiry/download/enquiryReport?fromDate=${fromDate}&toDate=${toDate}`,
    {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
  )
    .then((res) => {
      fileDownload(res.data, "Enquiries.xlsx");
    });


  return response;
};



