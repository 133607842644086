import React, { useState, useEffect } from "react";
import "./Enquiry.css";
import "../../Styles/Common.css";
import "../../Styles/sideBar.css";
import "../../Styles/Pagination.css";
import ReactPaginate from "react-paginate";
import useEnquiry from "./useEnquiry.logic";
import AddLead, { CustomMenu } from "../../components/AddLead/AddLead";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import DownloadPanel from "../../components/DownloadPanel/DownloadPanel";
import { withStyles } from "@material-ui/core/styles";
import { List, Tooltip, Typography } from "@material-ui/core";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes";
import CustomLoader from "../../components/CustomLoader";
import { Button, Drawer, Table } from "@mui/material";
import AutoCallPanel from "../../components/AutoCall/AutoCallPanel";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import SideMenu from "../../components/SideMenu/SideMenu";
import { checkPermission } from "../../util/permissionUtils";

const openInNewTab = (enquiryId) => {
  window.open(`mykareEnquiryDetail/${enquiryId}`, "_blank");
};

const tooltipStyles = {
  tooltip: {
    fontSize: "20px",
    borderRadius: "18px",
    boxShadow: "0 20px 80px 0",
    backgroundColor: "#FFFFE0",
    color: "black",
  },
};

const CustomTooltip = withStyles(tooltipStyles)(Tooltip);

function NotificationCard({ item }) {
  return (
    <div class="alert">
      <Table class="not">
        <tbody>
          <tr>
            <th>Enquiry Id</th>
            <td class="tdNot">{item?.enquiryId}</td>
          </tr>
          <tr>
            <th>Patient Name</th>
            <CustomTooltip title={` ${item?.userName ? item?.userName : ""}`}>
              <td
                class="tdNot "
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  border: "none",
                }}
              >
                {item?.userName}
              </td>
            </CustomTooltip>
          </tr>

          <tr>
            <th>Assigned to</th>
            <td class="tdNot">{item?.leadAssignedTo || "N/A"}</td>
          </tr>

          <tr>
            <th>Status</th>
            <td class="tdNot text-1">{item?.status}</td>
          </tr>
          <tr>
            <th>Call Date Time</th>
            <td class="tdNot dateColor">{item?.dateTime}</td>
          </tr>

          <div
            style={{
              display: "flex",
              position: "absolute",
              bottom: -15,
              justifyContent: "center",
              width: "100%",
              left: 0,
            }}
          >
            {item?.callThisPatient == false && (
              <Button
                size="small"
                class="notButton"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab(item?.enquiryId);
                }}
              >
                call this patient
              </Button>
            )}
            {item?.changeStatus == false && (
              <Button
                size="small"
                class="notButton2"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab(item?.enquiryId);
                }}
              >
                change the status
              </Button>
            )}
          </div>
        </tbody>
      </Table>
    </div>
  );
}

function Enquery() {
  const state = useEnquiry();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [showAddLead, setShowAddLead] = useState(false);
  const [showDownloadPanel, setShowDownloadPanel] = useState(false);
  const [showAutoCallPanel, setShowAutoCallPanel] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [logoIcon, setLogoIcon] = useState("");
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const [time, setTime] = useState(moment());

  useEffect(() => {
    getLogo();
    const intervalId = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getCurrentTime = () => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 12) {
      return "Good Morning";
    } else if (hours >= 12 && hours < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const getLogo = () => {
    const logo = localStorage.getItem("logo");
    setLogoIcon(logo);
  };

  if (!state.dataLoaded) {
    return <CustomLoader />;
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "170px",
              height: "100vh",
              backgroundColor: "white",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SideMenu />
          </div>

          <div style={{ width: "100%" }}>
            <div
              className="sticky"
              style={{
                height: "98.2vh",
                width: "98.5%",
                padding: "10px 10px",
                paddingRight: "20px",
                boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px`,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "80px",
                  backgroundColor: "#074173",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "30%",
                    height: "80px",
                    backgroundColor: "#074173",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h4
                    style={{
                      color: "white",
                      fontWeight: 600,
                      margin: 0,
                      padding: 0,
                      marginLeft: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    {getCurrentTime()}
                  </h4>
                  <p
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      color: "#07ffd0",
                      paddingTop: "7px",
                    }}
                  >
                    Manage your sales leads effectively
                  </p>
                </div>
                {checkPermission("CENTER_VIEW") && (
                  <div
                    style={{
                      width: "54%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 200,
                          height: 36,
                          border: `2px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#074173",
                          marginTop: "10px",
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            fontWeight: 600,
                            paddingTop: "14px",
                          }}
                        >
                          {state?.activeCenter?.name || "Select Center"}
                        </p>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          maxHeight: 210,
                          overflowY: "scroll",
                          overflowX: "hidden",
                          width: 200,
                          padding: "5px",
                          borderRadius: "0px",
                          backgroundColor: "#074173",
                          color: "black",
                          border: "2px solid #808080",
                        }}
                      >
                        <style>
                          {`
                         input::placeholder {
                         color: black;
                         font-weight:600;}

                         input {
                      caret-color: white;
                     background-color: gray,
          }
                         
                         `}
                        </style>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={state?.handleSearchList}
                          style={{
                            width: "100%",
                            padding: "5px",
                            boxSizing: "border-box",
                            marginBottom: "5px",
                            borderRadius: "0px",
                            border: "1px solid gray",

                            color: "black",
                          }}
                        />
                        {state
                          ?.filterList(state.centerList)
                          .map((value, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{
                                  width: 200,
                                  maxHeight: 210,
                                  borderRadius: "0px",
                                  color: "white",
                                  padding: "10px 5px",
                                  backgroundColor:
                                    hoveredIndex === index
                                      ? "#0056b3"
                                      : "#074173",
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => {
                                  state.sendActiveCenter(value);
                                }}
                              >
                                {state?.capitalize(value.name)}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}

                <div
                  style={{
                    width: "27%",
                    height: "70px",
                    backgroundColor: "#074173",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                      height: "70px",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <Typography style={{ color: "white", fontSize: "30px" }}>
                      {time?.format("LTS")}
                    </Typography>
                  </div>

                  <img
                    src={logoIcon}
                    style={{ width: 140, height: 50, marginLeft: "20px" }}
                  />
                </div>
              </div>

              <br />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: "#eafefb",
                }}
              >
                <div
                  style={{
                    width: "60%",
                    backgroundColor: "#eafefb",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {checkPermission("CREATE_LEAD") && (
                    <button
                      style={{
                        width: 110,
                        height: 40,
                        borderRadius: "0px",
                        backgroundColor: "#00541A",
                      }}
                      onClick={() => {
                        setShowAddLead(!showAddLead);
                        setShowDownloadPanel(false);
                        setShowAutoCallPanel(false);
                      }}
                    >
                      Create Lead
                    </button>
                  )}

                  <button
                    style={{
                      width: 80,
                      height: 40,
                      marginLeft: 10,
                      backgroundColor: "#157cd8",
                    }}
                    onClick={() => {
                      setShowAddLead(false);
                      setShowDownloadPanel(false);
                      setShowAutoCallPanel(false);
                      state.refreshTable();
                    }}
                  >
                    Refresh
                  </button>

                  <button
                    style={{ width: 110, height: 40, marginLeft: 10 }}
                    onClick={() => {
                      state.clearAllFilter();
                      const url = window.location.href.split("?");
                      window.location.href = url[0];
                    }}
                  >
                    Clear Filter
                  </button>
                </div>

                <div
                  style={{
                    width: "40%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: 170 }}>
                    <h6
                      style={{
                        color: "#0f2c59",
                        fontWeight: 700,
                        fontSize: 14,
                      }}
                    >
                      {" "}
                      select no of datas{" "}
                    </h6>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 162,
                          height: 33,
                          border: `2px solid black`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {state.selectedPageFilter
                          ? state.selectedPageFilter
                          : "20"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {state.pageFilters.map((value, index) => {
                          return (
                            <>
                              <Dropdown.Item
                                key={index}
                                href="#"
                                style={{ width: "162px" }}
                                onClick={() => {
                                  state.setSelectedPageFilter(value);
                                }}
                              >
                                {value}
                              </Dropdown.Item>
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div style={{ marginTop: "15px", marginRight: "10px" }}>
                    <ReactPaginate
                      previousLabel={"PREV"}
                      nextLabel={"NEXT"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={state.pageCount}
                      initialPage={state.offset}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={state.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      forcePage={state.offset}
                    />
                  </div>
                </div>
              </div>
              {showAddLead ? (
                <AddLead
                  onClicked={(openPopUp) => {
                    state.refreshTable();
                    setShowAddLead(openPopUp);
                  }}
                />
              ) : null}
              {showDownloadPanel ? (
                <DownloadPanel
                  onClicked={(openPopUp) => setShowDownloadPanel(openPopUp)}
                />
              ) : null}
              {showAutoCallPanel ? (
                <AutoCallPanel
                  onClicked={(callback) => setShowAutoCallPanel(callback)}
                />
              ) : null}

              <div
                className="row "
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <h6 style={{ color: "#0f2c59" }}>
                  {" "}
                  Showing {state.offset * state.selectedPageFilter} -{" "}
                  {(state.offset + 1) * state.selectedPageFilter <
                  state.totalCount
                    ? (state.offset + 1) * state.selectedPageFilter
                    : state.totalCount}{" "}
                  out of {state.totalCount}
                </h6>

                <div className="col-1 ">
                  <b>Enquiry Date</b>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 120,
                        height: 36,
                        border: `1px solid #00997c`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "0px",
                      }}
                    >
                      {state.selectedEnquiryDateFilter
                        ? state.selectedEnquiryDateFilter
                        : "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.enquiryDateFilter.map((value, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 160 }}
                              onClick={() => {
                                state.setSelectedEnquiryDateFilter(value);
                                state.setReload(true);
                              }}
                            >
                              {value}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 200,
                        borderRadius: 5,
                        marginBottom: 7,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>From</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryFromDate
                            ? state.searchEnquiryFromDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryFromDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                  {state.selectedEnquiryDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 200,
                        borderRadius: 5,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>To</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchEnquiryToDate
                            ? state.searchEnquiryToDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchEnquiryToDate(date);
                          state.setReload(true);
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="container col-1 m-0">
                  <b>Patient Name</b>
                  <br />
                  <div
                    style={{
                      border: `1px solid #00997c`,
                      height: "36px",
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      style={{
                        width: 95,

                        height: "36px",
                        border: "none",
                        outline: "none",
                        paddingLeft: "10px",
                      }}
                      name="search"
                      placeholder="Search.."
                      onChange={state.handleSearchItemChange}
                    />
                    <div
                      style={{
                        backgroundColor: "#06c6a2",
                        width: "40px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => state?.handleSearch(1)}
                    >
                      <SearchIcon fontSize="small" />
                    </div>
                  </div>
                </div>

                <>
                  <div className="container col-1 m-0">
                    <b>Phone Number</b>
                    <br />
                    <div
                      style={{
                        border: `1px solid #00997c`,
                        height: "36px",
                        display: "flex",
                        justifyContent: "space-between",
                        overflow: "hidden",
                      }}
                    >
                      <input
                        style={{
                          width: 95,
                          height: "36px",

                          border: "none",
                          outline: "none",
                          paddingLeft: "10px",
                        }}
                        name="phone"
                        placeholder="Search.."
                        onChange={state.handleSearchPhone}
                      />
                      <div
                        style={{
                          backgroundColor: "#06c6a2",
                          width: "40px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => state?.handleSearch(2)}
                      >
                        <SearchIcon fontSize="small" />
                      </div>
                    </div>
                  </div>
                </>

                <div className="container col-1 m-0">
                  <b>Enquiry ID</b>
                  <br />
                  <div
                    style={{
                      border: `1px solid #00997c`,

                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                      height: "36px",
                    }}
                  >
                    <input
                      style={{
                        width: 95,

                        height: "36px",

                        border: "none",
                        outline: "none",
                        paddingLeft: "10px",
                      }}
                      name="phone"
                      placeholder="Search.."
                      onChange={state.handleSearchEnquiryId}
                    />
                    <div
                      style={{
                        backgroundColor: "#06c6a2",
                        width: "40px",
                        height: "36px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => state?.handleSearch(3)}
                    >
                      <SearchIcon fontSize="small" />
                    </div>
                  </div>
                </div>

                {checkPermission("LEADS_ASSIGNED_FIELD") && (
                  <div className="container col-1 m-0">
                    <b>Assigned To</b>
                    <br />

                    <div
                      style={{
                        width: 120,
                        height: 35,
                        backgroundColor: "white",
                        border: "1px solid  #00997c",

                        // overflow: "hidden",
                      }}
                    >
                      <ReactMultiselectCheckboxes
                        options={state?.assignedUser?.list}
                        getDropdownButtonLabel={state.getDropdownButtonLabel}
                        value={state.selectedAssignedTo}
                        onChange={(value, event) =>
                          state.handleAssignedAgents(value, event)
                        }
                        setState={state.setSelectedAssignedTo}
                      />
                    </div>
                  </div>
                )}

                <div className="container col-1 m-0">
                  <b>Service</b>
                  <div
                    style={{
                      width: 120,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.treatmentData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedTreatments}
                      onChange={(value, event) =>
                        state.handleSelectTreatments(value, event)
                      }
                      setState={state.setSelectedTreatments}
                    />
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>Status</b>
                  <div
                    style={{
                      width: 120,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.statusData}
                      placeholderButtonLabel={"Choose"}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedStatuses}
                      onChange={(value, event) =>
                        state.handleSelectStatuses(value, event)
                      }
                      setState={state.setSelectedStatuses}
                    />
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>FollowUp Date</b>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="none"
                      style={{
                        width: 120,
                        height: 36,
                        border: `1px solid #00997c`,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        overflow: "hidden",
                        marginTop: "0px",
                      }}
                    >
                      {state.selectedFollowupDateFilter
                        ? state.selectedFollowupDateFilter
                        : "All"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {state.followUpDateFilter.map((value, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              href="#"
                              style={{ width: 160 }}
                              onClick={() => {
                                state.setSelectedFollowupDateFilter(value);
                                state.setReload(true);
                              }}
                            >
                              {value}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {state.selectedFollowupDateFilter === "Choose Date" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #00997c",
                        width: 160,
                        height: 40,
                      }}
                    >
                      <label style={{ marginLeft: 10 }}>From</label>
                      <DatePicker
                        className="mySearchEnquiryUpDatePicker followUpdate"
                        selected={
                          state.searchFollowupFromDate
                            ? state.searchFollowupFromDate
                            : new Date()
                        }
                        onChange={(date) => {
                          state.setSearchFollowupFromDate(date);
                          state.setReload(true);
                        }}
                      />
                    </div>
                  ) : null}
                  {state.selectedFollowupDateFilter === "Choose Date" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #00997c",
                          width: 160,
                          height: 40,
                          borderRadius: 5,
                        }}
                      >
                        <label style={{ marginLeft: 10 }}>To</label>
                        <DatePicker
                          className="mySearchEnquiryUpDatePicker followUpdate"
                          selected={
                            state.searchFollowupToDate
                              ? state.searchFollowupToDate
                              : new Date()
                          }
                          onChange={(date) => {
                            state.setSearchFollowupToDate(date);
                            state.setReload(true);
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="container col-1 m-0">
                  <b>Comments</b>

                  <input
                    style={{
                      width: 120,
                      height: "36px",
                      outline: "none",
                      paddingLeft: "10px",
                      border: "1px solid  #00997c",
                    }}
                    placeholder="Search.."
                  />
                </div>

                <div className="container col-1 m-0">
                  <b>Source</b>
                  <div
                    style={{
                      width: 120,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.sourceData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedSources}
                      onChange={(value, event) =>
                        state.handleSelectSources(value, event)
                      }
                      setState={state.setSelectedSources}
                    />
                  </div>
                </div>

                <div className="container col-1 m-0">
                  <b>Location</b>
                  <div
                    style={{
                      width: 120,
                      height: 35,
                      backgroundColor: "white",
                      border: "1px solid  #00997c",
                    }}
                  >
                    <ReactMultiselectCheckboxes
                      options={state.locationData}
                      getDropdownButtonLabel={state.getDropdownButtonLabel}
                      value={state.selectedLocations}
                      onChange={(value, event) =>
                        state.handleSelectLocations(value, event)
                      }
                      setState={state.setSelectedLocations}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <List
                component="nav"
                aria-label="mailbox folders"
                style={{
                  overflowX: "hidden",
                  overflowY: "scroll",
                  height: "60vh",
                  scrollbarWidth: "thin",
                }}
              >
                {state.homeData}
              </List>
            </div>
          </div>
          {isDrawerOpen && (
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
            >
              <div
                style={{
                  position: "fixed",
                  backgroundColor: "#06c6a2",
                  zIndex: 99,
                  width: "460px",
                  height: 70,
                }}
              >
                <h4 style={flexStyle}>Notifications </h4>

                <span class="closebtn" onClick={() => setIsDrawerOpen(false)}>
                  &times;
                </span>
                <div className="row" style={{ marginLeft: "8px" }}>
                  <button
                    onClick={() => {
                      state.setIsToday(true);
                      state.setPageNo(0);
                    }}
                    style={{
                      backgroundColor: state.isToday ? "#06c6a2" : "gray",
                      marginTop: "45px",
                      width: "42%",
                      marginLeft: "12px",
                      height: "50px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Today </span>
                    <span>
                      <Badge bg="danger">{state.todayCount}</Badge>
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      state.setIsToday(false);
                      state.setPageNo(0);
                    }}
                    style={{
                      backgroundColor: !state.isToday ? "#06c6a2" : "gray",
                      marginTop: "45px",
                      width: "42%",
                      marginLeft: "12px",
                      height: "50px",
                    }}
                  >
                    <span style={{ fontSize: "16px" }}>Missed </span>
                    <span>
                      <Badge bg="danger">{state.missedCount}</Badge>
                    </span>
                  </button>
                </div>

                <div className="col-md-2">
                  {checkPermission("LEADS_ASSIGNED_FIELD") && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        style={{
                          width: 200,
                          border: `1px solid gray`,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginLeft: "15px",
                        }}
                      >
                        {!!state.assignedAgent
                          ? state.assignedAgent
                          : "Assign To"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        as={CustomMenu}
                        style={{
                          height: 200,
                          width: 260,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {state?.assignedUser?.list?.map((value, id) => {
                          return (
                            <Dropdown.Item
                              key={id}
                              href="#"
                              style={{ width: 200 }}
                              onClick={() => {
                                state.setAssignedAgent(value.label);
                                state.setAssignedAgentCode(value.value);
                                state.loadAllTask(value.value);
                              }}
                            >
                              {value?.label}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
              </div>
              <hr></hr>

              <div style={{ width: 460 }}>
                {state.isloader ? (
                  <p>Loading....</p>
                ) : (
                  <InfiniteScroll
                    style={{ marginTop: "100vh" }}
                    dataLength={state.taskData.length}
                    next={state.fetchMoreData}
                    hasMore={state.hasMore}
                    loader={
                      state.hasMore && (
                        <h6 style={{ textAlign: "center" }}>Loading...</h6>
                      )
                    }
                    height={"75vh"}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                  >
                    {state.taskData.length ? (
                      state.taskData.map((item, i) => {
                        return <NotificationCard item={item} />;
                      })
                    ) : (
                      <div>
                        <p style={{ margin: "auto" }}>
                          <b>Task fully finished... ✌🏻🥳</b>
                        </p>
                      </div>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </Drawer>
          )}
        </div>
      </div>
    </>
  );
}

export default Enquery;
