import React, { useEffect } from "react";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import AppNavigator from "./AppNaviator";
import { cacheControling } from "./util/cacheControling";
// import ScreenTimeTracker from "./ScreenTimeTracker/ScreenTimeTracker";

function App() {
  useEffect(() => {
    cacheControling();
  }, []);

  return <AppNavigator />;
}

export default App;
