import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader } from "../../store/DataStore";

import api from "../../axios-config";

export const getPreSalesUsers = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/mykare/team/pre/all`, {
    headers,
  });
  return response;
};

export const getAllKareBuddies = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/karebuddy/all`, {
    headers,
  });
  return response;
};

export const getDoctors = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/data/doctors/all`, {
    headers,
  });
  return response;
};

export const updateDeviceToken = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.put(
    `${APP_URL}/mykare/team/updateToken`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getAllCountries = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/internal/mykare/country/all`, {
    headers,
  });
  return response;
};
