import React from "react";
import Lottie from "react-lottie";
// import loder from "./loader.json";
function LotieAnimation() {
  const options = {
    loop: true,
    autoplay: true,
    // animationData: loder,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie options={options} height={400} width={400} />;{" "}
    </div>
  );
}

export default LotieAnimation;
