import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { getCallRecordingList } from "../../services/mykare/dataService";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Drawer({ open, setOpen ,userTxnId}) {
  const [recordings, setRecordings] = useState([]);
  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  const loadCallRecordings = async (userTxnId) => {
    const response = await getCallRecordingList(userTxnId);
    setRecordings(response?.data?.details);
  };

  const handleUrl = (recordUrl) => {
    if (recordUrl) {
      window.confirm(`Do you want to play the record ?`) &&
        window.open(recordUrl, "_blank");
    } else {
      alert("Sorry audio not found..!");
    }
  };

  React.useEffect(() => {
    loadCallRecordings(userTxnId);
  }, [userTxnId]);

  const list = () => (
    <Box
      sx={{ width: 550, padding: "20px" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div style={{ width: "100%", paddingTop: "10px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h3 style={{ color: "#1892ff" }}>Call Recordings</h3>
        </div>

        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: "50%" }}>Date</StyledTableCell>
                <StyledTableCell align="center" sx={{ width: "50%" }}>
                  CallType
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recordings.map((row) => (
                <StyledTableRow key={row?.callSid}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ width: "50%" }}
                  >
                    {row?.date}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ width: "50%", cursor: "pointer" }}
                    onClick={() => handleUrl(row?.recording)}
                  >
                    {row?.callType}
                    <PlayCircleOutlineIcon sx={{ pl: 0.7, color: "#1892ff" }} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
