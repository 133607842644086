import React from "react";
import Lottie from "react-lottie";
import xmaxLotie from "../assets/Lotties/xmax.json";
import loader from "../../src/Pages/LotieAnimation/loader.json"

export default function CustomLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
}
