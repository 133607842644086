import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import { getAuthHeader, getUserCode, getUserRole } from "../../store/DataStore";
import { saveEventActivityLogs } from "../../util/EventActivity";

export const sendMessage = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.post(
    `${APP_URL}/internal/mykare/message/bulk/send`,
    postData,
    {
      headers,
    }
  );
  return data;
};

export const sendWaMessage = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  postData.agentCode = getUserCode();
  const { data } = await axios.post(
    `${APP_URL}/internal/chat/message/send`,
    postData,
    {
      headers,
    }
  );
  return data;
};
// export const sendMessage = async (postData) => {
//     const headers = {
//         Authorization: getAuthHeader(),
//     };

//     const { data } = await axios.post(
//         `${APP_URL}/internal/mykare/message/bulk/sendMessage`, postData,
//         {
//             headers,
//         }
//     );
//     return data;
// };

export const getBulkCount = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.post(
    `${APP_URL}/internal/mykare/message/bulk/count`,
    postData,
    {
      headers,
    }
  );
  return data;
};

export const getAllMessages = async (patientId) => {
   if (!patientId) {
    console.error("Invalid patientId:", patientId);
    throw new Error("patientId is required to fetch messages");
  }
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const response = await axios.get(
      `${APP_URL}/internal/chat/all?patientId=${patientId}`,
      {
        headers,
      }
    );
    console.log("api response", response);
    return response.data;
  } catch (error) {
    console.log("Error fetching messages:", error);
    throw error;
  }

};

export const getAllChats = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  postData.role = getUserRole();
  postData.code = getUserCode();
  const { data } = await axios.post(
    `${APP_URL}/internal/mykare/contacts/all`,
    postData,
    {
      headers,
    }
  );
  return data;
};

export const sendDocuments = async (data, patientId, whatsappNumberId) => {
  let formData = new FormData();
  const code = getUserCode();
  formData.append("whatsappDoc", data);
  formData.append("patientId", patientId);
  formData.append("agentCode", code);
  formData.append("whatsappNumberId", 1);
  formData.append("type", "document");

  const headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/internal/chat/document/upload`,
    formData,
    { headers }
  );
  return response;
};

// export const getAllMessageTemplates = async (numberType, isCampaign) => {
//   const headers = {
//     Authorization: getAuthHeader(),
//   };

//   const { data } = await axios.get(
//     `${APP_URL}/internal/mykare/message/template/all?numberType=${numberType}&campaign=${isCampaign}`,
//     {
//       headers,
//     }
//   );
//   return data;
// };

export const getAllMessageTemplates = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/internal/chat/template/all`, {
    headers,
  });
  return data;
};

export const downloadAttachment = async (documentId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(
    `${APP_URL}/internal/chat/download/${documentId}`,
    {
      headers,
    }
  );
  return data;
};
export const searchEnquiry = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.post(
    `${APP_URL}/internal/searchEnquiries`,
    postData,
    {
      headers,
    }
  );
  return data;
};

export const updateMessage = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/internal/chat/update`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getListOfTeam = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/mykare/team/all`, {
    headers,
  });
  return response;
};

export const getTeamByCode = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const code = getUserCode();
  const response = await axios.get(`${APP_URL}/mykare/team/${code}`, {
    headers,
  });
  return response;
};

export const getAllFlowTypeTemplates = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const { data } = await axios.get(`${APP_URL}/mykare/call/flowType/all`, {
    headers,
  });
  return data;
};
