import "./Lead.css";
import useLead from "./useLead.logic";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { checkPermission } from "../../util/permissionUtils";

function AddLead(props) {
  const state = useLead(props);

  const activePermission = () => {
    const isPrivilegedRole = ["SUPER_ADMIN", "ADMIN", "SALES_HEAD"].includes(
      localStorage.getItem("user-role")
    );
    const hasSelectedCenter = !!state?.selectedCenter?.name;
    return isPrivilegedRole && !hasSelectedCenter;
  };
  const activeCenter = state?.centers.filter((center) => center.isActive);

  return (
    <div>
      <hr />
      <div className="row popup-view">
        <div className="col-md-2">
          <input
            className=""
            onChange={(e) => state.setName(e.target.value)}
            placeholder="Name"
            style={{
              height: 36,
              borderRadius: 0,
              border: `1px solid black`,
              paddingLeft: "10px",
              width: 200,
            }}
          />
        </div>

        <div className="col-md-2">
          <div
            style={{
              height: 36,
              borderRadius: 0,
              border: `1px solid black`,
              display: "flex",
              width: 220,
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                className="drop-down"
                variant="none"
                style={{
                  height: 20,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "none",
                }}
              >
                {state.countryCode?.code || "+91"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: 200,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: 220,
                  marginTop: "8px",
                }}
              >
                {state?.countryList.map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ maxHeight: 60 }}
                      onClick={() => state.setCountryCode(value)}
                    >
                      {value.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <input
              onChange={(e) => state.setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              style={{
                height: "100%",
                width: "80%",
                borderRadius: 20,
                border: `1px solid white`,
                outline: "none",
              }}
              maxLength={10}
            />
          </div>
        </div>

        {["SUPER_ADMIN", "ADMIN", "SALES_HEAD"].includes(
          localStorage.getItem("user-role")
        ) && (
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  height: 36,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {state.selectedCenter ? state.selectedCenter.name : "Center"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: 210,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: 200,
                  padding: "5px",
                  borderRadius: "0px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  onChange={state?.handleSearch}
                  style={{
                    width: "100%",
                    padding: "5px",
                    boxSizing: "border-box",
                    marginBottom: "5px",
                    borderRadius: "0px",
                    border: "1px solid gray",
                  }}
                />

                {state?.filterList(activeCenter).map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200, maxHeight: 200 }}
                      onClick={() => {
                        state.setSelectedCenter(value);
                        if (state?.searchTerm) {
                          state?.setSearchTerm("");
                        }
                      }}
                    >
                      {state?.capitalize(value.name)}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              disabled={activePermission()}
            >
              {state.selectedLocation
                ? state.selectedLocation.name
                : "Location"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 210,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
                padding: "5px",
                borderRadius: "0px",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                onChange={state?.handleSearch}
                style={{
                  width: "100%",
                  padding: "5px",
                  boxSizing: "border-box",
                  marginBottom: "5px",
                  borderRadius: "0px",
                  border: "1px solid gray",
                }}
              />

              {state?.filterList(state.locations).map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => {
                      state.setSelectedLocation(value);
                      if (state?.searchTerm) {
                        state.setSearchTerm("");
                      }
                    }}
                  >
                    {state?.capitalize(value.name)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              disabled={activePermission()}
            >
              {state.selectedTreatment
                ? state.selectedTreatment.name
                : "Services"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 210,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 200,
                padding: "5px",
                borderRadius: "0px",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                onChange={state?.handleSearch}
                style={{
                  width: "100%",
                  padding: "5px",
                  boxSizing: "border-box",
                  marginBottom: "5px",
                  borderRadius: "0px",
                  border: "1px solid gray",
                }}
              />
              {state?.filterList(state.treatments).map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => {
                      state.setselectedTreatment(value);
                      if (state?.searchTerm) {
                        state.setSearchTerm("");
                      }
                    }}
                  >
                    {state?.capitalize(value.name)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="col-md-2">
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 290,
                height: 36,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {state.selectedSource ? state.selectedSource.name : "Source"}
            </Dropdown.Toggle>
            <Dropdown.Menu
              style={{
                maxHeight: 210,
                overflowY: "scroll",
                overflowX: "hidden",
                width: 290,
                padding: "5px",
                borderRadius: "0px",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                onChange={state?.handleSearch}
                style={{
                  width: "100%",
                  padding: "5px",
                  boxSizing: "border-box",
                  marginBottom: "5px",
                  borderRadius: "0px",
                  border: "1px solid gray",
                }}
              />
              {state?.filterList(state.sources).map((value, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    href="#"
                    style={{ width: 200, maxHeight: 200 }}
                    onClick={() => {
                      state.setSelectedSource(value);

                      if (state?.searchTerm) {
                        state.setSearchTerm("");
                      }
                    }}
                  >
                    {state?.capitalize(value.name)}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/* {state.selectedOrigin == "Doctor Referral" ? (
          <div className="col-md-2">
            <input
              className=""
              onChange={(e) => state.setName(e.target.value)}
              placeholder="Referral Name"
              style={{ height: 38, borderRadius: 5, border: `1px solid black` }}
            />
          </div>
        ) : null} */}

        {/* {[29, 18, 17].includes(state.selectedSource?.id) && (
          <div className="col-md-2">
            <input
              onChange={(e) => state.setReferral(e.target.value)}
              placeholder="Referral Name"
              style={{
                height: "40px",
                width: "250px",
                border: `1px solid gray`,
                outline: "none",
                marginLeft: "20px",
                paddingLeft: "10px",
              }}
            />
          </div>
        )} */}

        {/* <div className="col-md-2" style={{ backgroundColor: "red" }}>
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {!!state.assignedAgent ? state.assignedAgent : "Assign To"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {state.userData.map((value, id) => {
                return (
                  <Dropdown.Item
                    key={id}
                    href="#"
                    style={{ width: 200 }}
                    onClick={() =>
                      state.handleToAssigned(value.name, value.code)
                    }
                  >
                    {value.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div> */}

        <div className="col-md-2">
          {checkPermission("LEADS_ASSIGNED_FIELD") && (
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  height: 36,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!!state.assignedAgent ? state.assignedAgent : "Assign To"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  maxHeight: 210,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: 200,
                  padding: "5px",
                  borderRadius: "0px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search"
                  onChange={state?.handleSearch}
                  style={{
                    width: "100%",
                    padding: "5px",
                    boxSizing: "border-box",
                    marginBottom: "5px",
                    borderRadius: "0px",
                    border: "1px solid gray",
                  }}
                />
                {state?.filterList(state.userData).map((value, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      href="#"
                      style={{ width: 200, maxHeight: 200 }}
                      onClick={() => {
                        state.handleToAssigned(value.name, value.code);
                        if (state?.searchTerm) {
                          state?.setSearchTerm("");
                        }
                      }}
                    >
                      {state?.capitalize(value.name)}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        <div className="col-10">
          <div className="row">
            {["Doctor Referral", "Patient Referral"].includes(
              state?.selectedSource?.name
            ) && (
              <div className="col-md-2" style={{ marginTop: "10px" }}>
                <input
                  className=""
                  onChange={(e) => state.setName(e.target.value)}
                  placeholder="Referal Name"
                  style={{
                    height: 36,
                    borderRadius: 0,
                    border: `1px solid black`,
                    paddingLeft: "10px",
                  }}
                />
              </div>
            )}
            <div className="col-4">
              <div
                style={{ display: "flex", width: "400px", marginTop: "10px" }}
              >
                <textarea
                  style={{ width: "100%", paddingLeft: "10px" }}
                  placeholder="Add Comments"
                  onChange={(e) => state.setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div
              className="col-md-2"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  variant="none"
                  style={{
                    width: 200,
                    height: 36,
                    border: `1px solid gray`,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {state.selectedRelation
                    ? state.selectedRelation?.name
                    : "Relation"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    maxHeight: 210,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: 200,
                    padding: "5px",
                    borderRadius: "0px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={state?.handleSearch}
                    style={{
                      width: "100%",
                      padding: "5px",
                      boxSizing: "border-box",
                      marginBottom: "5px",
                      borderRadius: "0px",
                      border: "1px solid gray",
                    }}
                  />

                  {state?.relation?.map((value, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        href="#"
                        style={{ width: 200, maxHeight: 200 }}
                        onClick={() => {
                          state.setSelectedRelation(value);
                          if (state?.searchTerm) {
                            state?.setSearchTerm("");
                          }
                        }}
                      >
                        {value?.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {state?.selectedRelation?.name &&
              state?.selectedRelation?.id !== 1 && (
                <div
                  className="col-md-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    className=""
                    onChange={(e) => state.setRelationName(e.target.value)}
                    placeholder={`${state?.selectedRelation?.name}'s Name`}
                    style={{
                      height: 36,
                      borderRadius: 0,
                      border: `1px solid black`,
                      paddingLeft: "10px",
                      width: 200,
                    }}
                  />
                </div>
              )}

            <div className="col-4">
              <div
                className="row"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="col-md-2" style={{ marginLeft: "30px" }}>
                  <button
                    style={{ height: 36, width: 80, backgroundColor: "red" }}
                    onClick={() => props.onClicked(false)}
                  >
                    Cancel
                  </button>
                </div>

                <div className="col-md-1">
                  <button
                    onClick={() => state.saveEnquiryDetails()}
                    style={{
                      height: 36,
                      width: 80,
                      marginLeft: "20px",
                      backgroundColor: "#157cd8",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <hr style={{ marginTop: "40px" }} />
    </div>
  );
}

export default AddLead;

export const CustomMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, searchStyle },
    ref
  ) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          style={searchStyle}
          autoFocus
          className={!searchStyle && "mx-3 my-2 w-auto"}
          placeholder="Search"
          onChange={(e) => setValue(e?.target?.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React?.Children?.toArray(children)?.filter(
            (child) =>
              !value ||
              child?.props?.children
                ?.toString()
                .toLowerCase()
                ?.startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);
