import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import usePatientsDetail from "./usePatientsDetail.logic";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, Box, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import { checkPermission } from "../../../util/permissionUtils";

function PatientsDetail({
  patientId,
  refreshFn,
  handleHide,
  patientTxnId,
  patientData,
  newData,
}) {
  const theme = useTheme();
  const state = usePatientsDetail({
    patientId,
    refreshFn,
    handleHide,
    patientTxnId,
    patientData,
    newData,
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <div className="row" style={{ rowGap: "20px" }}>
      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            User Name
          </p>
          <input
            placeholder="User Name"
            value={state?.value?.userName}
            style={{
              width: 200,
              height: 50,
              borderRadius: 5,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={(e) =>
              state?.setValue((pre) => ({
                ...pre,
                userName: e?.target?.value,
              }))
            }
          />
        </div>
      </div>

      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Patient Age
          </p>
          <input
            placeholder="Age"
            value={state?.value?.age}
            style={{
              width: 200,
              height: 50,
              borderRadius: 5,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={(e) =>
              state?.setValue((pre) => ({ ...pre, age: e?.target?.value }))
            }
          />
        </div>
      </div>
      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Patient Email
          </p>
          <input
            placeholder="Email"
            value={state?.value?.email}
            style={{
              width: 200,
              height: 50,
              borderRadius: 5,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={(e) =>
              state?.setValue((pre) => ({
                ...pre,
                email: e?.target?.value,
              }))
            }
          />
        </div>
      </div>

      <div className="col-lg-2">
        {console.log(state?.gender, "gender console")}
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Gender
          </p>
          <Dropdown>
            <Dropdown.Toggle
              variant="none"
              style={{
                width: 200,
                height: 50,
                border: `1px solid gray`,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: 0,
              }}
            >
              {state?.value?.gender || "Gender"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {state?.gender?.map((value, id) => {
                return (
                  <Dropdown.Item
                    key={id}
                    href="#"
                    style={{ width: 200 }}
                    onClick={() =>
                      state.setValue((pre) => ({ ...pre, gender: value }))
                    }
                  >
                    {value}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {checkPermission("LEADS_PHONE_NUMBER_FIELD") && (
        <div className="col-lg-2">
          <div>
            <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
              Patient Alternate Contact
            </p>
            <input
              placeholder="Number"
              value={state?.value?.alternateContact}
              style={{
                width: 200,
                height: 50,
                borderRadius: 5,
                paddingLeft: 10,
                border: `1px solid gray`,
              }}
              onChange={(e) =>
                state?.setValue((pre) => ({
                  ...pre,
                  alternateContact: e?.target?.value,
                }))
              }
              onInput={(event) => {
                event.target.value = event.target.value.replace(/[^0-9]/g, "");
                if (event.target.value.length > 10) {
                  event.target.value = event.target.value.slice(0, 10);
                }
              }}
            />
            {state.errorMessage && (
              <div style={{ color: "red" }}>{state.errorMessage}</div>
            )}
          </div>
        </div>
      )}

      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Marital status
          </p>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.meritalStatusList || []}
            value={state?.value?.maritalStatus}
            size="small"
            sx={{ width: 200 }}
            getOptionLabel={(option) =>
              option?.status || state?.value?.maritalStatus
            }
            onChange={(e, value) =>
              state?.setValue((pre) => ({
                ...pre,
                maritalStatus: value?.status,
              }))
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Marital status" />
            )}
          />
        </div>
      </div>

      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Occupation
          </p>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.lists?.occupationList || []}
            value={state?.removeValue?.occupation}
            size="small"
            sx={{ width: 200 }}
            getOptionLabel={(option) =>
              option?.name || state?.removeValue?.occupation
            }
            onChange={(e, value) => {
              state?.setValue((pre) => ({
                ...pre,
                occupationId: value?.id,
              }));
              state?.setRemoveValue((pre) => ({
                ...pre,
                occupation: value?.name,
              }));
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Occupation" />
            )}
          />
        </div>
      </div>

      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Education
          </p>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={state?.lists?.educationList || []}
            value={state?.removeValue?.education}
            getOptionLabel={(option) =>
              option?.name || state?.removeValue?.education
            }
            onChange={(e, value) => {
              state?.setValue((pre) => ({
                ...pre,
                educationId: value?.id,
              }));
              state?.setRemoveValue((pre) => ({
                ...pre,
                education: value?.name,
              }));
            }}
            size="small"
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Education" />
            )}
          />
        </div>
      </div>
      <div className="col-2">
        <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
          Habits
        </p>
        <FormControl sx={{ width: 200 }} size="medium">
          <Select
            labelId="lifestyleIds-chip-label"
            id="lifestyleIds-chip"
            multiple
            value={state?.value?.lifestyleIds}
            onChange={(event) =>
              state?.handleInputChange(event, "lifestyleIds")
            }
            name="lifestyleIds"
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const lifestyleIds = state?.lists?.lifestyleList?.find(
                    (item) => item.id === value
                  );
                  return (
                    <Chip
                      key={value}
                      label={lifestyleIds ? lifestyleIds.name : ""}
                    />
                  );
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {state?.lists?.lifestyleList &&
              state?.lists?.lifestyleList.map((item) => (
                <MenuItem
                  key={`lifestyleIds${item.id}`}
                  value={item.id}
                  style={getStyles(
                    item.name,
                    state?.value?.lifestyleIds,
                    theme
                  )}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Location
          </p>

          <TextField
            placeholder="Locations"
            multiline
            rows={1}
            style={{ width: 200 }}
            onChange={(e) =>
              state?.setValue((pre) => ({ ...pre, city: e?.target?.value }))
            }
            value={state?.value?.city}
          />
        </div>
      </div>

      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            UHID
          </p>

          <TextField
            placeholder="UHID number"
            rows={1}
            style={{ width: 200 }}
            onChange={(e) =>
              state?.setValue((pre) => ({ ...pre, uhId: e?.target?.value }))
            }
            value={state?.value?.uhId}
          />
        </div>
      </div>

      <div className="col-lg-2">
        <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
          Language
        </p>
        <FormControl sx={{ width: 200 }}>
          <Select
            labelId="languageIds-chip-label"
            id="languageIds-chip"
            multiple
            placeholder="Language"
            value={state?.value.languageIds || []}
            onChange={(event) => state?.handleInputChange(event, "languageIds")}
            name="languageIds"
            input={
              <OutlinedInput id="select-multiple-chip" placeholder="Language" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const language = state?.lists.languageList?.find(
                    (item) => item.id === value
                  );
                  return (
                    <Chip key={value} label={language ? language?.name : ""} />
                  );
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {state?.lists.languageList &&
              state?.lists.languageList?.map((item) => (
                <MenuItem
                  key={`languageIds${item.id}`}
                  value={item.id}
                  style={getStyles(item.name, state?.value?.languageIds, theme)}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="col-2">
        <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
          Medical History
        </p>
        <FormControl sx={{ width: 200 }}>
          <Select
            labelId="medicalHistoryIds-chip-label"
            id="medicalHistoryIds-chip"
            multiple
            value={state?.value.medicalHistoryIds}
            onChange={(event) =>
              state?.handleInputChange(event, "medicalHistoryIds")
            }
            name="medicalHistoryIds"
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => {
                  const medicalHistoryIds =
                    state?.lists?.medicalHistoryList?.find(
                      (item) => item.id === value
                    );
                  return (
                    <Chip
                      key={value}
                      label={medicalHistoryIds ? medicalHistoryIds.name : ""}
                    />
                  );
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {state?.lists?.medicalHistoryList &&
              state?.lists?.medicalHistoryList.map((item) => (
                <MenuItem
                  key={`medicalHistoryIds${item.id}`}
                  value={item.id}
                  style={getStyles(
                    item.name,
                    state?.value?.medicalHistoryIds,
                    theme
                  )}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-2">
        <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
          Relationship
        </p>
        {console.log(state.lists.relationList.relationResponses, "data needed")}
        {/* <FormControl sx={{ width: 200 }}>
          <Select
            labelId="medicalHistoryIds-chip-label"
            id="medicalHistoryIds-chip"
            multiple
            value={state?.value?.relationName}
            onChange={(event) => state?.handleInputChange(event, "relationId")}
            name="medicalHistoryIds"
            input={<OutlinedInput id="select-multiple-chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => {
                  const relation = state?.lists?.relationList?.find(
                    (item) => item.id === value
                  );
                  return (
                    <Chip key={value} label={relation ? relation.name : ""} />
                  );
                })}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {state?.lists?.relationList.relationResponses &&
              state.lists.relationList.relationResponses?.map((item) => (
                <MenuItem
                  key={item?.id}
                  value={item?.name}
                  style={getStyles(
                    item.name,
                    state?.value?.relationName,
                    theme
                  )}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl> */}
        <div className="col-lg-2">
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant="none"
                style={{
                  width: 200,
                  height: 50,
                  border: `1px solid gray`,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                {state?.value?.relationName || "Gender"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {state?.lists?.relationList?.relationResponses?.map(
                  (value, id) => {
                    return (
                      <Dropdown.Item
                        key={id}
                        href="#"
                        style={{ width: 200 }}
                        onClick={() =>
                          state.setValue((pre) => ({
                            ...pre,
                            relationName: value.name,
                            relationId: value.id,
                          }))
                        }
                      >
                        {value?.name}
                      </Dropdown.Item>
                    );
                  }
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="col-lg-2">
        <div>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: 0 }}>
            Patient Name
          </p>
          <input
            placeholder="Name"
            value={state?.value?.enquiryName}
            style={{
              width: 200,
              height: 50,
              borderRadius: 5,
              paddingLeft: 10,
              border: `1px solid gray`,
            }}
            onChange={(e) =>
              state.setValue((pre) => ({
                ...pre,
                enquiryName: e?.target?.value,
              }))
            }
          />
        </div>
      </div>

      <div className="col-lg-2">
        <FormControlLabel
          control={
            <Checkbox
              checked={state?.value.healthInsurance}
              value={state?.value.healthInsurance}
              onChange={(event) => {
                event.preventDefault();
                state?.setValue((prev) => ({
                  ...prev,
                  healthInsurance: event.target.checked,
                }));
              }}
              name="healthInsurance"
            />
          }
          label={`Have Health Insurance ? ${
            state?.value.healthInsurance === true ? "Yes" : "No"
          }`}
        />
      </div>
      <div className="col-lg-2">
        <button
          onClick={() => state.editPatientDetail()}
          style={{
            width: 70,
            padding: 8,
            marginTop: 20,
            backgroundColor: "#1892ff",
            border: "2px solid #0a67b2",
          }}
        >
          Save
        </button>
      </div>
      <p style={{ color: "green" }}>{state.successMessage}</p>
    </div>
  );
}

export default PatientsDetail;
