import axios from "axios";
import { APP_URL } from "../../util/AppConstants";
import api from "../../axios-config";
import {
  getUserRole,
  getUserCode,
  getAuthHeader,
  getUserName,
} from "../../store/DataStore";
import downloadDocument from "../../util/documentUtil";
import fileDownload from "js-file-download";
import useEnquiry from "../../Pages/Enquiry/useEnquiry.logic";

const funnelFilter = parseInt(window.location.href.split("=")[1]);

export const getFilteredEnquiries = async (
  offset,
  perPage,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  paymentModeFilter,
  intentFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  sourceIds,
  locationIds,
  searchEnquiryId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["SURGERY", "ENQUIRY"],
    statusIds,
    assignedTo: assignedUserCode.length ? assignedUserCode : [],
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    // paymentModeFilter: paymentModeFilter ? paymentModeFilter : "All",
    intentFilter: intentFilter ? intentFilter : "All",
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    sourceIds,
    locationIds,
    enquiryId: searchEnquiryId ? searchEnquiryId : 0,
    // funnelFilter,
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};

export const getFilteredTasks = async (
  offset,
  perPage,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  paymentModeFilter,
  intentFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  sourceIds,
  locationIds,
  searchEnquiryId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["SURGERY", "ENQUIRY"],
    taskStatusIds:statusIds,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    // paymentModeFilter: paymentModeFilter ? paymentModeFilter : "All",
    intentFilter: intentFilter ? intentFilter : "All",
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    sourceIds,
    locationIds,
    enquiryId: searchEnquiryId ? searchEnquiryId : 0,
    // funnelFilter,
    task: true,
    taskAssignedTo: assignedUserCode.length ? assignedUserCode : [],
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};

export const getFilteredPreventiveCareEnquiries = async (
  offset,
  perPage,
  enquiryType,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  sourceId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["PREVENTIVE_CARE"],
    statusIds,
    assignedTo: assignedUserCode,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    sourceId,
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};
export const getFilteredOtherLeadEnquiries = async (
  offset,
  perPage,
  enquiryType,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  locationIds,
  sourceIds,
  searchEnquiryId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
    // Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcmFkbWluQHRlc3QuY29tIiwiZXhwIjoxNzE5Njk3NjgzLCJpYXQiOjE3MTk2NTQ0ODN9.bmbvO22wV_3a2EzvwbZV0u_mpVjgQBrjIM942wdOOx5iiTn_rRTMxvEomYIhyMioHExZ5Q2ZwjOxEjEeg77j-w"
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["OTHER_LEADS"],
    statusIds,
    assignedTo: assignedUserCode,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    locationIds,
    sourceIds,
    enquiryId: searchEnquiryId ? searchEnquiryId : 0,
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};

export const getScan = async (
  offset,
  perPage,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  paymentModeFilter,
  intentFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  sourceIds,
  locationIds,
  searchEnquiryId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
    // Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcmFkbWluQHRlc3QuY29tIiwiZXhwIjoxNzE5Njk3NjgzLCJpYXQiOjE3MTk2NTQ0ODN9.bmbvO22wV_3a2EzvwbZV0u_mpVjgQBrjIM942wdOOx5iiTn_rRTMxvEomYIhyMioHExZ5Q2ZwjOxEjEeg77j-w"
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["KARE_SCAN"],
    statusIds,
    assignedTo: assignedUserCode.length ? assignedUserCode : [],
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    paymentModeFilter: paymentModeFilter ? paymentModeFilter : "All",
    intentFilter: intentFilter ? intentFilter : "All",
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    sourceIds,
    locationIds,
    enquiryId: searchEnquiryId ? searchEnquiryId : 0,
    funnelFilter,
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};

export const getScanDetailsById = async (enquiryId, type, userTxnId) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `/mykare/enquiry/byContext?contextId=${enquiryId}&categoryContext=${type}&userTxnId=${userTxnId}`,
    { headers }
  );

  return response;
};

export const getTaskDetailsById = async (taskId,type) => {
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };
  const response = await axios.get(
    `${APP_URL}/enquiry/getTaskById?taskId=${taskId}`,
    { headers }
  );

  return response;
};


export const getFilteredOtherPreLeadEnquiries = async (
  offset,
  perPage,
  enquiryType,
  preAssignedCode,
  preSelectedStatusIds,
  preLeadStatusIds,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  locationIds,
  sourceIds,
  searchEnquiryId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
    // Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcmFkbWluQHRlc3QuY29tIiwiZXhwIjoxNzE5Njk3NjgzLCJpYXQiOjE3MTk2NTQ0ODN9.bmbvO22wV_3a2EzvwbZV0u_mpVjgQBrjIM942wdOOx5iiTn_rRTMxvEomYIhyMioHExZ5Q2ZwjOxEjEeg77j-w"
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["SURGERY", "ENQUIRY"],
    preLeadStatusIds: statusIds?.length ? statusIds : [],
    preLeadAssignedTo: assignedUserCode?.length ? assignedUserCode : [],
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    // treatmentIds,
    packageIds: treatmentIds,
    locationIds,
    sourceIds,
    enquiryId: searchEnquiryId ? searchEnquiryId : 0,
  };
  const response = await axios.post(
    `${APP_URL}/enquiry/pre/filtered`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getFilteredSMOEnquiries = async (
  offset,
  perPage,
  enquiryType,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  sourceId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
    // Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcmFkbWluQHRlc3QuY29tIiwiZXhwIjoxNzE5Njk3NjgzLCJpYXQiOjE3MTk2NTQ0ODN9.bmbvO22wV_3a2EzvwbZV0u_mpVjgQBrjIM942wdOOx5iiTn_rRTMxvEomYIhyMioHExZ5Q2ZwjOxEjEeg77j-w"
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: ["SMO"],
    statusIds,
    assignedTo: assignedUserCode,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    sourceId,
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};

export const getTask = async (postData, assignCode) => {
  if (assignCode == "ALL") {
    postData.code = getUserCode();
    postData.role = getUserRole();
  } else {
    postData.code = assignCode;
    postData.role = "SALES_AGENT";
  }
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/getAllTask`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getFilteredAddOnServiceEnquiries = async (
  offset,
  perPage,
  enquiryType,
  statusIds,
  patientName,
  phone,
  assignedUserCode,
  enquiryDateFilter,
  enquiryFromDate,
  enquiryToDate,
  followUpDateFilter,
  followUpFromDate,
  followUpToDate,
  treatmentIds,
  sourceId
) => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
    // Authorization:"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcmFkbWluQHRlc3QuY29tIiwiZXhwIjoxNzE5Njk3NjgzLCJpYXQiOjE3MTk2NTQ0ODN9.bmbvO22wV_3a2EzvwbZV0u_mpVjgQBrjIM942wdOOx5iiTn_rRTMxvEomYIhyMioHExZ5Q2ZwjOxEjEeg77j-w"
  };

  const postData = {
    pageNo: offset,
    pageSize: perPage,
    role,
    code,
    patientName,
    phone,
    categories: [
      "FREE_COUNSELLING",
      "MEDICAL_LENDING",
      "INSURANCE",
      "DIAGNOSTICS",
      "MEDICINE",
      "CONSULTATION",
    ],
    statusIds,
    assignedTo: assignedUserCode,
    enquiryDateFilter,
    enquiryFromDate,
    enquiryToDate,
    followUpDateFilter,
    followUpFromDate,
    followUpToDate,
    packageIds: treatmentIds,
    sourceId,
  };
  const response = await axios.post(`${APP_URL}/enquiry/filtered`, postData, {
    headers,
  });
  return response;
};

export const getEnquiryByContext = async (contextId, categoryContext) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/byContext?contextId=${contextId}&categoryContext=${categoryContext}`,
    {
      headers,
    }
  );
  return response;
};

export const getEnquiryStatistics = async () => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    role,
    code,
    categories: ["SURGERY", "ENQUIRY"],
  };

  const response = await axios.post(`${APP_URL}/enquiry/statistics`, postData, {
    headers,
  });
  return response;
};

export const getTopBarStatistics = async () => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    role,
    code,
  };

  const response = await axios.post(
    `${APP_URL}/internal/mykare/topbar/statistics`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getEnquiryPreventiveCareStatistics = async () => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    role,
    code,
    categories: ["PREVENTIVE_CARE"],
  };

  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/statistics`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getSMOEnquiryStatistics = async () => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    role,
    code,
    categories: ["SMO"],
  };

  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/statistics`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getAddOnServiceEnquiryStatistics = async () => {
  const role = getUserRole();
  const code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const postData = {
    role,
    code,
    categories: [
      "FREE_COUNSELLING",
      "MEDICAL_LENDING",
      "INSURANCE",
      "DIAGNOSTICS",
      "MEDICINE",
      "CONSULTATION",
    ],
  };

  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/statistics`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const updateEnquiry = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.put(`${APP_URL}/enquiry/update`, postData, {
    headers,
  });
  return response;
};

export const updatePreLeadEnquiry = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/preLead/update`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const saveEnquiry = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  if (postData.assignedTo == null) {
    postData.assignedTo = getUserCode();
  }
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(`${APP_URL}/enquiry/save`, postData, {
    headers,
  });
  return response;
};

export const checkDuplicate = async (duplicatePostData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/checkDuplicate`,
    duplicatePostData,
    {
      headers,
    }
  );
  return response;
};

export const savePreLeadEnquiry = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  if (postData.preLeadAssignedTo == null) {
    postData.preLeadAssignedTo = getUserCode();
  }
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(`${APP_URL}/enquiry/save`, postData, {
    headers,
  });
  return response;
};

export const saveEventActivityLog = async (postData) => {
  postData.addedBy = getUserName();
  postData.addedByCode = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.post(
    `${APP_URL}/internal/activity/event/save`,
    postData,
    {
      headers,
    }
  );
  return response;
};

// export const saveBlackEnquiry = async (postData) => {
//   if(postData.assignedTo == null) {
//     postData.assignedTo = getUserCode();
//   }
//   console.log('==postData=====', postData.assignedTo)
//   const headers = {
//     Authorization: getAuthHeader(),
//   };

//   const response = await axios.post(`${APP_URL}/mykare/enquiry/save`, postData, {
//     headers,
//   });
//   return response;
// };

export const getUserTrxnStatuses = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const commonPath = "/enquiry/getAllUserTxnStatus";

  const response = await axios.get(`${APP_URL}/enquiry/getAllUserTxnStatus`, {
    headers,
  });
  return response;
};

export const getUserTrxnHistory = async (enquiryId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/enquiry/getUserTrxnHistory/${enquiryId}`,
    {
      headers,
    }
  );
  return response;
};

export const getDownloadReportData = async (
  fromDate,
  toDate,
  assignedTo,
  type
) => {
  axios
    .get(
      `${APP_URL}/mykare/enquiry/downloadReport?fromDate=${fromDate}&toDate=${toDate}&assignedTo=${assignedTo}&type=${type}`,
      {
        responseType: "blob",
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    )
    .then((res) => {
      fileDownload(res.data, "Enquiries.xlsx");
    });
};

export const getDownloadMissedFollowUpReportData = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  axios
    .post(`${APP_URL}/mykare/enquiry/download/missedFollowupReport`, postData, {
      headers,
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, "Missedfollowup.xlsx");
    });
};

export const getDownloadIpOpReportData = async (fromDate, toDate) => {
  axios
    .get(
      `${APP_URL}/mykare/appointment/downloadReport?fromDate=${fromDate}&toDate=${toDate}`,
      {
        responseType: "blob",
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    )
    .then((res) => {
      fileDownload(res.data, "AppointmentReport.xlsx");
    });
};

// // <-- Budget vs Achieved select month  -->

export const getDownloadBudgetAchieved = async (fromDate, toDate, range) => {
  axios
    .get(
      `${APP_URL}/mykare/report/download/budgetAchievedReport?fromDate=${fromDate}&toDate=${toDate}&range=${range}`,
      {
        responseType: "blob",
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    )
    .then((res) => {
      fileDownload(res.data, "BudgetAchievedReport.xlsx");
    });
};

// // <-- Budget vs Achieved select month  -->

export const getDownloadSurgerySuggestedReportData = async (
  fromDate,
  toDate
) => {
  axios
    .get(
      `${APP_URL}/mykare/appointment/download/surgerySuggestedReport?fromDate=${fromDate}&toDate=${toDate}`,
      {
        responseType: "blob",
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    )
    .then((res) => {
      fileDownload(res.data, "SurgerySuggestedReport.xlsx");
    });
};

export const getSources = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/enquiry/source/all`, {
    headers,
  });
  return response;
};

export const getLostReasons = async (statusId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/internal/mykare/reasons?reasonContext=ENQUIRY&statusId=${statusId}`,
    {
      headers,
    }
  );
  return response;
};

export const mergeDuplicateLead = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/mergeLeads`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const sendFeedbackLink = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/sendFeedbackLink`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const sendPatientReferralLink = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/sendPatientReferralLink`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const sendFeedbackCallNotPickedMsg = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/sendFeedbackCallNotPickedMsg`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const sendFeedbackMessage = async (postData) => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(
    `${APP_URL}/mykare/enquiry/sendWhatsappFeedbackMessage`,
    postData,
    {
      headers,
    }
  );
  return response;
};

export const getTaskCount = async (assignCode) => {
  let addedByCode = getUserCode();
  if (assignCode == "ALL") {
    assignCode = addedByCode;
  }
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/taskCount?assignedTo=${assignCode}`,
    {
      headers,
    }
  );
  return response;
};

export const getUserTexnHistory = async (userTxnId) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/karetrip/activity/${userTxnId}`,
    {
      headers,
    }
  );
  return response;
};

export const getTestPushNotification = async () => {
  const response = await axios.get(
    `http://localhost:8095/mykare-customer/notification//testPush`
  );
  return response;
};

export const postTreatmentInfo = async (treatmentid, type) => {
  const headers = {
    Authorization: getAuthHeader(),
  };

  const response = await axios.get(
    `${APP_URL}/treatment/info/?treatmentId=${treatmentid}&type=${type}`,
    {
      headers,
    }
  );
  return response;
};

// http://15.206.0.236:8065/admin/treatment/info?treatmentId=30&type=INFO
//  start  lami
export const getUserEducationList = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/educations`, {
    headers,
  });
  return response;
};

export const saveUserActivity = async () => {
  const postData = {
    userCode: getUserCode(),
  };
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(`${APP_URL}/user/activity/save`, postData, {
    headers,
  });
  return response;
};

export const getTotalWorkingTime = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const userCode = getUserCode();
  const response = await axios.get(
    `${APP_URL}/user/working/time?userCode=${userCode}`,
    {
      headers,
    }
  );
  return response;
};
export const getUserStatus = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const userCode = getUserCode();
  const response = await axios.get(
    `${APP_URL}/user/status?userCode=${userCode}`,
    {
      headers,
    }
  );
  return response;
};

export const saveUserBreaks = async (postData) => {
  postData.userCode = getUserCode();

  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.post(`${APP_URL}/user/break/save`, postData, {
    headers,
  });
  return response;
};
export const getUserLifestyleList = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/lifestyle/all`, {
    headers,
  });
  return response;
};

export const getUserLanguageList = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/languages`, {
    headers,
  });
  return response;
};

export const getUserLocationList = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/locations`, {
    headers,
  });
  return response;
};
export const getUserMedicalHistoryList = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/medical/histories`, {
    headers,
  });
  return response;
};
export const getUserOccupationsList = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/data/occupations`, {
    headers,
  });
  return response;
};

export const getTopPerfomer = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/mykare/enquiry/topPerformer`, {
    headers,
  });
  return response;
};

export const getUpcomingTask = async () => {
  let code = getUserCode();
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/getUpcomingTask?code=${code}`,
    {
      headers,
    }
  );
  return response;
};
export const getOpSubStatus = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(
    `${APP_URL}/mykare/enquiry/getAllUserTxnSubStatus/21`,
    {
      headers,
    }
  );
  return response;
};

export const getActivePreLeads = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  const response = await axios.get(`${APP_URL}/mykare/team/pre/all`, {
    headers,
  });
  return response;
};

export const getAllComents = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const response = await axios.get(`${APP_URL}/data/comments`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("An error occurred during the request:", error);
    throw error;
  }
};

export const getAllRelation = async () => {
  const headers = {
    Authorization: getAuthHeader(),
  };
  try {
    const response = await axios.get(`${APP_URL}/data/relation/all`, {
      headers,
    });
    return response;
  } catch (error) {
    console.error("An error occurred during the request:", error);
    throw error;
  }
};